import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { CreditCardPayload } from 'state/account/account.services';

import { PaymentHistoryLine, PaymentHistoryV2Response } from 'types/payment-history';

import {
    paymentsGetPaymentHistoryRoutine,
    paymentsPostPayBalanceRoutine,
    paymentsV2GetPaymentHistoryRoutine,
    paymentsV2PostPatientBalanceFamilyRoutine
} from './payments.routines';

export interface PaymentsState {
    isBusy?: boolean;
    selectedPaymentMethod?: CreditCardPayload;
    paymentHistory?: PaymentHistoryLine[];
    paymentHistoryV2?: PaymentHistoryV2Response;
}

export const initialState: PaymentsState = {
    isBusy: false
};

const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setSelectedPaymentMethod: (state, { payload }: PayloadAction<CreditCardPayload | undefined>) =>
            produce(state, (draftState) => {
                draftState.selectedPaymentMethod = payload;
            })
    },
    extraReducers: ({ addCase }) => {
        /**
         * Pay Balance
         */
        addCase(paymentsPostPayBalanceRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(paymentsPostPayBalanceRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );
        addCase(paymentsPostPayBalanceRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );

        /**
         * Pay Balance Family
         */
        addCase(paymentsV2PostPatientBalanceFamilyRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(paymentsV2PostPatientBalanceFamilyRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );
        addCase(paymentsV2PostPatientBalanceFamilyRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );

        /**
         * Payment History
         */
        addCase(paymentsGetPaymentHistoryRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(paymentsGetPaymentHistoryRoutine.SUCCESS, (state, { payload }: PayloadAction<PaymentHistoryLine[]>) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
                draftState.paymentHistory = payload;
            })
        );
        addCase(paymentsGetPaymentHistoryRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );

        /**
         * Payment History V2
         */
        addCase(paymentsV2GetPaymentHistoryRoutine.TRIGGER, (state) => {
            return produce(state, (draftState) => {
                draftState.isBusy = true;
            });
        });

        addCase(
            paymentsV2GetPaymentHistoryRoutine.SUCCESS,
            (state, { payload }: PayloadAction<PaymentHistoryV2Response>) =>
                produce(state, (draftState) => {
                    draftState.isBusy = false;
                    draftState.paymentHistoryV2 = payload;
                })
        );

        addCase(paymentsV2GetPaymentHistoryRoutine.FAILURE, (state) => {
            return produce(state, (draftState) => {
                draftState.isBusy = false;
            });
        });
    }
});

export const paymentsActions = paymentsSlice.actions;

export default paymentsSlice.reducer;
