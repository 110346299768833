import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import {
    PaymentHistoryLine,
    PaymentHistoryRequestPayload,
    PayPatientBalanceFamilyResponse,
    PostPatientBalanceFamilyPayload
} from 'types/payment-history';

import { TrackError } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';

import { PaymentHistoryV2Response, PayPatientBalanceFamilyErrors } from './../../types/payment-history.d';
import {
    paymentsGetPaymentHistoryRoutine,
    paymentsPostPayBalanceRoutine,
    paymentsV2GetPaymentHistoryRoutine,
    paymentsV2PostPatientBalanceFamilyRoutine
} from './payments.routines';
import PaymentsService from './payments.services';

export default function* paymentsSaga() {
    yield takeLatest(paymentsPostPayBalanceRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: PaymentsService.postPayBalance().post,
            data: action.payload,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(paymentsPostPayBalanceRoutine.success(data));
            },
            *onError(error: any) {
                yield put(paymentsPostPayBalanceRoutine.failure(error));
                if (error.response?.response?.data?.SystemMessage) {
                    TrackError(
                        'account.sagas.ts',
                        'accountPayBalanceRoutine',
                        error.response.response.data.SystemMessage
                    );
                    const regex = /REASON TEXT:(.*)/g;
                    const reason = error.response.response.data.SystemMessage.match(regex);

                    if (reason) {
                        TrackError('account.sagas.ts', 'accountPayBalanceRoutineDetails', JSON.stringify(reason));
                    }
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountPayBalanceRoutine',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }

                // Tracking other errors for debugging prod issue with payments
                const errorResponse = error?.response;
                if (errorResponse) {
                    const errorStatus = errorResponse.response?.status;
                    if (errorStatus) {
                        TrackError(
                            'account.sagas.ts',
                            'accountPayBalanceRoutineDebugging',
                            `Error status: ${errorStatus}`
                        );
                    }

                    const errorMessage = errorResponse.message;
                    if (errorMessage) {
                        TrackError(
                            'account.sagas.ts',
                            'accountPayBalanceRoutineDebugging',
                            `Error message: ${errorMessage}`
                        );
                    }

                    const errorDataMessage = errorResponse.response?.data?.ErrorMessage;
                    if (errorDataMessage) {
                        TrackError(
                            'account.sagas.ts',
                            'accountPayBalanceRoutineDebugging',
                            `Error data message: ${errorDataMessage}`
                        );
                    }
                }

                const { onFailure } = action.payload;
                if (onFailure) onFailure(error.response?.response?.data ? error.response.response.data : error);
            }
        });
    });

    yield takeLatest(
        paymentsV2PostPatientBalanceFamilyRoutine.TRIGGER,
        function* (action: PayloadAction<PostPatientBalanceFamilyPayload>) {
            yield baseEffectHandler({
                service: PaymentsService.postPayPatientBalanceFamily().post,
                data: action.payload,
                *onResponse(data: Array<PayPatientBalanceFamilyResponse>) {
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                    yield put(paymentsV2PostPatientBalanceFamilyRoutine.success(data));
                },
                *onError(error: PayPatientBalanceFamilyErrors) {
                    yield put(paymentsV2PostPatientBalanceFamilyRoutine.failure(error));
                    if (error.ErrorMessage) {
                        TrackError(
                            'payments.sagas.ts',
                            'paymentsV2PostPatientBalanceFamilyRoutine',
                            error.ErrorMessage
                        );
                        const regex = /REASON TEXT:(.*)/g;
                        const reason = error.ErrorMessage.match(regex);

                        if (reason) {
                            TrackError('account.sagas.ts', 'accountPayBalanceRoutineDetails', JSON.stringify(reason));
                        }
                    } else {
                        TrackError(
                            'payments.sagas.ts',
                            'paymentsV2PostPatientBalanceFamilyRoutine',
                            error?.SystemMessage ? error.SystemMessage : 'An unknown error occurrred.'
                        );
                    }

                    // Tracking other errors for debugging prod issue with payments
                    const errorResponse = error;
                    if (errorResponse) {
                        const errorStatus = errorResponse.InError;
                        if (errorStatus) {
                            TrackError(
                                'payments.sagas.ts',
                                'accountPayBalanceRoutineDebugging',
                                `Error status: ${errorStatus}`
                            );
                        }

                        const errorMessage = errorResponse.ErrorMessage;
                        if (errorMessage) {
                            TrackError(
                                'payments.sagas.ts',
                                'accountPayBalanceRoutineDebugging',
                                `Error message: ${errorMessage}`
                            );
                        }

                        const errorDataMessage = errorResponse.SystemMessage;
                        if (errorDataMessage) {
                            TrackError(
                                'payments.sagas.ts',
                                'accountPayBalanceRoutineDebugging',
                                `Error data message: ${errorDataMessage}`
                            );
                        }
                    }

                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(error.ErrorMessage ? error.ErrorMessage : error);
                }
            });
        }
    );

    yield takeLatest(
        paymentsV2GetPaymentHistoryRoutine.TRIGGER,
        function* (action: PayloadAction<PaymentHistoryRequestPayload>) {
            yield baseEffectHandler({
                service: PaymentsService.getPaymentHistory().v2,
                data: action.payload,
                *onResponse(data: PaymentHistoryV2Response) {
                    yield put(paymentsV2GetPaymentHistoryRoutine.success(data));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError(error: any) {
                    yield put(paymentsV2GetPaymentHistoryRoutine.failure(error));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );

    yield takeLatest(paymentsGetPaymentHistoryRoutine.TRIGGER, (action: PayloadAction<PaymentHistoryRequestPayload>) =>
        baseEffectHandler({
            service: PaymentsService.getPaymentHistory().v1,
            data: action.payload,
            *onResponse(response: Array<PaymentHistoryLine>) {
                yield put(paymentsGetPaymentHistoryRoutine.success(response));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError() {
                yield put(paymentsGetPaymentHistoryRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        })
    );
}
