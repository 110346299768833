import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { AddressesResponse, PaymentCardsResponse } from 'types/auto-refill';

export const autoRefillSelector = (state: RootState) => state.autoRefillReducer;

export const autoRefillUserVerificationFailedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.userVerified !== 'UserVerified';
});

export const autoRefillUserVerifiedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.userVerified === 'UserVerified';
});

export const autoRefillSecurityTokenSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.securityToken;
});

export const isLoadingPatientDataSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.isLoadingPatientData;
});

export const autoRefillSecretKeyHashSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.secretKeyHash;
});

export const autoRefillErrorMessageSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.error.messageText ?? '';
});

export const autoRefillUserBearerTokenSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.bearerToken;
});

export const autoRefillFirstNameSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.firstName;
});

export const autoRefillLoadingRxsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.loadingPrescriptions;
});

export const autoRefillRxsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.rxResults;
});

export const autoRefillRxsLoadedSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.prescriptionsLoaded;
});

export const autoRefillOrderInvoiceNumberSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.orderInvoiceNumber;
});

export const autoRefillFlagsSelector = createSelector(autoRefillSelector, (autoRefill) => autoRefill.autoRefillFlags);

export const autoRefillFamilyDataSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.familyDependents;
});

export const autoRefillEligibleFamilyDataSelector = createSelector(autoRefillFamilyDataSelector, (familyDependents) => {
    return familyDependents.map((patient) => ({
        ...patient,
        rxResults: [...patient.rxResults.filter((rx) => rx.autoRefillEligible)]
    }));
});

export const autoRefillToggleRxBusySelector = createSelector(autoRefillSelector, (autoRefill) => autoRefill.isBusy);

export const autoRefillAccountHasInsuranceSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.planAlias !== 'BRD01' && autoRefill.planAlias !== 'BRD02';
});

export const autoRefillAdressesSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.addresses;
});

export const autoRefillDefaultAddressSelector = createSelector(autoRefillAdressesSelector, (addresses) => {
    return addresses.find((address) => address.defaultShip);
});

export const autoRefillAccountIsCaliforniaUserSelector = createSelector(
    autoRefillAdressesSelector,
    (autoRefillAddresses: AddressesResponse[]) => {
        if (!Array.isArray(autoRefillAddresses) && autoRefillAddresses > 0) {
            return false;
        }
        return !!autoRefillAddresses.find((address) => address.state === 'CA' && address.defaultShip);
    }
);

export const autoRefillPaymentCardsSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.paymentCards;
});

export const autoRefillePostPatientNumberSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.epostPatientNum;
});

export const autoRefillIsCaregiverSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.isCaregiver;
});

export const autoRefillDefaultPaymentSelector = createSelector(
    autoRefillPaymentCardsSelector,
    (paymentCards: PaymentCardsResponse[]) => {
        return paymentCards.find((card) => card.defaultCard);
    }
);

export const autoRefillFamilyRxSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.isFamilyMembersWithRx;
});

export const autoRefillAcCodeSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.planAlias;
});

export const autoRefillFamilyPlansMapSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.familyDependents.reduce((acc, item) => {
        return {
            ...acc,
            [item.epostPatientNum]: item.planAlias
        };
    }, {});
});

export const autoRefillPatientPlanSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.patientAutoRefill;
});

export const autoRefillAccountStatusSelector = createSelector(autoRefillSelector, (autoRefill) => {
    return autoRefill.isPlanAutoRefillOn;
});
