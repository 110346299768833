import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';

import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
// State
import { accountIsLoggedInSelector } from 'state/account/account.selectors';
import { CreditCardPayload } from 'state/account/account.services';

// Types
import {
    MembershipAddMemberResponse,
    MembershipAddMemberResponseData,
    MembershipDefaultPaymentMethodResponse,
    MembershipPersonalDetailsFormValues,
    MembershipPreAuthorizeTelemedicineRequest,
    MembershipPreAuthorizeTelemedicineResponse,
    MembershipPricingDetailsResponse,
    MembershipRemoveInviteePayload,
    MembershipRemoveInviteResponse,
    MembershipRemoveResponse,
    MembershipSendInviteResponse,
    MembershipTelehealthVisitsResponse,
    MembershipUpgradePlanPayload,
    MembershipUpgradePlanResponse,
    MembershipViewVisitsResponse
} from 'types/membership';

// Utils
import { isAxiosError } from 'util/axiosClient';
import { MEMBERSHIP_RESPONSE_ERRORS } from 'util/membership';
import { baseEffectHandler } from 'util/sagas/sagas';

// State
import {
    membershipAcceptOrDeclineInvitationsRoutine,
    membershipAddMemberRoutine,
    membershipApplyPromoCodeRoutine,
    membershipBirdiSelectFormularyRoutine,
    membershipDetailsRoutine,
    membershipGetInvitationsRoutine,
    membershipGetTelehealthVisitsRoutine,
    membershipGetUpgradablePlansRoutine,
    membershipGetVisitsLinkRoutine,
    membershipLeavePlanRoutine,
    membershipPaymentMethodsRoutine,
    membershipPlansDetailsRoutine,
    membershipPreAuthorizeTelemedicineRoutine,
    membershipRemoveFromPlanRoutine,
    membershipRemoveInviteeRoutine,
    membershipSendInviteRoutine,
    membershipUpdateDefaultPaymentMethodRoutine,
    membershipUpgradePlanRoutine,
    membershipVerifyDependentRoutine
} from './membership.routines';
import { membershipDetailSelector } from './membership.selector';
import MembershipService from './membership.services';

type VerifyDependentSaga = {
    dependentData: MembershipPersonalDetailsFormValues;
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
};

type GetMembershipDetailsSaga = {
    membershipId: string;
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
};

type GetMembershipPlansDetailsSaga = {
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
};

type PostMembershipAddMemberSaga = {
    data: MembershipAddMemberResponseData;
    onSuccess?: (response: MembershipAddMemberResponse) => void;
    onFailure?: (error: MembershipAddMemberResponse['Errors']) => void;
};

type PostMembershipSendInviteSaga = {
    data: MembershipSendInviteResponse;
    onSuccess?: (response: MembershipSendInviteResponse) => void;
    onFailure?: (error: MembershipSendInviteResponse) => void;
};

type PostGetVisitsLinkSaga = {
    epostPatientNumber: string;
    onSuccess?: (response: MembershipViewVisitsResponse) => void;
    onFailure?: (error: MembershipViewVisitsResponse) => void;
};

type GetTelehealthVisitsSaga = {
    epostPatientNumber: string;
    onSuccess?: (response: MembershipTelehealthVisitsResponse) => void;
    onFailure?: (error: MembershipTelehealthVisitsResponse) => void;
};

type PutSetDefaultMembershipCardSaga = {
    data: CreditCardPayload;
    onSuccess?: (response: MembershipDefaultPaymentMethodResponse) => void;
    onFailure?: (error: any) => void;
};
type DeleteRemoveFromPlanSaga = {
    payload: {
        ePostPatientNumber: string;
        membershipId: string;
    };
    onSuccess?: (response: MembershipRemoveResponse) => void;
    onFailure?: (error: MembershipRemoveResponse) => void;
};

type DeleteLeavePlanSaga = {
    payload: {
        ePostPatientNumber: string;
    };
    onSuccess?: (response: MembershipRemoveResponse) => void;
    onFailure?: (error: MembershipRemoveResponse) => void;
};

type DeleteMembershipRemoveInviteSaga = {
    data: MembershipRemoveInviteePayload;
    onSuccess?: (response: MembershipRemoveInviteResponse) => void;
    onFailure?: (error: MembershipRemoveInviteResponse) => void;
};

type GetUpgradePlansSaga = {
    data: {
        epostPatientNum: string;
        promoCode?: string;
    };
    onSuccess?: (response: MembershipPricingDetailsResponse) => void;
    onFailure?: (error: any) => void;
};

type PutMembershipUpgradePlanSaga = {
    payload: MembershipUpgradePlanPayload;
    onSuccess?: (response: MembershipUpgradePlanResponse) => void;
    onFailure?: (error: MembershipUpgradePlanResponse) => void;
};

type PostAcceptOrDeclinetMembershipSaga = {
    payload: {
        membershipInvitationId?: number;
        status: number;
    };
    onSuccess?: (response: any) => void;
    onFailure?: (error: any) => void;
};

type PostMembershipPreAuthorizeTelemedicineSaga = {
    payload: MembershipPreAuthorizeTelemedicineRequest;
    onSuccess?: (response: MembershipPreAuthorizeTelemedicineResponse) => void;
    onFailure?: (error: any) => void;
};

function* verifyDependentSaga(action: PayloadAction<VerifyDependentSaga>) {
    const { dependentData, onSuccess } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.dependents().verify,
        isAuthenticatedService: true,
        isLoggedIn,
        data: dependentData,
        *onResponse(response) {
            yield put(membershipVerifyDependentRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            yield put(membershipVerifyDependentRoutine.failure({ messageText: error.response.response.data.Errors }));
        }
    });
}

function* getMembershipDetailsSaga(action: PayloadAction<GetMembershipDetailsSaga>) {
    if (!action.payload) return;
    const { onSuccess, membershipId } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipDetails().get,
        isAuthenticatedService: true,
        isLoggedIn,
        data: membershipId,
        *onResponse(response) {
            yield put(membershipDetailsRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipDetailsRoutine.failure({ messageText: response.response.data.Message }));
        }
    });
}

function* getMembershipPlansDetailsSaga(action: PayloadAction<GetMembershipPlansDetailsSaga>) {
    const onSuccess = action?.payload?.onSuccess || undefined;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipGetPlansDetails().get,
        isAuthenticatedService: false,
        isLoggedIn,
        data: action.payload,
        *onResponse(response) {
            yield put(membershipPlansDetailsRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipPlansDetailsRoutine.failure({ messageText: response.response.data.Message }));
        }
    });
}

function* postMembershipAddMemberSaga(action: PayloadAction<PostMembershipAddMemberSaga>) {
    const { onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipAddMember().post,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload.data,
        *onResponse(response) {
            if (response.messageStatus === false) {
                yield put(membershipAddMemberRoutine.failure({ messageText: response.messageText }));
                if (onFailure) onFailure(response);
                return;
            }

            yield put(membershipAddMemberRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipAddMemberRoutine.failure({ messageText: response.response.Message }));
            if (onFailure) onFailure(response.response.data.Errors);
        }
    });
}

function* getMembershipPaymentMethodsSaga() {
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipPaymentMethods().get,
        isAuthenticatedService: true,
        isLoggedIn,
        *onResponse(response) {
            yield put(membershipPaymentMethodsRoutine.success(response));
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipPaymentMethodsRoutine.failure({ messageText: response.response.data.Message }));
        }
    });
}

function* postMembershipSendInviteSaga(action: PayloadAction<PostMembershipSendInviteSaga>) {
    const { onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    const { membershipId }: { membershipId: string | null } = yield select(membershipDetailSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipSendInvite().post,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload.data,
        *onResponse(response: MembershipSendInviteResponse) {
            if (response.messageStatus === false) {
                yield put(membershipSendInviteRoutine.failure({ messageText: response.messageText }));
                if (onFailure) onFailure(response);
                return;
            }

            yield put(membershipSendInviteRoutine.success(response));
            yield put(membershipDetailsRoutine.trigger({ membershipId }));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipSendInviteRoutine.failure({ messageText: response.response.Message }));
            if (onFailure) onFailure(response.response.data.Errors);
        }
    });
}

function* postGetVisitsLinkSaga(action: PayloadAction<PostGetVisitsLinkSaga>) {
    const { onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipViewVisits().post,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload.epostPatientNumber,
        *onResponse(response: MembershipViewVisitsResponse) {
            yield put(membershipGetVisitsLinkRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipGetVisitsLinkRoutine.failure({ messageText: response.response.Message }));
            if (onFailure) onFailure(response.response.data.Errors);
        }
    });
}

function* getMembershipBirdiSelectFormularySaga() {
    yield baseEffectHandler({
        service: MembershipService.membershipBirdiSelectFomulary().get,
        isAuthenticatedService: false,
        *onResponse(response) {
            yield put(membershipBirdiSelectFormularyRoutine.success(response));
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipBirdiSelectFormularyRoutine.failure({ messageText: response.response.Message }));
        }
    });
}

function* getTelehealthVisitsSaga(action: PayloadAction<GetTelehealthVisitsSaga>) {
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipTelehealthVisits(action.payload.epostPatientNumber).get,
        isAuthenticatedService: true,
        isLoggedIn,
        *onResponse(response: MembershipViewVisitsResponse) {
            yield put(membershipGetTelehealthVisitsRoutine.success(response));
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipGetTelehealthVisitsRoutine.failure({ messageText: response.response.Message }));
        }
    });
}

function* deleteMembershipRemoveFromPlanSaga(action: PayloadAction<DeleteRemoveFromPlanSaga>) {
    const { payload, onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipRemoveMember().removeFromPlan,
        isAuthenticatedService: true,
        isLoggedIn,
        data: payload,
        *onResponse(response: MembershipRemoveResponse) {
            if (response.messageStatus === false) {
                yield put(membershipRemoveFromPlanRoutine.failure({ messageText: response.messageText }));
                if (onFailure) onFailure(response);
                return;
            }
            yield put(membershipRemoveFromPlanRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipRemoveFromPlanRoutine.failure({ messageText: response }));
            if (onFailure) onFailure(response);
        }
    });
}

function* getUpgradablePlansSaga(action: PayloadAction<GetUpgradePlansSaga>) {
    const { onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipUpgradePlan().get,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload.data,
        *onResponse(response: MembershipPricingDetailsResponse) {
            const { PricingDetails } = response;

            // reset promoCode state
            yield put(membershipApplyPromoCodeRoutine.fulfill());

            if (action.payload.data.promoCode) {
                const allPlansValid = PricingDetails.every((plan) => {
                    // Check if every plan has DiscountChargeAmount and DiscountRecurringBilling
                    return plan.DiscountChargeAmount && plan.DiscountRecurringBilling;
                });

                if (allPlansValid) {
                    // If all plans have required properties, treat promo code as successful
                    yield put(membershipApplyPromoCodeRoutine.success());
                    // Stop further execution
                } else {
                    // If any plan is missing required properties, treat promo code as failure
                    yield put(membershipApplyPromoCodeRoutine.failure());
                }
            }

            // If promoCode was not sent or all necessary properties are present, treat it as a success
            if (onSuccess) onSuccess(response);
            yield put(membershipGetUpgradablePlansRoutine.success(response));
        },
        *onError(error) {
            if (onFailure) onFailure(error);
            yield put(membershipGetUpgradablePlansRoutine.failure({ messageText: error.response.Message }));
        }
    });
}

function* deleteMembershipLeavePlanSaga(action: PayloadAction<DeleteLeavePlanSaga>) {
    const { payload, onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipRemoveMember().leavePlan,
        isAuthenticatedService: true,
        isLoggedIn,
        data: payload,
        *onResponse(response: MembershipRemoveResponse) {
            if (response.messageStatus === false) {
                yield put(membershipLeavePlanRoutine.failure({ messageText: response.messageText }));
                if (onFailure) onFailure(response);
                return;
            }

            yield put(membershipLeavePlanRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipLeavePlanRoutine.failure({ messageText: response }));
            if (onFailure) onFailure(response);
        }
    });
}

function* deleteMembershipRemoveInviteSaga(action: PayloadAction<DeleteMembershipRemoveInviteSaga>) {
    const { onSuccess, onFailure, ...removeMembershipData } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    yield baseEffectHandler({
        service: MembershipService.membershipRemoveInvitee().delete,
        isAuthenticatedService: true,
        isLoggedIn,
        data: removeMembershipData,
        *onResponse(response: MembershipRemoveInviteResponse) {
            if (response.messageStatus === false) {
                yield put(membershipRemoveInviteeRoutine.failure({ messageText: response.messageText }));
                if (onFailure) onFailure(response);
                return;
            }
            yield put(membershipRemoveInviteeRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipRemoveInviteeRoutine.failure({ messageText: response.response.Message }));
            if (onFailure) onFailure(response.response.data.Errors);
        }
    });
}

function* updateBirdiMembershipPaymentMethodSaga(action: PayloadAction<PutSetDefaultMembershipCardSaga>) {
    const { onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipDefaultPayment().put,
        isAuthenticatedService: true,
        isLoggedIn,
        data: action.payload.data,
        *onResponse(response) {
            if (onSuccess) onSuccess(response);
            yield put(membershipUpdateDefaultPaymentMethodRoutine.success(response));
            yield put(accountGetAllCreditCardsRoutine.trigger());
        },
        *onError(error) {
            if (onFailure) onFailure(error);
            yield put(membershipUpdateDefaultPaymentMethodRoutine.failure({ messageText: error.response.Message }));
        }
    });
}

function* putMembershipUpgradePlanSaga(action: PayloadAction<PutMembershipUpgradePlanSaga>) {
    const { payload, onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
    const { membershipId }: { membershipId: string | null } = yield select(membershipDetailSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipUpgradePlan().put,
        isAuthenticatedService: true,
        isLoggedIn,
        data: payload,
        *onResponse(response: MembershipUpgradePlanResponse) {
            if (response.messageStatus === false) {
                yield put(membershipUpgradePlanRoutine.failure({ messageText: response.messageText }));
                if (onFailure) onFailure(response);
                return;
            }

            yield put(membershipUpgradePlanRoutine.success(response));

            // update membership detail after upgrade plan
            yield put(membershipDetailsRoutine.trigger({ membershipId }));

            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipUpgradePlanRoutine.failure({ messageText: response }));
            if (onFailure) onFailure(response);
        }
    });
}

function* getMembershipInvitationsSaga(action: PayloadAction<GetMembershipPlansDetailsSaga>) {
    const onSuccess = action?.payload?.onSuccess || undefined;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipInvitations().get,
        isAuthenticatedService: false,
        isLoggedIn,
        data: action.payload,
        *onResponse(response) {
            yield put(membershipGetInvitationsRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(membershipGetInvitationsRoutine.failure({ messageText: response.response.data.Message }));
        }
    });
}

function* acceptOrDeclinetMembershipInvitationsSaga(action: PayloadAction<PostAcceptOrDeclinetMembershipSaga>) {
    const onSuccess = action?.payload?.onSuccess || undefined;
    const onFailure = action?.payload?.onFailure || undefined;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipAcceptOrDeclineInvite().post,
        isAuthenticatedService: false,
        isLoggedIn,
        data: action.payload,
        *onResponse(response) {
            yield put(membershipAcceptOrDeclineInvitationsRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error) {
            const { response } = error;
            yield put(
                membershipAcceptOrDeclineInvitationsRoutine.failure({ messageText: response.response.data.Message })
            );
            if (onFailure) onFailure(error.response);
        }
    });
}

function* postMembershipPreAuthorizeTelemedicineSaga(
    action: PayloadAction<PostMembershipPreAuthorizeTelemedicineSaga>
) {
    const { payload, onSuccess, onFailure } = action.payload;
    const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

    yield baseEffectHandler({
        service: MembershipService.membershipPreAuthorizeTelemedicine().post,
        isAuthenticatedService: true,
        isLoggedIn,
        data: payload,
        *onResponse(response: MembershipPreAuthorizeTelemedicineResponse) {
            if (
                response.MessageStatus === false &&
                (
                    [
                        MEMBERSHIP_RESPONSE_ERRORS.PAYMENT_PROCESS_WITH_ERRORS,
                        MEMBERSHIP_RESPONSE_ERRORS.PREAUTH_PAYMENT_NOT_AUTORIZED
                    ] as string[]
                ).includes(response.MessageText)
            ) {
                yield put(membershipPreAuthorizeTelemedicineRoutine.failure(response));
                if (onFailure) onFailure(response);
                return;
            }
            yield put(membershipPreAuthorizeTelemedicineRoutine.success(response));
            onSuccess && onSuccess(response);
        },
        *onError(error: any) {
            if (isAxiosError(error.response)) {
                yield put(membershipPreAuthorizeTelemedicineRoutine.failure(error.response));
                if (onFailure) onFailure(error.response);
            }
        }
    });
}

function* membershipSaga() {
    yield takeLatest(membershipVerifyDependentRoutine.TRIGGER, verifyDependentSaga);
    yield takeLatest(membershipDetailsRoutine.TRIGGER, getMembershipDetailsSaga);
    yield takeLatest(membershipPlansDetailsRoutine.TRIGGER, getMembershipPlansDetailsSaga);
    yield takeLatest(membershipAddMemberRoutine.TRIGGER, postMembershipAddMemberSaga);
    yield takeLatest(membershipPaymentMethodsRoutine.TRIGGER, getMembershipPaymentMethodsSaga);
    yield takeLatest(membershipSendInviteRoutine.TRIGGER, postMembershipSendInviteSaga);
    yield takeLatest(membershipGetVisitsLinkRoutine.TRIGGER, postGetVisitsLinkSaga);
    yield takeLatest(membershipBirdiSelectFormularyRoutine.TRIGGER, getMembershipBirdiSelectFormularySaga);
    yield takeLatest(membershipGetTelehealthVisitsRoutine.TRIGGER, getTelehealthVisitsSaga);
    yield takeLatest(membershipUpdateDefaultPaymentMethodRoutine.TRIGGER, updateBirdiMembershipPaymentMethodSaga);
    yield takeLatest(membershipGetUpgradablePlansRoutine.TRIGGER, getUpgradablePlansSaga);
    yield takeLatest(membershipApplyPromoCodeRoutine.TRIGGER, getUpgradablePlansSaga);
    yield takeLatest(membershipRemoveFromPlanRoutine.TRIGGER, deleteMembershipRemoveFromPlanSaga);
    yield takeLatest(membershipLeavePlanRoutine.TRIGGER, deleteMembershipLeavePlanSaga);
    yield takeLatest(membershipRemoveInviteeRoutine.TRIGGER, deleteMembershipRemoveInviteSaga);
    yield takeLatest(membershipUpgradePlanRoutine.TRIGGER, putMembershipUpgradePlanSaga);
    yield takeLatest(membershipGetInvitationsRoutine.TRIGGER, getMembershipInvitationsSaga);
    yield takeLatest(membershipAcceptOrDeclineInvitationsRoutine.TRIGGER, acceptOrDeclinetMembershipInvitationsSaga);
    yield takeLatest(membershipPreAuthorizeTelemedicineRoutine.TRIGGER, postMembershipPreAuthorizeTelemedicineSaga);
}

export default membershipSaga;
