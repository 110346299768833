import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export const familyAccountSelector = (state: RootState) => state.familyAccountReducer;

export const familyAccountSecurityTokenSelector = createSelector(familyAccountSelector, (familyAccount) => {
    return familyAccount.securityToken;
});

export const familyAccountSecretKeyHashSelector = createSelector(familyAccountSelector, (familyAccount) => {
    return familyAccount.secretKeyHash;
});

export const familyAccountUserBearerTokenSelector = createSelector(familyAccountSelector, (familyAccount) => {
    return familyAccount.bearerToken;
});

export const familyAccountMemberToAddSelector = createSelector(familyAccountSelector, (familyAccount) => {
    return familyAccount.memberToAdd;
});

export const familyAccountFirstNameSelector = createSelector(familyAccountSelector, (familyAccount) => {
    return familyAccount.firstName;
});

export const familyAccountCaregiversSelector = createSelector(familyAccountSelector, (familyAccount) => {
    return familyAccount.caregivers;
});

export const familyAccountIsCaregiverLoadingSelector = createSelector(familyAccountSelector, (familyAccount) => {
    return familyAccount.isCaregiverLoading;
});
