import { createRoutine } from 'redux-saga-routines';

export const memberShipRegistrationGetOptionsRoutine = createRoutine('membership-registration/OPTIONS');
export const membershipRegistrationGetGendersRoutine = createRoutine('membership-registration/GET_GENDERS');
export const membershipRegistrationGetRacesRoutine = createRoutine('membership-registration/GET_RACES');
export const membershipRegistrationGetEthnicitiesRoutine = createRoutine('membership-registration/GET_ETHNICITIES');
export const membershipRegistrationGetPlanDetailsRoutine = createRoutine('membership-registration/GET_PLAN_DETAILS');
export const membershipRegistrationGetEmailZipcodeRoutine = createRoutine('membership-registration/GET_EMAIL_ZIPCODE');
export const membershipRegistrationValidateInsuranceIdRoutine = createRoutine(
    'membership-registration/VALIDATE_INSURANCE_ID'
);
export const membershipRegistrationValidateInviteRoutine = createRoutine('membership-registration/VALIDATE_INVITE');
export const membershipRegistrationRegisterRoutine = createRoutine('membership-registration/REGISTER');
export const membershipRegistrationPromoCodeRoutine = createRoutine('membership-registration/PROMO_CODE');
export const membershipRegistrationClearPromoCodeRoutine = createRoutine('membership-registration/PROMO_CODE/CLEAR');
export const membershipRegistrationUninsuredRegisterRoutine = createRoutine(
    'membership-registration/UNINSURED_REGISTER'
);
export const membershipRegistrationUninsuredPaymetricCredentialsRoutine = createRoutine(
    'membership-registration/UNINSURED_PAYMETRIC_CREDENTIALS'
);
export const membershipRegistrationUninsuredTokenizedCardNumberRoutine = createRoutine(
    'membership-registration/UNINSURED_TOKENIZED_CARD_NUMBER'
);
export const membershipRegistrationConfirmPaymentRoutine = createRoutine('membership-registration/CONFIRM_PAYMENT');
