import valid from 'card-validator';
import { TFunction } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';

const isValidCardType = (cardNumber: string): boolean => {
    const cardTypes = [
        /^4[0-9]{12}(?:[0-9]{3})?$/, // Visa
        /^5[1-5][0-9]{14}$/, // MasterCard
        /^6(?:011|5[0-9]{2})[0-9]{12}$/, // Discover
        /^3[47][0-9]{13}$/ // American Express
    ];
    return cardTypes.map((cardType) => cardType.test(cardNumber)).includes(true);
};

export const PAYMENT_SCHEMA = yup.object().shape({
    cardNumber: yup
        .string()
        .required()
        .min(13)
        .max(19)
        .test(
            'test-number', // this is used internally by yup
            (value) => valid.number(value).isValid
        )
        .test(
            'test-number', // this is used internally by yup
            (value) => isValidCardType(value)
        ),
    nameOnCard: yup
        .string()
        .required()
        .test('test-name', (value) => {
            if (!value) return false;

            const validation = valid.cardholderName(value);
            if (!validation.isValid) {
                return false;
            }

            const invalidCharacters = /[^a-zA-Z\s'-]/;
            if (invalidCharacters.test(value)) {
                return false;
            }

            const nameParts = value.trim().split(/\s+/);
            if (nameParts.length < 2) {
                return false;
            }

            return true;
        }),
    expMonth: yup
        .string()
        .required()
        .test('test-month', (value, { parent }) => {
            if (value === undefined) return false;

            const currentDate = moment();
            const cardDateExpiration = moment(`${value}, ${parent.expYear || currentDate.get('YYYY')}`, 'MMMM, YYYY');
            const isCardExpirated = currentDate.isBefore(cardDateExpiration);

            return isCardExpirated;
        }),
    expYear: yup.string().required(),
    cvcNumber: yup
        .string()
        .required()
        .test('cvc-number', (value) => valid.cvv(value, [3, 4]).isValid),
    fsaCard: yup.boolean()
});

export const PAYMENT_SCHEMA_V2 = () =>
    PAYMENT_SCHEMA.shape({
        zipCode: yup.string().required()
    });

export const PAYMENT_SCHEMA_V3 = (t: TFunction) =>
    yup.object().shape({
        cardNumber: yup
            .string()
            .required(
                t('forms.errorMessages.requiredField', { label: t('membership.paymentMethod.form.labels.cardNumber') })
            )
            .min(13, t('forms.errorMessages.invalidCard'))
            .max(19, t('forms.errorMessages.invalidCard'))
            .test('test-card-number', t('forms.errorMessages.invalidCard'), (value) =>
                value ? valid.number(value).isValid && isValidCardType(value) : false
            ),
        nameOnCard: yup
            .string()
            .required(
                t('forms.errorMessages.requiredField', { label: t('membership.paymentMethod.form.labels.nameOnCard') })
            )
            .test('test-name-on-card', t('forms.errorMessages.invalidName'), (value) => {
                if (!value) return false;

                const validation = valid.cardholderName(value);
                if (!validation.isValid) {
                    return false;
                }

                const invalidCharacters = /[^a-zA-Z\s'-]/;
                if (invalidCharacters.test(value)) {
                    return false;
                }

                const nameParts = value.trim().split(/\s+/);
                if (nameParts.length < 2) {
                    return false;
                }

                return true;
            }),
        expMonth: yup
            .string()
            .required(
                t('forms.errorMessages.requiredField', { label: t('membership.paymentMethod.form.labels.expMonth') })
            )
            .test('test-exp-month', t('forms.errorMessages.invalidMonth'), (value, { parent }) => {
                if (value === undefined) return false;

                const currentDate = moment();
                const cardDateExpiration = moment(
                    `${value}, ${parent.expYear || currentDate.get('YYYY')}`,
                    'MMMM, YYYY'
                );
                const isCardExpired = currentDate.isBefore(cardDateExpiration);

                return isCardExpired;
            }),
        expYear: yup
            .string()
            .required(
                t('forms.errorMessages.requiredField', { label: t('membership.paymentMethod.form.labels.expYear') })
            ),
        cvcNumber: yup
            .string()
            .required(
                t('forms.errorMessages.requiredField', { label: t('membership.paymentMethod.form.labels.cvcNumber') })
            )
            .test('test-cvc-number', t('forms.errorMessages.invalidCVC'), (value) => valid.cvv(value, [3, 4]).isValid),
        zipCode: yup
            .string()
            .required(
                t('forms.errorMessages.requiredField', { label: t('membership.paymentMethod.form.labels.zipCode') })
            )
            .matches(/^\d{5}$/, t('forms.errorMessages.invalidZipCode')),
        fsaCard: yup.boolean()
    });
