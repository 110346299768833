import { MutableRefObject } from 'react';

import { NavPath } from 'components/navigation/navigation.props';

// Function that returns a list of navigation paths that should be
// displayed based on their drupal configurations.

// Note that our navigation paths only consider 1 level of children
// adding a new level, will require adjustments on this function
// to change into a recursive logic.
export const filterNavigationPaths = (
    navItems: NavPath[],
    isSecurePage = false,
    isLoggedIn = false,
    isMembership = false
) => {
    const shouldDisplayItem = (item: NavPath) => {
        // If the user is authenticated
        return isSecurePage || isLoggedIn
            ? // If the user has membership, check if we should display the link only for membership
              // If the user doesn't have membership check if the user should be authenticated
              isMembership
                ? item.requiresMembership || item.requiresAccountAuth
                : item.requiresAccountAuth
            : // If the user is not authenticated, check that
              // requires accountAuth and requiresMembership are off
              !item.requiresAccountAuth && !item.requiresMembership;
    };

    return (
        navItems
            .map((item: NavPath) => {
                const navItem = { ...item };
                // If the menu item has children
                if (navItem.children && navItem.children.length > 0) {
                    // Check if the children items should be displayed
                    navItem.children = [...navItem.children].filter((i) => shouldDisplayItem(i));
                }
                return navItem;
            })
            // Show only the items that should display or have active children
            .filter((item: NavPath) => shouldDisplayItem(item) || (item.children && item.children.length > 0))
    );
};

export const scrollToTop = (elementRef?: MutableRefObject<any>) => {
    if (elementRef && window && elementRef && elementRef.current) {
        window.scrollTo({
            top: window.scrollY + elementRef.current.getBoundingClientRect().top - 250,
            left: 0,
            behavior: 'smooth'
        });
    } else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
};
