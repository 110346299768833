import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AddressCardProps } from 'components/shipping-addresses-v2/address-card/address-card.props';
// Components & UI
import ShippingAddresses from 'components/shipping-addresses-v2/shipping-addresses.component';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import UpdateCartModalContent, {
    FailureUpdateCartModalContent
} from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

// Cart
import { accountProfileAddressesSelector, accountProfilIsCaregiverSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartUpdateShippingRoutine, getCartRoutine } from 'state/cart/cart.routines';
import { cartOrderBillShipSelector } from 'state/cart/cart.selectors';

import './shipping-address.style.scss';

const getDefaultAddress = (addresses: AddressCardProps[]): AddressCardProps | null => {
    if (!Array.isArray(addresses) || !addresses.length) {
        return null;
    }
    return addresses.find((address) => address.isChecked) || null;
};

const ShippingAddress: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileAddresses = useSelector(accountProfileAddressesSelector);
    const orderBillShip = useSelector(cartOrderBillShipSelector);
    const isCaregiver = useSelector(accountProfilIsCaregiverSelector);
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);

    const hasProfileAddresses = Array.isArray(profileAddresses) && profileAddresses.length > 0;
    if (!hasProfileAddresses) {
        return null;
    }

    const addresses: AddressCardProps[] = profileAddresses.map((address) => {
        const cartCurrentShippingSeqNum =
            !isCaregiver && orderBillShip?.dependentAddressSeqNum
                ? orderBillShip?.dependentAddressSeqNum
                : orderBillShip?.patientShipAddressSeq;
        const isCurrentCartShippingAddress = address.addressSeqNum === cartCurrentShippingSeqNum;
        return {
            addressSeqNum: address.addressSeqNum,
            defaultAddress: address.defaultShip,
            isChecked: isCurrentCartShippingAddress,
            address1: address.address1,
            address2: address.address2,
            city: address.city,
            country: address.country,
            state: address.state,
            zipcode: address.zipcode,
            zipcodeFour: address.zipcodeFour,
            defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
            addressType: address.addressTypeDesc,
            isProfile: false
        };
    });

    const handleShipToAddressClick = (address: AddressCardProps) => {
        const editAddress = profileAddresses.find(
            (location) =>
                location.address1 === address.address1 &&
                location.address2 === address.address2 &&
                location.city === address.city &&
                location.state === address.state &&
                location.zipcode === address.zipcode + ''
        );
        const updatedAddress = {
            ...orderBillShip,
            patientBillAddressSeq: editAddress?.addressSeqNum,
            patientShipAddressSeq: editAddress?.addressSeqNum,
            dependentAddressSeqNum: isCaregiver ? orderBillShip?.dependentAddressSeqNum : editAddress?.addressSeqNum
        };

        columnSectionRef?.current?.handleSaveChangesButtonClick();

        dispatch(
            cartUpdateShippingRoutine.trigger({
                ...updatedAddress,
                onSuccess: () => {
                    dispatch(getCartRoutine.trigger());
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <UpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCart'
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            className: 'prescription-modal',
                            bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.address')} />,
                            ctas: [
                                {
                                    label: t('modals.updateCart.labels.gotIt'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ReviewOrderUpdateCartError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    const { address1, address2, city, state, zipcode, zipcodeFour, addressType } = getDefaultAddress(addresses) ?? {};

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="shipping-address__content"
            className="shipping-address"
            editModeContent={
                <ShippingAddresses
                    addressData={addresses}
                    onSetAsShipping={handleShipToAddressClick}
                    isProfile={false}
                    showLabels={true}
                    addNewAddressButtonLabel={t('pages.reviewOrder.addAddress')}
                />
            }
            headerClassName="shipping-address__header"
            title="Shipping Address"
        >
            <address>
                <div className="shipping-address__eyebrow">
                    <p className="shipping-address__type text-left">
                        <small>
                            {t('pages.profile.shippingAddress.addressType', {
                                type: addressType?.toLowerCase()
                            })}
                        </small>
                    </p>
                </div>
                <p>
                    {address1} {address2}
                </p>
                <p>
                    {city}, {state} {zipcodeFour ? `${zipcode}-${zipcodeFour}` : zipcode}
                </p>
            </address>
        </ColumnSectionEditModeToggle>
    );
};
export default ShippingAddress;
