import { PayloadAction } from '@reduxjs/toolkit';
import { FormikHelpers } from 'formik';
import { navigate } from 'gatsby';
import { ENABLE_AMPLIFY_CHATBOT } from 'gatsby-env-variables';
import produce from 'immer';
import qs from 'qs';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { RegistrationFormValues } from 'components/registration-form/registration-form.component';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import { ConfirmationStatuses } from 'components/verify-sms-modal/types';

import { AccountErrorPayload, AccountState } from 'state/account/account.reducers';
import {
    accountAddAddressToProfileRoutine,
    accountAddPaymentRoutine,
    accountAddRepresentativesRoutine,
    accountCancelSmsRequestRoutine,
    accountCheckPendingSmsRequestsRoutine,
    accountCheckSmsRequestsStatusRoutine,
    accountCheckSmsRequestStatusRoutine,
    accountClearMessageThreadRoutine,
    accountClearOrderInvoiceRoutine,
    accountFetchComposeMessageRoutine,
    accountFetchHealthConditionsRoutine,
    accountFetchMessagesRoutine,
    accountFetchNotificationsRoutine,
    accountFetchOrderGetInvoiceRoutine,
    accountFetchOrderHistoryRoutine,
    accountFetchOrderLinesRoutine,
    accountFetchPlansRoutine,
    accountFetchProfileRoutine,
    accountFetchReplyMessageRoutine,
    accountFetchSecurityQuestionsRoutine,
    accountFetchWebProfileRoutine,
    accountForgotUsernameRoutine,
    accountGetAcknowledgementRoutine,
    accountGetAllCreditCardsRoutine,
    accountGetAllRepresentativesRoutine,
    accountGetAwsCredsRoutine,
    accountGetContactUsMessageRoutine,
    accountGetMessageFileDownloadRoutine,
    accountGetMessageFileListRoutine,
    accountGetMessageGetFileDownloadPathRoutine,
    accountGetMessageThreadRoutine,
    accountGetPaymetricDetailsRoutine,
    accountGetTokenizedCardNumberRoutine,
    accountGetUserIpAddressRoutine,
    accountLoginRoutine,
    accountOptOutRoutine,
    accountPostCloseMessageRoutine,
    accountPostComposeMessageRoutine,
    accountPostMessageFileUploadRoutine,
    accountPostReplyMessageRoutine,
    accountRegisterProfileRoutine,
    accountRegisterRoutine,
    accountRemoveAddressFromProfileRoutine,
    accountRemoveCreditCardRoutine,
    accountRemoveRepresentativesRoutine,
    accountResendVerificationEmailRoutine,
    accountResetPasswordRoutine,
    accountSendChangePasswordRequestRoutine,
    accountSendContactUsMessageRoutine,
    accountSendSmsConfirmationRoutine,
    accountSendSmsConfirmationsRoutine,
    accountSetAcknowledgementRoutine,
    accountSetPrimaryAddressRoutine,
    accountSubmitSecurityQuestionAnswerRoutine,
    accountUninsuredRegister,
    accountUpdateAddressToProfileRoutine,
    accountUpdateAllergiesRoutine,
    accountUpdateAutoRefill,
    accountUpdateCreditCardRoutine,
    accountUpdateMedicalConditionsRoutine,
    accountUpdateNotificationsRoutine,
    accountUpdateProfileRoutine,
    accountUpdateSecurePasswordRoutine,
    accountValidatePhoneNumberRoutine,
    accountVerifyPatient
} from 'state/account/account.routines';
import AccountService, {
    AccountSetupPayload,
    AcknowledgementPayload,
    APIAllergiesPayload,
    APIHealthConditionsPayload,
    APIMedicalConditionsPayload,
    ContactUsPayload,
    CreditCardPayload,
    NotificationsPayload,
    PatientRegistrationPayload,
    PaymetricDetailsPayload,
    ProfileObjectAddressPayload,
    ProfileObjectPayload,
    TokenizePaymentCardPayload,
    ValidatePhoneNumberResponse
} from 'state/account/account.services';
import { cartUpdatePaymentRoutine } from 'state/cart/cart.routines';
import { cartSelector } from 'state/cart/cart.selectors';
import { CartObjectPayload } from 'state/cart/cart.services';
import { membershipDetailsRoutine } from 'state/membership/membership.routines';

import { SecureChangePasswordSubmission } from 'types/change-password';
import {
    FetchSecurityQuestionsRequest,
    ResetPasswordRequest,
    SendChangePasswordRequestPayload,
    SubmitSecurityQuestionAnswerRequest
} from 'types/forgot-password';
import { ForgotUsernameRequestPayload, ForgotUsernameResponsePayload } from 'types/forgot-username';
import { Allergies, MedicalConditions } from 'types/health-profile';
import { Login, LoginRequest } from 'types/login';
import {
    AccountRegisterProfilePayload,
    OrderGetInvoiceRequestPayload,
    OrderGetInvoiceResponsePayload,
    OrderHistoryRequestPayload,
    OrderHistoryResponsePayload,
    OrderLinesRequestPayload,
    OrderLinesResponsePayload
} from 'types/order';
import { AccountRemoveCreditCardPayloadAction } from 'types/payment';
import { AssitsOthersFormValues, ProfileFormValues, ProfileUpdateAutoRefill } from 'types/profile';
import {
    SmsConfirmationCancelResponse,
    SmsConfirmationSendRequest,
    SmsConfirmationSendResponse,
    SmsGetConfirmationStatusResponse,
    SMSPendingRequestData,
    SMSPendingRequestResponse,
    SmsTextNumbersToVerify
} from 'types/sms';

import { resendVerificationLinkErrors } from 'util/account';
import { noop } from 'util/function';
import { TrackError, TrackFlowComplete, TrackFormSuccess } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';
import storageHelper from 'util/storageHelper';

import { reduceExistingConditions } from 'hooks/useHealthConditions';

import { formatAccountFinalizeUninsuredData } from './account.helpers';
import {
    accountCreditCardsSelector,
    accountIsLoggedInSelector,
    accountIsMembershipSelector,
    accountProfileAddressesSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector,
    accountRegistrationSelector,
    accountRegistrationUninsuredRegisterErrorPayloadSelector,
    accountSmsPendingRequestsSelector,
    paymetricDetailsSelector
} from './account.selectors';

const FORM_NAME = {
    Allergies: 'Allergies',
    HealthConditions: 'HealthConditions',
    Registration: 'Registration',
    RegisterProfile: 'RegisterProfile',
    AddAddressToProfile: 'AddAddressToProfile',
    UpdateAddressToProfile: 'UpdateAddressToProfile',
    RemoveAddressFromProfile: 'RemoveAddressFromProfile',
    SetPrimaryAddress: 'SetPrimaryAddress',
    UpdateProfile: 'UpdateProfile',
    UpdateSecurePassword: 'UpdateSecurePassword',
    SubmitSecurityQuestionAnswer: 'SubmitSecurityQuestionAnswer',
    ResetPassword: 'ResetPassword',
    RemoveCreditCard: 'RemoveCreditCard',
    UpdateCreditCard: 'UpdateCreditCard',
    SendContactUsMessage: 'SendContactUsMessage'
};

type PostContactUsMessageSaga = {
    data: {
        message: ContactUsPayload;
        isLoggedIn?: boolean;
    };
    onSuccess?: (response: ContactUsPayload) => void;
    onFailure?: (error: any) => void;
};

const reduceHealthProfileResponse = <P extends APIHealthConditionsPayload, T>(response: P): T => {
    const { messageStatus, messageErrorText, messageText, ...reduced } = response;
    return { ...reduced } as T;
};

function* getAccountHealthConditions(): any {
    try {
        const conditionsResponse = yield call(AccountService.medicalConditions().getAll);
        const allergiesResponse = yield call(AccountService.allergies().getAll);

        if (conditionsResponse.error || allergiesResponse.error) {
            throw conditionsResponse.error ?? allergiesResponse.error;
        } else {
            const reducedConditions = reduceHealthProfileResponse<APIMedicalConditionsPayload, MedicalConditions>(
                conditionsResponse
            );
            const reducedAllergies = reduceHealthProfileResponse<APIAllergiesPayload, Allergies>(allergiesResponse);
            yield put(accountVerifyPatient.trigger({ conditions: reducedConditions, allergies: reducedAllergies }));
            yield put(
                accountFetchHealthConditionsRoutine.success({
                    medicalConditions: reducedConditions,
                    allergies: reducedAllergies
                })
            );
        }
    } catch {
        yield put(accountFetchHealthConditionsRoutine.failure());
    }
}

export default function* accountSaga() {
    yield takeLatest(accountFetchHealthConditionsRoutine.TRIGGER, getAccountHealthConditions);
    yield takeLatest(accountFetchOrderHistoryRoutine.TRIGGER, (action: PayloadAction<OrderHistoryRequestPayload>) =>
        baseEffectHandler<OrderHistoryResponsePayload>({
            service: AccountService.orderHistory().get,
            data: action.payload,
            *onResponse(response) {
                yield put(accountFetchOrderHistoryRoutine.success({ orders: response }));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError() {
                yield put(accountFetchOrderHistoryRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        })
    );
    yield takeLatest(accountFetchOrderLinesRoutine.TRIGGER, (action: PayloadAction<OrderLinesRequestPayload>) =>
        baseEffectHandler<OrderLinesResponsePayload>({
            service: AccountService.orderLines().get,
            data: action.payload,
            *onResponse(response) {
                yield put(
                    accountFetchOrderLinesRoutine.success({
                        epostOrderNum: action.payload.epostOrderNum,
                        orderLines: response
                    })
                );
            },
            *onError() {
                yield put(accountFetchOrderLinesRoutine.failure());
            }
        })
    );
    yield takeLatest(
        accountFetchOrderGetInvoiceRoutine.TRIGGER,
        (action: PayloadAction<OrderGetInvoiceRequestPayload>) =>
            baseEffectHandler<OrderGetInvoiceResponsePayload>({
                service: AccountService.orderGetInvoice().get,
                data: action.payload,
                *onResponse(response) {
                    yield put(accountFetchOrderGetInvoiceRoutine.success({ data: response }));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError() {
                    yield put(accountFetchOrderGetInvoiceRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            })
    );
    yield takeLatest(accountClearOrderInvoiceRoutine.TRIGGER, function* () {
        yield put(accountClearOrderInvoiceRoutine.success());
    });
    yield takeLatest(accountUpdateMedicalConditionsRoutine.TRIGGER, (action: PayloadAction<any>) =>
        baseEffectHandler<MedicalConditions>({
            service: AccountService.medicalConditions().post,
            data: action.payload,
            *onResponse() {
                yield put(accountFetchHealthConditionsRoutine.trigger());
                yield put(accountUpdateMedicalConditionsRoutine.success(action.payload));
                TrackFormSuccess(FORM_NAME.HealthConditions);
                TrackFlowComplete(FORM_NAME.HealthConditions);
            },
            *onError() {
                yield put(accountUpdateMedicalConditionsRoutine.failure());
            }
        })
    );
    yield takeLatest(accountUpdateAllergiesRoutine.TRIGGER, (action: PayloadAction<any>) =>
        baseEffectHandler<Allergies>({
            service: AccountService.allergies().post,
            data: action.payload,
            *onResponse() {
                yield put(accountFetchHealthConditionsRoutine.trigger());
                yield put(accountUpdateAllergiesRoutine.success(action.payload));
                TrackFormSuccess(FORM_NAME.Allergies);
                TrackFlowComplete(FORM_NAME.Allergies);
            },
            *onError() {
                yield put(accountUpdateAllergiesRoutine.failure());
            }
        })
    );
    yield takeLatest(accountLoginRoutine.TRIGGER, (action: PayloadAction<LoginRequest>) => {
        const data: any = {
            username: action.payload.login.email,
            password: action.payload.login.password,
            grant_type: 'password'
        };
        if (action.payload.login.newEmail) {
            data.email = action.payload.login.newEmail;
        }
        return baseEffectHandler<Login>({
            service: AccountService.login().post,
            data: qs.stringify(data),
            *onResponse(response: any) {
                const { onSuccess, onFailure } = action.payload;
                //  the API response contains an embedded error object that we need to examine and return to the UI for display
                if (response.data && response.data.error) {
                    if (onFailure) {
                        yield put(accountLoginRoutine.failure());
                        onFailure({ error: response.data.error, description: response.data.error_description });
                    }
                } else {
                    yield put(accountLoginRoutine.success(response));
                    if (onSuccess) onSuccess(response);

                    if (window && window.dataLayer) {
                        window.dataLayer.push({ event: 'login', loginStatus: 'logged in' });
                    }
                    // Removing these two API calls, since we are doing a hard pageload
                    // after logging in due to Robin Chatbot Session restrictions.
                    // Keeping here in the hope that we can pass session info to the
                    // chatbot without doing a hard pageload.
                    //
                    //yield put(accountFetchProfileRoutine.trigger());
                    //yield put(getCartRoutine.trigger());

                    // By default, redirect to the medicine cabinet after
                    // signing in. If there is a saved PrescriptionFlowType (part of
                    // the alt flow DRX-826), the user should start in the chosen
                    // prescription work flow page.
                    let redirect = '';
                    const flow = storageHelper.session.getPrescriptionFlowType();
                    if (flow) {
                        redirect = '/secure/prescription';
                    } else {
                        redirect = '/secure/auth';
                    }

                    // Check to see if there is a redirect URL, and if so
                    // redirect there instead.
                    const params = new URLSearchParams(window.location.search);
                    if (params.has('redirect') && params.get('redirect') !== '') {
                        redirect = params.get('redirect')!;
                    }

                    // Because the Robin ChatBot cannot re-initialize with the logged-in token,
                    // a hard pageload was requested (DRX-523) instead of a faster "navigate"
                    if (!ENABLE_AMPLIFY_CHATBOT) {
                        if (window) {
                            window.location.href = redirect;
                        }
                    } else {
                        navigate(redirect);
                    }
                }
            },
            *onError(err: any) {
                const { onFailure } = action.payload;
                yield put(accountLoginRoutine.failure());
                if (onFailure) {
                    onFailure({ error: err.response?.error, description: err.response?.error_description });
                }
            }
        });
    });
    yield takeLatest(
        accountRegisterRoutine.TRIGGER,
        (
            action: PayloadAction<{
                formValues: Partial<RegistrationFormValues>;
                formHelpers: FormikHelpers<Partial<RegistrationFormValues>>;
                errorMessage: string | undefined;
            }>
        ) =>
            baseEffectHandler<RegistrationFormValues>({
                service: AccountService.register().get,
                data: action.payload.formValues,
                *onResponse(data: PatientRegistrationPayload) {
                    // If the patient is already registered, set an error state.
                    if (data.RegCode === 3 && data.messageErrorText === 'Patient is already registered.') {
                        action.payload.formHelpers.setSubmitting(false);
                        TrackError('account.sagas.ts', 'accountRegisterRoutine', data.messageErrorText);
                        action.payload.formHelpers.setStatus(data.messageErrorText);
                        yield put(accountRegisterRoutine.failure(data.messageErrorText));
                    } else {
                        yield put(
                            accountRegisterRoutine.success({
                                patientRegistrationPayload: data,
                                formValues: action.payload.formValues
                            })
                        );
                        TrackFormSuccess(FORM_NAME.Registration);
                        TrackFlowComplete(FORM_NAME.Registration);
                        navigate('/get-started/profile-setup');
                    }
                },
                *onError(err: any) {
                    action.payload.formHelpers.setSubmitting(false);
                    const formError = action.payload.errorMessage
                        ? action.payload.errorMessage
                        : err.response.response.data.SystemMessage;
                    TrackError('account.sagas.ts', 'accountRegisterRoutine', formError);
                    action.payload.formHelpers.setStatus(formError);
                    yield put(accountRegisterRoutine.failure(err));
                }
            })
    );

    yield takeLatest(
        accountRegisterProfileRoutine.TRIGGER,
        function* (action: PayloadAction<AccountRegisterProfilePayload>) {
            const registrationData: AccountState['registration'] = yield select(accountRegistrationSelector);

            yield baseEffectHandler({
                service: AccountService.accountSetup().post,
                data: registrationData.patientRegistration,
                *onResponse({ data }: { data: AccountSetupPayload }) {
                    if (registrationData.patientRegistration !== undefined) {
                        data.Email = (action.payload.formValues as ProfileFormValues).email;
                        data.UserName = (action.payload.formValues as ProfileFormValues).email;
                        data.Password = (action.payload.formValues as ProfileFormValues).password;
                        data.PhoneNumber = (action.payload.formValues as ProfileFormValues).phoneNumber;
                    }
                    yield baseEffectHandler({
                        service: AccountService.accountFinalize().post,
                        data: data,
                        *onResponse({ data }: { data: PatientRegistrationPayload }) {
                            //TODO need to resolve the tos accept here

                            yield put(accountRegisterProfileRoutine.success({}));
                            TrackFormSuccess(FORM_NAME.RegisterProfile);
                            TrackFlowComplete(FORM_NAME.RegisterProfile);
                            yield put(
                                accountLoginRoutine.trigger({
                                    login: {
                                        email: (action.payload.formValues as ProfileFormValues).email,
                                        password: (action.payload.formValues as ProfileFormValues).password
                                    },
                                    onSuccess: () => {
                                        // The accountLoginRoutine has logic to handle what to do after logging in,
                                        // since the user may be coming from a birdi-price drug page.
                                        // navigate('/secure/profile');
                                    }
                                })
                            );
                        },
                        *onError(error: any) {
                            action.payload.formHelpers.setSubmitting(false);
                            action.payload.formHelpers.setStatus(
                                action.payload.errorMessage
                                    ? action.payload.errorMessage
                                    : 'There was an error finalizing your registration'
                            );
                            yield put(accountRegisterProfileRoutine.failure({}));
                            const { onFailure } = action.payload;
                            if (onFailure) {
                                onFailure(error.response?.response?.data);
                            }
                        }
                    });
                },
                *onError(error: any) {
                    action.payload.formHelpers.setSubmitting(false);
                    action.payload.formHelpers.setStatus(
                        action.payload.errorMessage
                            ? action.payload.errorMessage
                            : 'There was an error finalizing your registration'
                    );
                    yield put(accountRegisterProfileRoutine.failure({}));
                    const { onFailure } = action.payload;
                    if (onFailure) {
                        onFailure();
                    }
                }
            });
        }
    );

    yield takeLatest(accountFetchProfileRoutine.TRIGGER, function* () {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.fetchProfile().get,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data: any) {
                // Get acknowledgement info before returning success
                yield put(
                    accountGetAcknowledgementRoutine.trigger({
                        patientId: data?.epostPatientNum
                    })
                );
                yield put(accountFetchProfileRoutine.success(data));

                // When a user has membership, the membershipDetails are accessed at the state level.
                // This approach prevents overburdening the component with additional responsibilities
                // and retrieves data efficiently from the component lifecycle.
                if (data.hasMembership && data.membershipID) {
                    yield put(membershipDetailsRoutine.trigger({ membershipId: data?.membershipID }));
                }
            },
            *onError(data: Login) {
                yield put(accountFetchProfileRoutine.failure(data));
            }
        });
    });
    yield takeLatest(accountFetchPlansRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);
        const familyMembers: string[] = isCaregiver
            ? action.payload.familyMembers.map((member: any) => member.value)
            : action.payload.familyMembers.map((member: string) => member);

        yield baseEffectHandler({
            service: AccountService.fetchPlans(familyMembers).get,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data: any) {
                yield put(accountFetchPlansRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountFetchPlansRoutine.failure(data));
            }
        });
    });
    yield takeLatest(
        accountAddAddressToProfileRoutine.TRIGGER,
        function* (action: PayloadAction<ProfileObjectAddressPayload>) {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const profileAddresses: ProfileObjectAddressPayload[] = yield select(accountProfileAddressesSelector);

            let addresses: ProfileObjectAddressPayload[] = profileObject.addresses;

            //If address is default address then set other addresses to non default
            if (action.payload.defaultAddress) {
                addresses = addresses.map((address) => {
                    return {
                        ...address,
                        defaultBill: false,
                        defaultShip: false,
                        currentShipping: false
                    };
                });
            }

            const defaultBill =
                !profileAddresses || profileAddresses.length === 0 || action.payload.defaultAddress ? true : false;
            const defaultShip =
                !profileAddresses || profileAddresses.length === 0 || action.payload.defaultAddress ? true : false;

            //Add new address to the profile addresses
            const profileObjectPayload: ProfileObjectPayload = {
                ...profileObject,
                addresses: [
                    ...addresses,
                    {
                        ...action.payload,
                        defaultBill,
                        defaultShip,
                        currentShipping: action.payload.defaultAddress ? true : false,
                        epostPatientNum: profileObject.epostPatientNum
                    }
                ].sort((a, b) => Number(b.defaultShip) - Number(a.defaultShip))
            };

            yield baseEffectHandler({
                service: AccountService.fetchProfile().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: profileObjectPayload,
                *onResponse(data: any) {
                    yield put(accountUpdateProfileRoutine.success(data));
                    TrackFormSuccess(FORM_NAME.AddAddressToProfile);
                    TrackFlowComplete(FORM_NAME.AddAddressToProfile);
                    const { onSuccess } = action.payload;
                    if (onSuccess) {
                        onSuccess();
                    }
                },
                *onError(data: Login) {
                    yield put(accountUpdateProfileRoutine.failure(profileObject));
                    const { onFailure } = action.payload;
                    if (onFailure) {
                        onFailure();
                    }
                }
            });
        }
    );
    yield takeLatest(
        accountUpdateAddressToProfileRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                index: number;
                address: ProfileObjectAddressPayload;
                onSuccess?: () => void;
                onFailure?: () => void;
            }>
        ) {
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const profileObjectPayload: ProfileObjectPayload = produce(profileObject, (draftProfileObject) => {
                draftProfileObject.addresses[action.payload.index] = {
                    ...draftProfileObject.addresses[action.payload.index],
                    addressTypeDesc: action.payload.address.addressTypeDesc,
                    addressTypeNum: action.payload.address.addressTypeNum,
                    address1: action.payload.address.address1,
                    address2: action.payload.address.address2,
                    city: action.payload.address.city,
                    state: action.payload.address.state,
                    zipcode: action.payload.address.zipcode,
                    zipcodeFour: action.payload.address.zipcodeFour
                };
            });

            yield baseEffectHandler<any>({
                service: AccountService.fetchProfile().post,
                data: profileObjectPayload,
                *onResponse(data: any) {
                    yield put(accountUpdateProfileRoutine.success(data));
                    TrackFormSuccess(FORM_NAME.UpdateAddressToProfile);
                    TrackFlowComplete(FORM_NAME.UpdateAddressToProfile);
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError(data: Login) {
                    yield put(accountFetchProfileRoutine.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );

    yield takeLatest(
        accountRemoveAddressFromProfileRoutine.TRIGGER,
        function* (action: PayloadAction<AddressesPayloadObject>) {
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);

            const addressToRemove = {
                ...profileObject.addresses.find(
                    (address) =>
                        address.address1 === action.payload.address.address1 &&
                        address.address2 === action.payload.address.address2 &&
                        address.city === action.payload.address.city &&
                        address.state === action.payload.address.state &&
                        address.zipcode === action.payload.address.zipcode.toString()
                )
            };
            if (addressToRemove) {
                addressToRemove.active = false;
            }
            const profileObjectPayload: ProfileObjectPayload = {
                ...profileObject,
                addresses: [
                    ...profileObject.addresses.filter(
                        (address) =>
                            address.address1 !== action.payload.address.address1 &&
                            address.address2 !== action.payload.address.address2 &&
                            address.city !== action.payload.address.city &&
                            address.state !== action.payload.address.state &&
                            address.zipcode !== action.payload.address.zipcode.toString()
                    )
                ]
            };
            if (addressToRemove) {
                profileObjectPayload.addresses.push(addressToRemove as any);
            }

            yield baseEffectHandler<ProfileObjectPayload>({
                service: AccountService.fetchProfile().post,
                data: profileObjectPayload,
                *onResponse(data: ProfileObjectPayload) {
                    yield put(accountUpdateProfileRoutine.success(data));
                    TrackFormSuccess(FORM_NAME.RemoveAddressFromProfile);
                    TrackFlowComplete(FORM_NAME.RemoveAddressFromProfile);
                    const { onSuccess } = action.payload;
                    if (onSuccess) {
                        onSuccess();
                    }
                },
                *onError(data: Login) {
                    yield put(accountUpdateProfileRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) {
                        onFailure();
                    }
                }
            });
        }
    );

    yield takeLatest(accountSetPrimaryAddressRoutine.TRIGGER, function* (action: PayloadAction<AddressCardProps>) {
        const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
        const profileObjectPayload: ProfileObjectPayload = produce(profileObject, (draftProfileObject) => {
            const newPrimaryAddressIndex = draftProfileObject.addresses.findIndex(
                (address) =>
                    address.address1 === action.payload.address1 &&
                    address.address2 === action.payload.address2 &&
                    address.city === action.payload.city &&
                    address.state === action.payload.state &&
                    address.zipcode === action.payload.zipcode.toString()
            );

            const updatedAddressPayload = draftProfileObject.addresses
                .map((address, index) => {
                    const isNewPrimaryAddress = index === newPrimaryAddressIndex;
                    return {
                        ...address,
                        defaultShip: isNewPrimaryAddress,
                        currentShipping: isNewPrimaryAddress,
                        defaultBill: isNewPrimaryAddress
                    };
                })
                .sort((a, b) => Number(b.defaultShip) - Number(a.defaultShip));
            draftProfileObject.addresses = [...updatedAddressPayload];
        });

        yield baseEffectHandler<ProfileObjectPayload>({
            service: AccountService.fetchProfile().post,
            data: profileObjectPayload,
            *onResponse(data: ProfileObjectPayload) {
                yield put(accountUpdateProfileRoutine.success(data));
                TrackFormSuccess(FORM_NAME.SetPrimaryAddress);
                TrackFlowComplete(FORM_NAME.SetPrimaryAddress);
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(data: Login) {
                const { onFailure } = action.payload;
                if (onFailure) {
                    onFailure();
                } else {
                    alert('Failure to update profile primary address');
                }
                yield put(accountFetchProfileRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountUpdateProfileRoutine.TRIGGER, (action: PayloadAction<ProfileObjectPayload>) => {
        const { onSuccess, onFailure, ...updatedProfile } = action.payload;
        return baseEffectHandler<ProfileObjectPayload>({
            service: AccountService.fetchProfile().post,
            data: updatedProfile,
            *onResponse(data: ProfileObjectPayload) {
                yield put(accountUpdateProfileRoutine.success(data));
                yield put(membershipDetailsRoutine.trigger({ membershipId: data?.membershipID }));
                TrackFormSuccess(FORM_NAME.UpdateProfile);
                TrackFlowComplete(FORM_NAME.UpdateProfile);
                if (onSuccess) onSuccess();
            },
            *onError(error: any) {
                yield put(accountFetchProfileRoutine.failure(error.response.data));
                if (onFailure) {
                    onFailure(error.response?.data);
                } else {
                    alert('Failure to update profile data');
                }
            }
        });
    });

    yield takeLatest(accountFetchWebProfileRoutine.TRIGGER, () =>
        baseEffectHandler({
            service: AccountService.fetchWebProfile().get,
            *onResponse(data) {
                yield put(accountFetchWebProfileRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountFetchWebProfileRoutine.failure(data));
            }
        })
    );

    yield takeLatest(
        accountUpdateSecurePasswordRoutine.TRIGGER,
        (action: PayloadAction<SecureChangePasswordSubmission>) =>
            baseEffectHandler<SecureChangePasswordSubmission>({
                service: AccountService.changePassword().update,
                data: action.payload,
                *onResponse({ data }: any) {
                    if (data.length === 0) {
                        // Done this way because the API will return a "success" response even if the request is a failure
                        yield put(accountUpdateSecurePasswordRoutine.success(true));
                        TrackFormSuccess(FORM_NAME.UpdateSecurePassword);
                        TrackFlowComplete(FORM_NAME.UpdateSecurePassword);
                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess();
                    } else {
                        // This will run if the API call is successful but the password is not reset
                        // because, for example, the user did not enter their current password correctly.
                        yield put(accountUpdateSecurePasswordRoutine.failure(data));
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure(data);
                    }
                },
                *onError(err) {
                    // This will run if the API call fails completely.
                    const message = err.message ?? 'Failure to update password';
                    yield put(accountUpdateSecurePasswordRoutine.failure(message));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(message);
                }
            })
    );

    yield takeLatest(
        accountFetchSecurityQuestionsRoutine.TRIGGER,
        (action: PayloadAction<FetchSecurityQuestionsRequest>) =>
            baseEffectHandler({
                service: AccountService.fetchSecurityQuestions().get,
                data: action.payload.username,
                *onResponse(response) {
                    yield put(accountFetchSecurityQuestionsRoutine.success(response));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess(response);
                },
                *onError() {
                    yield put(accountFetchSecurityQuestionsRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            })
    );

    yield takeLatest(
        accountSubmitSecurityQuestionAnswerRoutine.TRIGGER,
        (action: PayloadAction<SubmitSecurityQuestionAnswerRequest>) =>
            baseEffectHandler({
                service: AccountService.submitSecurityQuestionAnswer().post,
                data: action.payload.accountSecurity,
                *onResponse(response) {
                    //  if there is any response it's a failure
                    if (response && response.length > 0) {
                        yield put(accountSubmitSecurityQuestionAnswerRoutine.failure());
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure();
                    } else {
                        yield put(accountSubmitSecurityQuestionAnswerRoutine.success());
                        TrackFormSuccess(FORM_NAME.SubmitSecurityQuestionAnswer);
                        TrackFlowComplete(FORM_NAME.SubmitSecurityQuestionAnswer);
                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess();
                    }
                },
                *onError(err) {
                    yield put(accountSubmitSecurityQuestionAnswerRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            })
    );

    yield takeLatest(accountResetPasswordRoutine.TRIGGER, (action: PayloadAction<ResetPasswordRequest>) =>
        baseEffectHandler({
            service: AccountService.resetPasswordWithToken().post,
            data: action.payload.accountSecurity,
            *onResponse(response) {
                //  if there is any response it's a failure
                if (response && response.length > 0) {
                    yield put(accountResetPasswordRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(response);
                } else {
                    yield put(accountResetPasswordRoutine.success());
                    TrackFormSuccess(FORM_NAME.ResetPassword);
                    TrackFlowComplete(FORM_NAME.ResetPassword);
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                }
            },
            *onError(err) {
                yield put(accountResetPasswordRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        })
    );

    yield takeLatest(
        accountSendChangePasswordRequestRoutine.TRIGGER,
        (action: PayloadAction<SendChangePasswordRequestPayload>) =>
            baseEffectHandler({
                service: AccountService.sendChangePasswordRequest().post,
                data: { username: action.payload.username },
                *onResponse(_response) {
                    const { onSuccess = noop } = action.payload;

                    yield put(accountSendChangePasswordRequestRoutine.success());
                    onSuccess();
                },
                *onError(_error) {
                    const { onFailure = noop } = action.payload;

                    yield put(accountSendChangePasswordRequestRoutine.failure());
                    onFailure();
                }
            })
    );

    yield takeLatest(accountGetAllRepresentativesRoutine.TRIGGER, function* () {
        yield baseEffectHandler({
            service: AccountService.representativesMethods().getAll,
            *onResponse(data) {
                yield put(accountGetAllRepresentativesRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountGetAllRepresentativesRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountAddRepresentativesRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: AccountService.representativesMethods().post,
            data: action.payload,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(accountAddRepresentativesRoutine.success(data));
            },
            *onError(data) {
                console.error(data);
                yield put(accountAddRepresentativesRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountRemoveRepresentativesRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: AccountService.representativesMethods().delete,
            data: action.payload,
            *onResponse(data) {
                yield put(accountRemoveRepresentativesRoutine.success(data));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(data) {
                yield put(accountRemoveRepresentativesRoutine.failure([]));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });

    yield takeLatest(accountGetAllCreditCardsRoutine.TRIGGER, function* () {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        const isMembership: boolean | undefined = yield select(accountIsMembershipSelector);

        yield baseEffectHandler({
            service: isMembership
                ? AccountService.paymentMethods().getAllMembership
                : AccountService.paymentMethods().getAll,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                // sort by default first (a requirement), then sort by card number which simply adds some consistency to the
                // order cards are displayed
                function compareCards(cardA: any, cardB: any) {
                    if (cardA.defaultCard === cardB.defaultCard) {
                        return cardA.cardNumber.toLocaleLowerCase().localeCompare(cardB.cardNumber.toLocaleLowerCase());
                    } else {
                        return cardA.defaultCard ? -1 : 1;
                    }
                }

                if (isMembership) {
                    const creditCards: CreditCardPayload[] =
                        Array.isArray(data.Cards) && data.Cards.length > 0 ? data.Cards : [];
                    const sortedCreditCards = [...creditCards]
                        .map((item) => {
                            return {
                                ...item,
                                // data conversion and processing required for the new api
                                /**
                                 * DRX-2648 - Related ticket (https://mincainc.atlassian.net/browse/DRX-2648)
                                 * @cardSeqNum This property is treated here as a string to adapt to all the used components. Please remove it when the API updates this return..*/
                                cardSeqNum: String(item.cardSeqNum),
                                defaultCard: item.defaultCard === 'Y' ? true : false,
                                FSACard: item.FSACard,
                                secureCardNumber: item.secureCardNumber,
                                cardName: `${item.cardFirstName} ${item.cardLastName}`
                            };
                        })
                        .sort(compareCards);

                    yield put(accountGetAllCreditCardsRoutine.success(sortedCreditCards));

                    return;
                }

                const creditCards: CreditCardPayload[] = Array.isArray(data) && data.length > 0 ? data : [];
                const sortedCreditCards = [...creditCards].sort(compareCards);

                yield put(accountGetAllCreditCardsRoutine.success(sortedCreditCards));
            },
            *onError(data) {
                // Done this way because the API is throwing an error (now) when a user has no cards on file
                yield put(accountGetAllCreditCardsRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountAddPaymentRoutine.TRIGGER, function* (action: PayloadAction<CreditCardPayload>) {
        const creditCards: CreditCardPayload[] = yield select(accountCreditCardsSelector);
        const cartObject: CartObjectPayload = yield select(cartSelector);
        const isMembership: boolean | undefined = yield select(accountIsMembershipSelector);

        const newCard: any = action.payload;
        if (!creditCards || creditCards.length === 0) {
            newCard.defaultCard = isMembership ? 'Y' : true;
        }

        yield baseEffectHandler<CreditCardPayload>({
            service: isMembership
                ? AccountService.paymentMethods().postMembership
                : AccountService.paymentMethods().post,
            data: isMembership
                ? {
                      ...newCard,
                      cardSeqNum: -1
                  }
                : newCard,
            *onResponse(data: CreditCardPayload) {
                const { onSuccess, onFailure } = action.payload;
                const updatedCreditCardPayload: CreditCardPayload[] = creditCards ? [...creditCards, data] : [data];

                if (data.messageStatus === false) {
                    if (onFailure) onFailure();
                    yield put(accountAddPaymentRoutine.failure(data));
                    return;
                }

                yield put(accountAddPaymentRoutine.success(updatedCreditCardPayload));
                yield put(accountGetAllCreditCardsRoutine.trigger());
                if (onSuccess) onSuccess();
                if (cartObject !== undefined && updatedCreditCardPayload.length === 1) {
                    const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                        draftCartObject.orderBillShip.paymentCardSeqNum = data.cardSeqNum;
                    });
                    yield put(cartUpdatePaymentRoutine.trigger({ ...cartObjectPayload.orderBillShip }));
                }
            },
            *onError(data: any) {
                yield put(accountAddPaymentRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountRemoveCreditCardRoutine.TRIGGER, function* (action: AccountRemoveCreditCardPayloadAction) {
        const creditCards: CreditCardPayload[] = yield select(accountCreditCardsSelector);
        const isMembership: boolean | undefined = yield select(accountIsMembershipSelector);
        const { removalItem, onSuccess, onFailure } = action.payload;

        let cardToRemove;

        if (isMembership) {
            cardToRemove = creditCards.find(
                (card) =>
                    String(card.cardSeqNum) === removalItem.cardSeqNum &&
                    card.secureCardNumber === removalItem.secureCardNumber &&
                    card.cardMonthDesc === removalItem.cardMonthDesc &&
                    card.cardYear === removalItem.cardYear
            );

            const parsedCard = isMembership
                ? {
                      ...removalItem,
                      cardSeqNum: Number(removalItem?.cardSeqNum),
                      conversionLink: null,
                      companyCard: null,
                      lastUsed: null,
                      secureCardNumber: undefined,
                      defaultCard: removalItem.defaultCard === true ? 'Y' : 'N',
                      cardActive: false
                  }
                : cardToRemove;

            cardToRemove = parsedCard;
        } else {
            cardToRemove = {
                ...creditCards.find(
                    (card) =>
                        card.cardSeqNum === removalItem.cardSeqNum &&
                        card.secureCardNumber === removalItem.secureCardNumber &&
                        card.cardMonthDesc === removalItem.cardMonthDesc &&
                        card.cardYear === removalItem.cardYear
                )
            };
            if (cardToRemove) {
                cardToRemove.cardActive = false;
            }
        }

        yield baseEffectHandler<CreditCardPayload>({
            service: isMembership
                ? AccountService.paymentMethods().postMembership
                : AccountService.paymentMethods().post,
            data: cardToRemove,
            *onResponse(data: CreditCardPayload) {
                const updateIndex = creditCards.indexOf(removalItem);
                const updatedPayload = creditCards.map((card, index) => {
                    return index !== updateIndex ? card : data;
                });
                yield put(accountRemoveCreditCardRoutine.success(updatedPayload));
                yield put(accountGetAllCreditCardsRoutine.trigger());
                TrackFormSuccess(FORM_NAME.RemoveCreditCard);
                TrackFlowComplete(FORM_NAME.RemoveCreditCard);
                if (onSuccess) onSuccess(updatedPayload);
            },
            *onError(data: any) {
                if (onFailure) onFailure(data);
                yield put(accountRemoveCreditCardRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountUpdateCreditCardRoutine.TRIGGER, function* (action: PayloadAction<CreditCardPayload>) {
        const creditCards: CreditCardPayload[] = yield select(accountCreditCardsSelector);
        const hasMembership: boolean | undefined = yield select(accountIsMembershipSelector);

        const { onSuccess, onFailure } = action.payload;

        let cartToUpdate;

        if (hasMembership) {
            cartToUpdate = {
                ...creditCards.find(
                    (card) =>
                        String(card.cardSeqNum) === action.payload.cardSeqNum &&
                        card.secureCardNumber === action.payload.secureCardNumber &&
                        card.cardMonthDesc === action.payload.cardMonthDesc &&
                        card.cardYear === action.payload.cardYear
                )
            };

            const parseCard = {
                ...cartToUpdate,
                cardSeqNum: Number(action.payload.cardSeqNum),
                conversionLink: null,
                companyCard: null,
                lastUsed: null,
                secureCardNumber: undefined,
                defaultCard: hasMembership ? 'Y' : true
            };

            cartToUpdate = parseCard;
        } else {
            cartToUpdate = {
                ...creditCards.find(
                    (card) =>
                        String(card.cardSeqNum) === action.payload.cardSeqNum &&
                        card.secureCardNumber === action.payload.secureCardNumber &&
                        card.cardMonthDesc === action.payload.cardMonthDesc &&
                        card.cardYear === action.payload.cardYear
                )
            };

            if (cartToUpdate) {
                cartToUpdate.defaultCard = hasMembership ? 'Y' : true;
            }
        }

        yield baseEffectHandler<CreditCardPayload>({
            service: hasMembership
                ? AccountService.paymentMethods().postMembership
                : AccountService.paymentMethods().post,
            data: cartToUpdate,
            // need to parse the data
            *onResponse(data: CreditCardPayload) {
                const updateIndex = creditCards.indexOf(action.payload);
                const updatedPayload = creditCards.map((card, index) => {
                    let newCard = Object.assign({}, card);
                    if (index !== updateIndex) {
                        newCard.defaultCard = false;
                    } else {
                        newCard = data;
                    }
                    return newCard;
                });
                if (onSuccess) onSuccess();
                yield put(accountUpdateCreditCardRoutine.success(updatedPayload));
                TrackFormSuccess(FORM_NAME.UpdateCreditCard);
                TrackFlowComplete(FORM_NAME.UpdateCreditCard);
            },
            *onError(data: Login) {
                if (onFailure) onFailure();
                yield put(accountUpdateCreditCardRoutine.failure(hasMembership ? '' : data));
            }
        });
    });

    yield takeLatest(accountGetPaymetricDetailsRoutine.TRIGGER, function* () {
        const isMembership: boolean | undefined = yield select(accountIsMembershipSelector);

        yield baseEffectHandler({
            service: isMembership
                ? AccountService.paymentMethods().getAccessIDForMembership
                : AccountService.paymentMethods().getAccessID,
            *onResponse(data) {
                yield put(accountGetPaymetricDetailsRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountGetPaymetricDetailsRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountGetTokenizedCardNumberRoutine.TRIGGER, function* () {
        const isMembership: boolean | undefined = yield select(accountIsMembershipSelector);

        yield baseEffectHandler({
            service: isMembership
                ? AccountService.paymentMethods().getTokenizedMembershipCardNumber
                : AccountService.paymentMethods().getTokenizedCardNumber,
            *onResponse(data) {
                yield put(accountGetTokenizedCardNumberRoutine.success(data));
            },
            *onError(data: Login) {
                yield put(accountGetTokenizedCardNumberRoutine.failure(data));
            }
        });
    });

    yield takeLatest(
        accountGetTokenizedCardNumberRoutine.TRIGGER,
        function* (action: PayloadAction<TokenizePaymentCardPayload>) {
            const paymetricDetails: PaymetricDetailsPayload = yield select(paymetricDetailsSelector);
            const isMembership: boolean | undefined = yield select(accountIsMembershipSelector);
            const accessToken = paymetricDetails?.AccessToken;

            yield baseEffectHandler({
                service: isMembership
                    ? AccountService.paymentMethods().getTokenizedMembershipCardNumber
                    : AccountService.paymentMethods().getTokenizedCardNumber,
                data: accessToken,
                *onResponse(data) {
                    if (data.Body.length !== 0 && data.Body !== null) {
                        // Done this way because the API will return a "success" response even if the request is a failure
                        yield put(accountGetTokenizedCardNumberRoutine.success(data));
                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess(data.Body);
                    } else {
                        yield put(accountGetTokenizedCardNumberRoutine.failure(data));
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure(data);
                    }
                }
            });
        }
    );

    yield takeLatest(accountGetContactUsMessageRoutine.TRIGGER, function* (action: PayloadAction<boolean>) {
        yield baseEffectHandler<boolean>({
            service: AccountService.contactUs().get,
            data: action.payload,
            *onResponse(data: ContactUsPayload) {
                yield put(accountGetContactUsMessageRoutine.success(data));
            },
            *onError(data) {
                yield put(accountGetContactUsMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(
        accountSendContactUsMessageRoutine.TRIGGER,
        function* (action: PayloadAction<PostContactUsMessageSaga>) {
            const { onSuccess, onFailure } = action.payload;

            yield baseEffectHandler<any>({
                service: AccountService.contactUs().post,
                data: action.payload.data,
                *onResponse(data: ContactUsPayload) {
                    yield put(accountSendContactUsMessageRoutine.success(data));
                    TrackFormSuccess(FORM_NAME.SendContactUsMessage);
                    TrackFlowComplete(FORM_NAME.SendContactUsMessage);
                    if (onSuccess) onSuccess(data);
                },
                *onError(data: any) {
                    yield put(accountSendContactUsMessageRoutine.failure(data));
                    if (onFailure) onFailure(data);
                }
            });
        }
    );

    yield takeLatest(
        accountForgotUsernameRoutine.TRIGGER,
        function* (action: PayloadAction<ForgotUsernameRequestPayload>) {
            yield baseEffectHandler<ForgotUsernameRequestPayload>({
                service: AccountService.forgotUsername().get,
                data: action.payload,
                *onResponse(data: ForgotUsernameResponsePayload) {
                    yield put(accountForgotUsernameRoutine.success());
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                },
                *onError(data: any) {
                    yield put(accountForgotUsernameRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );

    yield takeLatest(accountFetchNotificationsRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler<NotificationsPayload>({
            service: AccountService.notifications().get,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data: NotificationsPayload) {
                yield put(accountFetchNotificationsRoutine.success(data));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(data);
            },
            *onError(data: any) {
                yield put(accountFetchNotificationsRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountUpdateNotificationsRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler<NotificationsPayload>({
            service: AccountService.notifications().post,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                // Done this way because the API returns a "successful" response even if the API fails
                if (data.messageErrorText === null) {
                    yield put(accountUpdateNotificationsRoutine.success(action.payload));
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess(action.payload);
                } else {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            },
            *onError(data: any) {
                yield put(accountUpdateNotificationsRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountFetchMessagesRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.message(undefined).getAll,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountFetchMessagesRoutine.success(data));
            },
            *onError(data) {
                yield put(accountFetchMessagesRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountFetchComposeMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.message(undefined).getCompose,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountFetchComposeMessageRoutine.success(data));
            },
            *onError(data) {
                yield put(accountFetchComposeMessageRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountPostComposeMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler({
            service: AccountService.message(action.payload.messageType).postMessage,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                // The ContactUs API returns an error as 'messageErrorText' vs. a 400 response.
                // When ContactUs is successful, `messageErrorText` will be an empty string,
                // whereas when AskThePharm is successful, there is nothing returned.
                if (data && (data.messageErrorText !== '' || !data.messageStatus)) {
                    yield put(accountPostComposeMessageRoutine.failure(data));
                    TrackError('account.sagas.ts', 'accountPostComposeMessageRoutine', data.messageErrorText);
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(data);
                } else {
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                    yield put(accountPostComposeMessageRoutine.success(data));
                }
            },
            *onError(data) {
                console.error(data);
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
                yield put(accountPostComposeMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountFetchReplyMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler({
            service: AccountService.message(action.payload.messageType).getReply,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                data.messageType = action.payload.messageType;
                yield put(accountFetchReplyMessageRoutine.success(data));
            },
            *onError(data) {
                yield put(accountFetchReplyMessageRoutine.failure([]));
            }
        });
    });

    yield takeLatest(accountPostReplyMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler({
            service: AccountService.message(action.payload.messageType).postReply,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                // The ContactUs API returns an error as 'messageErrorText' vs. a 400 response.
                // When ContactUs is successful, `messageErrorText` will be an empty string,
                // whereas when AskThePharm is successful, there is nothing returned.
                if (data && (data.messageErrorText !== null || !data.messageStatus)) {
                    yield put(accountPostReplyMessageRoutine.failure(data));
                    TrackError('account.sagas.ts', 'accountPostReplyMessageRoutine', data.messageErrorText);
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure(data);
                } else {
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                    yield put(accountPostReplyMessageRoutine.success(data));
                }
            },
            *onError(data) {
                console.error(data);
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
                yield put(accountPostReplyMessageRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountGetMessageThreadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);

        yield baseEffectHandler({
            service: AccountService.message(action.payload.messageType).getMessageThread,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                const threadData = {
                    replies: data,
                    subject: action.payload.Subject,
                    messageSource: action.payload.messageSource,
                    threadStatus: action.payload.Action
                };
                yield put(accountGetMessageThreadRoutine.success(threadData));
            },
            *onError(data) {
                console.error(data);
                yield put(accountGetMessageThreadRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountPostCloseMessageRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        yield baseEffectHandler({
            service: AccountService.message(action.payload.messageType).closeMessage,
            data: action.payload,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountPostCloseMessageRoutine.success(data));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
            },
            *onError(data) {
                yield put(accountPostCloseMessageRoutine.failure([]));
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });

    yield takeLatest(accountGetMessageFileListRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: AccountService.message(action.payload.messageType).fileList,
            data: action.payload,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(accountGetMessageFileListRoutine.success(data));
            },
            *onError(error: any) {
                yield put(accountGetMessageFileListRoutine.failure(error));
                if (error.response?.data?.SystemMessage) {
                    TrackError(
                        'account.sagas.ts',
                        'accountGetMessageFileListRoutine',
                        error.response.data.SystemMessage
                    );
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountGetMessageFileListRoutine',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error.response?.data ? error.response.data : error);
            }
        });
    });

    yield takeLatest(accountPostMessageFileUploadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: AccountService.message(action.payload.messageType).fileUpload,
            data: action.payload,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess();
                yield put(accountPostMessageFileUploadRoutine.success(data));
            },
            *onError(error: any) {
                yield put(accountPostMessageFileUploadRoutine.failure(error));
                if (error.response?.data?.SystemMessage) {
                    TrackError(
                        'account.sagas.ts',
                        'accountPostMessageFileUploadRoutine',
                        error.response.data.SystemMessage
                    );
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountPostMessageFileUploadRoutine',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error.response?.data ? error.response.data : error);
            }
        });
    });

    yield takeLatest(accountGetMessageFileDownloadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            // "message" is not used for download files, it has to be explicited set as undefined
            service: AccountService.message(undefined).fileDownload,
            data: action.payload,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(data);
                yield put(accountGetMessageFileDownloadRoutine.success(data));
            },
            *onError(error: any) {
                yield put(accountGetMessageFileDownloadRoutine.failure(error));
                if (error.response?.data?.SystemMessage) {
                    TrackError(
                        'account.sagas.ts',
                        'accountGetMessageFileDownloadRoutine',
                        error.response.data.SystemMessage
                    );
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountGetMessageFileDownloadRoutine',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error.response?.data ? error.response.data : error);
            }
        });
    });

    yield takeLatest(accountGetMessageGetFileDownloadPathRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: AccountService.message(action.payload.messageType).fileDownloadPath,
            data: action.payload,
            *onResponse(data) {
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(data);
                yield put(accountGetMessageGetFileDownloadPathRoutine.success(data));
            },
            *onError(error: any) {
                yield put(accountGetMessageGetFileDownloadPathRoutine.failure(error));
                if (error.response?.data?.SystemMessage) {
                    TrackError(
                        'account.sagas.ts',
                        'accountGetMessageGetFileDownloadPathRoutine',
                        error.response.data.SystemMessage
                    );
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountGetMessageGetFileDownloadPathRoutine',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error.response?.data ? error.response.data : error);
            }
        });
    });

    yield takeLatest(accountClearMessageThreadRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield put(accountClearMessageThreadRoutine.success({}));
    });

    yield takeLatest(accountGetAcknowledgementRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        const { patientId } = action.payload;
        yield baseEffectHandler<AcknowledgementPayload>({
            service: AccountService.acknowledgement().getAll,
            data: patientId,
            isAuthenticatedService: true,
            isLoggedIn,
            *onResponse(data) {
                yield put(accountGetAcknowledgementRoutine.success(data));
            },
            *onError(data: any) {
                yield put(accountGetAcknowledgementRoutine.failure(data));
            }
        });
    });

    yield takeLatest(accountSetAcknowledgementRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        const {
            data: { patientId, clientIP, currentVID },
            onSuccess
        } = action.payload;

        if (currentVID) {
            const termsOfServiceAcknowledgementObject = {
                EPostPatientNum: patientId,
                version: currentVID,
                type: 'Terms of Use',
                timestamp: new Date().toISOString(),
                source: 'Birdi Website',
                sourceIp: clientIP
            };

            // if current version is passed in, it was out of date, so run both
            yield baseEffectHandler<AcknowledgementPayload>({
                service: AccountService.acknowledgement().postAll,
                data: {
                    patientId: patientId,
                    termsOfServiceAcknowledgementObject: termsOfServiceAcknowledgementObject
                },
                isAuthenticatedService: true,
                isLoggedIn,
                *onResponse(data) {
                    // Since the "save" response doesn't return the data, do a GET to refresh
                    yield put(
                        accountGetAcknowledgementRoutine.trigger({
                            patientId: patientId
                        })
                    );
                    yield put(accountSetAcknowledgementRoutine.success(data));
                    if (onSuccess) onSuccess();
                },
                *onError(data: any) {
                    yield put(accountSetAcknowledgementRoutine.failure(data));
                }
            });
        } else {
            // Always run HIPAA
            yield baseEffectHandler<AcknowledgementPayload>({
                service: AccountService.acknowledgement().postHipaa,
                data: {
                    patientId: patientId
                },
                isAuthenticatedService: true,
                isLoggedIn,
                *onResponse(data) {
                    yield put(accountSetAcknowledgementRoutine.success(data));
                    if (onSuccess) onSuccess();
                },
                *onError(data: any) {
                    yield put(accountSetAcknowledgementRoutine.failure(data));
                }
            });
        }
    });

    yield takeLatest(accountVerifyPatient.TRIGGER, function* (action: PayloadAction<any>) {
        const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
        const hasVerified = storageHelper.session.getVerified();
        if (!hasVerified || hasVerified === 'false') {
            let isVerified = false;
            if (action.payload.conditions && action.payload.allergies) {
                // Check conditions and allergies for stored values
                const existingConditions = reduceExistingConditions(action.payload.conditions);
                const existingWriteInCondition = action.payload.conditions.Other?.length > 0;
                const existingAllergies = reduceExistingConditions(action.payload.allergies);
                const existingWriteInAllergy = action.payload.allergies.Other?.length > 0;

                isVerified =
                    (existingConditions?.length > 0 || existingWriteInCondition) &&
                    (existingAllergies?.length > 0 || existingWriteInAllergy)
                        ? true
                        : false;
            }
            yield baseEffectHandler<any>({
                service: AccountService.verifiedPatient().post,
                data: { status: isVerified },
                isAuthenticatedService: true,
                isLoggedIn,
                *onResponse(data: any) {
                    //storageHelper.session.setVerified(isVerified);
                    yield put(accountVerifyPatient.success(data));
                },
                *onError(data: any) {
                    //storageHelper.session.setVerified(isVerified); // needed because API returns a 400 error even if successful
                    yield put(accountVerifyPatient.failure(data));
                }
            });
        }
    });

    yield takeLatest(accountGetAwsCredsRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: AccountService.getAwsCreds(action.payload.region, action.payload.poolID).get,
            data: action.payload,
            isAuthenticatedService: false,
            *onResponse(data) {
                // Do nothing. The access keys are now available at
                // AWS.config.credentials.
                yield put(accountGetAwsCredsRoutine.success(action.payload));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(data);
            },
            *onError(error: any) {
                // Do nothing.
                yield put(accountGetAwsCredsRoutine.failure(error));
                if (error.response?.data?.messageErrorText) {
                    TrackError('account.sagas.ts', 'accountGetAwsCredsRoutine', error.response.data.messageErrorText);
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountGetAwsCredsRoutine',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error);
            }
        });
    });

    yield takeLatest(accountOptOutRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: AccountService.optout().post,
            data: action.payload,
            isAuthenticatedService: false,
            *onResponse(data) {
                yield put(accountOptOutRoutine.success(action.payload));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(action.payload);
            },
            *onError(error: any) {
                yield put(accountOptOutRoutine.failure(error));
                if (error.response?.data?.messageErrorText) {
                    TrackError('account.sagas.ts', 'accountOptOutRoutine', error.response.data.messageErrorText);
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountOptOutRoutine',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error);
            }
        });
    });
    // SMS Confirmation
    function* sendSmsConfirmationsSaga(payload: {
        phoneNumber: string;
        smsTypes: string[];
        onSuccess?: (data: SmsConfirmationSendResponse) => void;
        onFailure?: () => void;
    }) {
        const { phoneNumber, smsTypes, onSuccess, onFailure } = payload;

        const data: SmsConfirmationSendRequest = {
            PhoneNumber: phoneNumber,
            NewScript: smsTypes.includes('NewScriptText'),
            RefillReminder: smsTypes.includes('RefillReminderText'),
            OrderShipped: smsTypes.includes('OrderShippedText'),
            Marketing: smsTypes.includes('MarketingText')
        };

        yield baseEffectHandler({
            service: AccountService.sendSmsConfirmation().post,
            data,
            isAuthenticatedService: false,
            *onResponse(data: SmsConfirmationSendResponse) {
                yield put(accountSendSmsConfirmationRoutine.success(data));
                if (onSuccess) onSuccess(data);
            },
            *onError(error: any) {
                yield put(accountSendSmsConfirmationRoutine.failure(error));
                if (error.response?.data?.messageErrorText) {
                    TrackError(
                        'account.sagas.ts',
                        'accountSendSmsConfirmationRoutine',
                        error.response.data.messageErrorText
                    );
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountSendSmsConfirmationRoutine',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }
                if (onFailure) onFailure();
            }
        });
    }

    // SMS Double Opt In - Check Pending Requests
    function* checkPendingSmsRequestsSaga(action: PayloadAction<{ onSuccess?: () => void; onFailure?: () => void }>) {
        const { onSuccess, onFailure } = action.payload;
        try {
            yield baseEffectHandler({
                service: AccountService.pendingSmsRequests().get,
                data: {},
                isAuthenticatedService: false,
                *onResponse(data: SMSPendingRequestResponse) {
                    const pendingData = data.data;
                    const newData = pendingData.map((smsData) => {
                        return { ...smsData, status: ConfirmationStatuses.NoChange };
                    });
                    yield put(accountCheckPendingSmsRequestsRoutine.success(newData));
                    if (onSuccess) onSuccess();
                },
                *onError(error: any) {
                    yield put(accountCheckPendingSmsRequestsRoutine.failure(error));
                    if (error.response?.data?.messageErrorText) {
                        TrackError(
                            'account.sagas.ts',
                            'accountCheckPendingSmsRequestsRoutine',
                            error.response.data.messageErrorText
                        );
                    } else {
                        TrackError(
                            'account.sagas.ts',
                            'accountCheckPendingSmsRequestsRoutine',
                            error?.message ? error.message : 'An unknown error occurrred.'
                        );
                    }
                    if (onFailure) onFailure();
                }
            });
        } catch (error) {
            if (onFailure) onFailure();
        }
    }
    yield takeLatest(accountCheckPendingSmsRequestsRoutine.TRIGGER, checkPendingSmsRequestsSaga);

    function* checkSmsRequestStatusSaga(securityToken: string, onFailure?: () => void) {
        try {
            yield baseEffectHandler({
                service: AccountService.getStatusOfSmsConfirmationRequest().get,
                data: securityToken,
                isAuthenticatedService: false,
                *onResponse(response: SmsGetConfirmationStatusResponse) {
                    const responseWithSecurityToken = { ...response, securityToken };
                    yield put(accountCheckSmsRequestStatusRoutine.success(responseWithSecurityToken));
                },
                *onError(error: any) {
                    yield put(accountCheckSmsRequestStatusRoutine.failure(error));
                    if (error.response?.data?.messageErrorText) {
                        TrackError(
                            'account.sagas.ts',
                            'accountCheckSmsRequestStatusRoutine',
                            error.response.data.messageErrorText
                        );
                    } else {
                        TrackError(
                            'account.sagas.ts',
                            'accountCheckSmsRequestStatusRoutine',
                            error?.message ? error.message : 'An unknown error occurrred.'
                        );
                    }
                    onFailure?.();
                }
            });
        } catch (error) {
            yield put(accountCheckSmsRequestStatusRoutine.failure(error));
            TrackError('account.sagas.ts', 'checkSmsRequestStatusSaga', 'Unexpected error occurred.');
            onFailure?.();
        }
    }

    // SMS Double Opt In - Check Status of specific request
    yield takeLatest(accountCheckSmsRequestsStatusRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const { onFailure } = action.payload;
        try {
            const pendingOptins: SMSPendingRequestData[] = yield select(accountSmsPendingRequestsSelector);
            yield all(
                pendingOptins.map((pendingOptin) =>
                    call(checkSmsRequestStatusSaga, pendingOptin.SecurityToken, onFailure)
                )
            );
        } catch (error) {
            yield put(accountCheckSmsRequestsStatusRoutine.failure(error));
        }
    });

    // SMS Double Opt In - Cancel ALL pending requests
    yield takeLatest(
        accountCancelSmsRequestRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                onSuccess?: (response: SmsConfirmationCancelResponse) => void;
                onFailure?: () => void;
            }>
        ) {
            const { onSuccess, onFailure } = action.payload;

            yield baseEffectHandler({
                service: AccountService.cancelSmsConfirmation().post,
                data: {},
                isAuthenticatedService: false,
                *onResponse(response: SmsConfirmationCancelResponse) {
                    yield put(accountCancelSmsRequestRoutine.success(response));
                    if (onSuccess) onSuccess(response);
                },
                *onError(error: any) {
                    yield put(accountCancelSmsRequestRoutine.failure(error));
                    if (error.response?.data?.messageErrorText) {
                        TrackError(
                            'account.sagas.ts',
                            'accountCancelSmsRequestRoutine',
                            error.response.data.messageErrorText
                        );
                    } else {
                        TrackError(
                            'account.sagas.ts',
                            'accountCancelSmsRequestRoutine',
                            error?.message ? error.message : 'An unknown error occurrred.'
                        );
                    }
                    if (onFailure) onFailure();
                }
            });
        }
    );
    yield takeLatest(
        accountSendSmsConfirmationsRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                phoneNumbers: SmsTextNumbersToVerify;
                onSuccess?: () => void;
                onFailure?: () => void;
            }>
        ) {
            const { phoneNumbers, onSuccess, onFailure } = action.payload;
            try {
                yield all(
                    Object.entries(phoneNumbers).map(([phoneNumber, smsTypes]) =>
                        call(sendSmsConfirmationsSaga, { phoneNumber, smsTypes, onSuccess })
                    )
                );
                yield put(accountSendSmsConfirmationsRoutine.success({ showVerifySmsModal: true }));
                if (onSuccess) onSuccess();
            } catch (error) {
                if (onFailure) onFailure();
            }
        }
    );
    yield takeLatest(accountGetUserIpAddressRoutine.TRIGGER, function* () {
        try {
            yield baseEffectHandler({
                service: AccountService.getIpAddress().get,
                isAuthenticatedService: false,
                *onResponse(response: { ip: string }) {
                    // TODO: REMOVE THIS CONSOLE LOG AS ITS JUST FOR TROUBLESHOOTING USER'S IP ADDRESS
                    console.log('Users IP Address: ', response);
                    yield put(accountGetUserIpAddressRoutine.success(response));
                }
            });
        } catch (error) {}
    });

    yield takeLatest(accountUpdateAutoRefill.TRIGGER, function* (action: PayloadAction<ProfileUpdateAutoRefill>) {
        yield baseEffectHandler({
            service: AccountService.manageAutoRefill().put,
            data: action.payload,
            *onResponse(data) {
                const { onSuccess, onFailure } = action.payload;
                if (data.messageStatus === false) {
                    yield put(accountUpdateAutoRefill.failure({ messageText: data.messageText }));
                    if (onFailure) onFailure(data);
                    return;
                }
                if (onSuccess) onSuccess();
                yield put(accountUpdateAutoRefill.success(data));
            },
            *onError(error: any) {
                yield put(accountUpdateAutoRefill.failure(error));
                yield put(accountUpdateAutoRefill.failure(error));
                if (error.response?.data?.SystemMessage) {
                    TrackError('account.sagas.ts', 'accountUpdateAutoRefill', error.response.data.SystemMessage);
                } else {
                    TrackError(
                        'account.sagas.ts',
                        'accountUpdateAutoRefill',
                        error?.message ? error.message : 'An unknown error occurrred.'
                    );
                }
                const { onFailure } = action.payload;
                if (onFailure) onFailure(error);
            }
        });
    });

    yield takeLatest(
        accountUninsuredRegister.TRIGGER,
        function* (
            action: PayloadAction<{
                formData: AssitsOthersFormValues;
                onSuccess?: () => void;
                onFailure?: (error: any) => void;
            }>
        ) {
            const { formData } = action.payload;

            const responseErrorPayload: AccountErrorPayload = yield select(
                accountRegistrationUninsuredRegisterErrorPayloadSelector
            );

            const accountValues = formatAccountFinalizeUninsuredData(formData, responseErrorPayload, 'BRD01');

            yield baseEffectHandler({
                service: AccountService.accountFinalizeNotInsuranced().post,
                data: accountValues,
                *onResponse(data) {
                    const { onSuccess, onFailure } = action.payload;
                    if (data.messageStatus === false) {
                        yield put(accountUninsuredRegister.failure(data));
                        if (onFailure) onFailure(data);
                        return;
                    }
                    if (onSuccess) onSuccess();
                },
                *onError(error: AccountErrorPayload) {
                    const { onFailure } = action.payload;
                    if (error.messageStatus === false) {
                        TrackError('account.sagas.ts', 'accountUninsuredRegister', error?.messageErrorText);
                    } else {
                        TrackError(
                            'account.sagas.ts',
                            'accountUninsuredRegister',
                            error?.messageErrorText ? error.messageErrorText : 'An unknown error occurrred.'
                        );
                    }
                    if (onFailure) onFailure(error);
                    yield put(accountUninsuredRegister.failure(error));
                }
            });
        }
    );

    yield takeLatest(
        accountResendVerificationEmailRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                email: string;
                onSuccess?: () => void;
                onFailure?: () => void;
            }>
        ) {
            const { onSuccess, onFailure, email } = action.payload;
            yield baseEffectHandler({
                service: AccountService.resendVerificationEmail().post,
                data: email,
                *onResponse(data) {
                    if (onSuccess) onSuccess();
                    yield put(accountResendVerificationEmailRoutine.success());
                },
                *onError(error: any) {
                    const errorMessage = error.response?.response?.data?.Message;
                    if (resendVerificationLinkErrors.includes(errorMessage)) {
                        yield put(accountResendVerificationEmailRoutine.failure(errorMessage));
                        if (onFailure) onFailure();
                    }
                }
            });
        }
    );

    // @MARK: ValidatePhoneNumber
    yield takeLatest(
        accountValidatePhoneNumberRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                phone: string[];
                onSuccess?: (data: ValidatePhoneNumberResponse) => void;
                onFailure?: () => void;
            }>
        ) {
            const { onSuccess, onFailure, phone } = action.payload;

            yield baseEffectHandler({
                service: AccountService.validatePhoneNumber().post,
                data: phone,
                *onResponse(data: ValidatePhoneNumberResponse) {
                    if (data.phoneNumberVerifications[0]?.messageStatus === false) {
                        yield put(
                            accountValidatePhoneNumberRoutine.failure(data.phoneNumberVerifications[0].messageText)
                        );
                        if (onFailure) {
                            onFailure();
                        }
                    } else {
                        yield put(accountValidatePhoneNumberRoutine.success(data));
                        if (onSuccess) {
                            onSuccess(data);
                        }
                    }
                },
                *onError(error: any) {
                    const errorMessage = error.response?.response?.data?.Message || 'Unknown error occurred';
                    console.log('Error occurred: ', errorMessage); // Adicionando log para verificação
                    yield put(accountValidatePhoneNumberRoutine.failure(errorMessage));
                    if (onFailure) {
                        onFailure();
                    }
                }
            });
        }
    );
}
