import classNames from 'classnames';
import { useField } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';

import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import InputError from 'ui-kit/input-error/input-error';

import { SelectOptionValue } from 'types/select';

import { TrackInputFocus } from 'util/google_optimize/optimize_helper';

import { SelectOptionProps, SelectProps } from './select.props';
import './select.style.scss';

const Select = <T extends SelectOptionValue>(props: SelectProps<T>): ReactElement => {
    const inputEl = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [, meta] = useField(props.name);
    const formikValue = meta.value;
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState<T | undefined>(props.value);

    const {
        className,
        errors,
        placeholder,
        onChange,
        options = [],
        touched,
        disabled = false,
        showSelectPlaceholder,
        showTitle = false,
        onFocus
    } = props;

    const optionMap = {};
    options.forEach((opt) => {
        optionMap[opt.value] = opt;
    });
    const selectedOption = optionMap[props.value];

    const controlTitle = selectedOption ? selectedOption.label : undefined;

    const classes = classNames(
        'select',
        className,
        { 'is-open': isMenuOpen },
        { 'has-value': props.formikControlled ? formikValue || (showSelectPlaceholder && !disabled) : selectedValue },
        { 'has-errors': errors && touched }
    );

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        TrackInputFocus(event.target, placeholder);
        onFocus && onFocus(event.target.name);
    };

    const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
        const currentTarget = e.currentTarget;

        // Check the newly focused element after all the elements have been painted
        requestAnimationFrame(() => {
            // Check if the new activeElement is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
                // You can invoke a callback or add custom logic here
                setIsMenuOpen(false);
            }
        });
    };

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const target = event.target;
            if (target) {
                const selectedOptions = target.selectedOptions;
                if (selectedOptions && selectedOptions[0]) {
                    // subtract one, since the select starts with a blank option
                    const selectedOption = options[selectedOptions[0].index - 1];
                    if (selectedOption) {
                        if (!props.formikControlled) {
                            setSelectedValue(selectedOption.value);
                        }
                        if (onChange) onChange({ key: selectedOption.key, option: selectedOption });
                    }
                }
            }
        },
        [options, props.formikControlled, onChange]
    );

    useEffect(() => {
        setSelectedValue(props.value);
    }, [props.value]);

    return (
        <div className={classes} onBlur={handleBlur} onFocus={handleOnFocus}>
            <div className="select-display-text">
                <label htmlFor={props.name} className="select-placeholder">
                    {placeholder}
                </label>
            </div>
            <Form.Control
                ref={inputEl}
                name={props.name}
                id={props.name}
                as="select"
                value={
                    props.formikControlled
                        ? showSelectPlaceholder && !disabled && !formikValue
                            ? ''
                            : formikValue
                        : selectedValue
                }
                title={showTitle && controlTitle ? controlTitle : undefined}
                onChange={handleChange}
                className="select-display"
                disabled={disabled}
            >
                <option disabled={showSelectPlaceholder && !disabled} value="">
                    {showSelectPlaceholder && !disabled ? t('components.select.placeholderOption') : ''}
                </option>
                {options &&
                    options.map((opt: SelectOptionProps<T>) => (
                        <option key={`select-${opt.value}`} value={opt.value}>
                            {opt.label}
                        </option>
                    ))}
            </Form.Control>
            <ChevronIcon direction="down" />
            <InputError
                inputElement={inputEl}
                touched={touched}
                label={placeholder}
                errors={errors}
                className="select-errors"
            />
        </div>
    );
};

export default Select;
