import classNames from 'classnames';
import React, { ReactElement } from 'react';

import AlertIcon from 'ui-kit/icons/alert/alert-icon';
import CheckCircle from 'ui-kit/icons/check-circle/check-circle';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

import { ThemeColors } from 'types/ui-kit';
import UIStatuses from 'types/ui-statuses';

import './toast-box.style.scss';

interface InfoBoxProps {
    variant?: UIStatuses | 'descriptive';
    icon?: UIStatuses;
    children: React.ReactNode | React.ReactNode[];
    iconColor?: ThemeColors;
    backgroundColor?: ThemeColors;
}
// This component will be used for creating the styles for the Info/Toast component variants
// https://www.figma.com/file/DCSivY7w2M4MI6nnmgZexX/Design-System---Source?type=design&node-id=606-21912&mode=dev
const ToastBox = ({ children, variant, icon, iconColor, backgroundColor }: InfoBoxProps): ReactElement => {
    const ToastClasses = classNames(
        'toast-box',
        `toast-box__${variant} ${backgroundColor ? `toast-box__background--${backgroundColor}` : ''}`
    );

    const selectIcon = (i: UIStatuses) => {
        switch (i) {
            case 'info':
                return <CircleInfo className="toastbox-icon info" />;

            case 'success':
                return <CheckCircle variant="success" className="toastbox-icon success" />;

            case 'danger':
                return <AlertIcon className="toastbox-icon danger" variant="alert" />;

            case 'warning':
                return <AlertIcon variant="warning" className="toastbox-icon warning" />;

            case 'error':
                return <AlertIcon className="toastbox-icon danger" variant="alert" />;

            // New default color - design system v2
            case 'error-v2':
                return <AlertIcon variant="error-v2" className="toastbox-icon error--v2" />;

            case 'default':
                return <CircleInfo className="toastbox-icon default" />;

            // add more cases as needed;
            default:
                return null;
        }
    };
    return (
        <div className={ToastClasses}>
            <div className="toast-box__text">
                <span className={`text-box--${iconColor ? iconColor : ''}`}>{icon && selectIcon(icon)}</span>
                {children}
            </div>
        </div>
    );
};
export default ToastBox;
