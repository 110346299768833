import classNames from 'classnames';

import { PlusIconProps } from './plus-icon.props';
import './plus-icon.style.scss';

export type PlusIconTypes = 'primary' | 'secondary' | 'third' | 'fourth';

const PlusIcon = ({ className, style, type = 'primary' }: PlusIconProps) => {
    const classes = classNames(className, {
        'plus-icon': type === 'primary',
        'secondary-plus-icon': type === 'secondary',
        'third-plus-icon': type === 'third'
    });

    const getIconByType = (type: PlusIconTypes) => {
        switch (type) {
            case 'primary':
                return (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        viewBox="0 0 38 38"
                        height="38px"
                        width="38px"
                    >
                        <g>
                            <g fill="#039fd3">
                                <circle cx="19" cy="19" r="19"></circle>
                            </g>
                            <path
                                d="M25.4772727,17.7045455 L20.2954545,17.7045455 L20.2954545,12.5227273 C20.2954545,11.8072675 19.7154598,11.2272727 19,11.2272727 C18.2845402,11.2272727 17.7045455,11.8072675 17.7045455,12.5227273 L17.7045455,17.7045455 L12.5227273,17.7045455 C11.8072675,17.7045455 11.2272727,18.2845402 11.2272727,19 C11.2272727,19.7154598 11.8072675,20.2954545 12.5227273,20.2954545 L17.7045455,20.2954545 L17.7045455,25.4772727 C17.7045455,26.1927325 18.2845402,26.7727273 19,26.7727273 C19.7154598,26.7727273 20.2954545,26.1927325 20.2954545,25.4772727 L20.2954545,20.2954545 L25.4772727,20.2954545 C26.1927325,20.2954545 26.7727273,19.7154598 26.7727273,19 C26.7727273,18.2845402 26.1927325,17.7045455 25.4772727,17.7045455 Z"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                            ></path>
                        </g>
                    </svg>
                );

            case 'secondary':
                return (
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="3.61523" width="1.80796" height="10" rx="0.903978" fill="#03A4DC" />
                        <rect
                            x="9.03906"
                            y="4"
                            width="2"
                            height="9.03978"
                            rx="1"
                            transform="rotate(90 9.03906 4)"
                            fill="#03A4DC"
                        />
                    </svg>
                );

            case 'third':
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} viewBox="0 0 24 24" fill="#03A4DC">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.5199 7C11.0206 7 10.6159 7.40472 10.6159 7.90398V11H8C7.44772 11 7 11.4477 7 12C7 12.5523 7.44771 13 8 13H10.6159V16.096C10.6159 16.5953 11.0206 17 11.5199 17C12.0191 17 12.4238 16.5953 12.4238 16.096V13H15.0398C15.5921 13 16.0398 12.5523 16.0398 12C16.0398 11.4477 15.5921 11 15.0398 11H12.4238V7.90398C12.4238 7.40472 12.0191 7 11.5199 7Z"
                            fill="#1A497F"
                        />
                    </svg>
                );

            case 'fourth':
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Add - Circle" clipPath="url(#clip0_39984_4015)">
                            <path
                                id="Vector"
                                d="M12 6.92308V17.0769M6.92308 12H17.0769M12 23C18.0752 23 23 18.0752 23 12C23 5.92487 18.0752 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0752 5.92487 23 12 23Z"
                                stroke="#03A4DC"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_39984_4015">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                );

            default:
                return <></>;
        }
    };

    return (
        <>
            <div className={classes} style={style}>
                {getIconByType(type)}
            </div>
        </>
    );
};

export default PlusIcon;
