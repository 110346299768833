import { FluidObject } from 'gatsby-image';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import BackgroundImage from 'ui-kit/background-image/background-image';

import ListIcon from 'components/icon-list/ListIcon/ListIcon';

import './IconList.style.scss';

interface Icons {
    body?: string;
    bodyClassName?: string;
    title?: string;
    icon: any;
    alt?: string;
}

export interface IconListTab {
    tabName: string;
    icons: Icons[];
}
export interface IconListProps {
    eyebrowText?: string;
    title?: string;
    subtitle?: string;
    image?: FluidObject;
    sectionIndex: number;
    iconsTabs: IconListTab[];
}

export default function IconList({ eyebrowText, title, subtitle, image, iconsTabs, sectionIndex }: IconListProps) {
    const [activeTab, setActiveTab] = useState<number>(0);

    return (
        <div className={'icon-list'}>
            {image && <BackgroundImage image={image} className="header-background" />}
            <Container fluid="xl">
                <Row className="justify-content-lg-center">
                    <Col xs={12} md={12} lg={8}>
                        <div className="icon-list__container">
                            {/* Printing page headers */}
                            {eyebrowText && <div className={'h6 icon-list__header__eyebrowText'}>{eyebrowText}</div>}
                            {title && (
                                <>
                                    {sectionIndex === 0 && <h1 className={'h2 icon-list__header__title'}>{title}</h1>}
                                    {sectionIndex > 0 && <h2 className={'icon-list__header__title'}>{title}</h2>}
                                </>
                            )}
                            {subtitle && (
                                <>
                                    <div className={'spacer'}></div>
                                    <div className={'h4 icon-list__header__subtitle col-12 my-0'}>{subtitle}</div>
                                </>
                            )}

                            {/* Printing page tabs */}
                            {iconsTabs.length > 1 && (
                                <div className="tabbed-options__tabs--tabs-container">
                                    {/* TODO: Unify tab components in a single component with variants */}
                                    <ul className="tabbed-options__tabs" role="tablist">
                                        {iconsTabs.map((tab, index) => (
                                            <li
                                                key={`icons-list-tabs-${tab.tabName}-${index}--tab`}
                                                className={
                                                    'tabbed-options__tabs--tab ' +
                                                    (activeTab === index ? 'tabbed-options__tabs--tab-active' : '')
                                                }
                                                aria-hidden="true"
                                                role="tab"
                                                onClick={() => setActiveTab(index)}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter' || event.key === 'Space') {
                                                        setActiveTab(index);
                                                    }
                                                }}
                                            >
                                                {tab.tabName}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {/* Print icons lists */}
                            {iconsTabs.map((tab, index) => (
                                <Row
                                    key={`icons-list-tabs-${tab.tabName}-${index}--content`}
                                    className={`icon-list__list-icons  w-100 ${activeTab === index ? '' : 'd-none'}  ${
                                        iconsTabs.length > 1 ? 'tabbed-options__tabs--container' : 'my-5'
                                    } ${tab?.icons?.length >= 5 ? 'icon-list__custom-justify' : ''}`}
                                >
                                    <>
                                        {tab.icons.map((item) => (
                                            <Col
                                                xs={6}
                                                md={tab.icons.length >= 5 ? 2 : 3}
                                                className="icon-list__icon-container"
                                                key={`icon-list-icon-${index}`}
                                            >
                                                <ListIcon
                                                    key={`list-icon-${index}`}
                                                    subtitle={item.title || ''}
                                                    bodyText={item.body || ''}
                                                    icon={<img src={item.icon.publicURL} alt={item.icon.alt} />}
                                                    bodyClassName={item.bodyClassName || ''}
                                                />
                                            </Col>
                                        ))}
                                    </>
                                </Row>
                            ))}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
