import AWS from 'aws-sdk/global';
import axios from 'axios';
import { CONTACT_US_FILE_URL, GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import { RegistrationFormValues } from 'components/registration-form/registration-form.component';

import { accountFinalizeNotInsuranced } from 'state/membership-registration/membership-registration.helpers';

import { monthOptions } from 'const/options';

import { ForgotUsernameRequestPayload } from 'types/forgot-username';
import { Allergies, MedicalConditions } from 'types/health-profile';
import { Login } from 'types/login';
import {
    OrderGetInvoiceRequestPayload,
    OrderGetInvoiceResponsePayload,
    OrderHistoryRequestPayload,
    OrderHistoryResponsePayload,
    OrderLinesRequestPayload,
    OrderLinesResponsePayload
} from 'types/order';
import { PaymentHistoryRequestPayload, PaymentHistoryResponsePayload } from 'types/payment-history';
import { ProfileFormValues, ProfileUpdateAutoRefill } from 'types/profile';
import { BaseService } from 'types/service';
import {
    SmsConfirmationCancelResponse,
    SmsConfirmationSendRequest,
    SmsConfirmationSendResponse,
    SmsGetConfirmationStatusResponse,
    SMSPendingRequestResponse
} from 'types/sms';

import axiosClient, { setDefaultHeader, unsetDefaultHeader } from 'util/axiosClient';
import { UnknownFunction } from 'util/function';
import { threatHealthConditionsPayload } from 'util/medical-conditions';

const LARGE_FILE_SIZE = 4000000;

export interface AccountSecurityPayload {
    ePostPatientNum?: string;
    email?: string;
    longToken?: string;
    newPassword?: string;
    passwordAnswer1?: string;
    passwordAnswer2?: string;
    passwordAnswer3?: string;
    passwordQuestion1?: string;
    passwordQuestion2?: string;
    passwordQuestion3?: string;
    shortToken?: string;
    uid?: string;
    username?: string;
}

export interface ChangePasswordPayload {
    email: string;
}

export interface AccountSecurityAuth0Payload extends Partial<AccountSecurityPayload> {
    Success: boolean;
    Message: string;
}

export interface ProfileObjectPhonePayload {
    epostPatientNum: string;
    phoneAreaCode: string;
    phoneExtension: string;
    phoneNumber: string;
    phoneSeqNum: string;
    phoneTypeDesc: string;
    phoneTypeNum: string;
    primaryPhone: boolean;
    uiPhone: string;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}

export interface ProfileObjectAddressPayload {
    active: boolean;
    address1: string;
    address2: string;
    address3: string;
    addressNote: string;
    addressSeqNum: string;
    addressTypeDesc: string;
    addressTypeNum: string;
    city: string;
    country: string;
    defaultBill: boolean;
    defaultAddress?: boolean;
    defaultPhoneNumber: string;
    defaultPhoneSeq: string;
    defaultShip: boolean;
    endDate: string;
    epostPatientNum: string;
    startDate: string;
    state: string;
    stateNum: string | null;
    zipcode: string;
    zipcodeFour: string;
    currentShipping: boolean;
    messageErrorText: string | null;
    messageStatus: boolean | null;
    messageText: string | null;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
}

export interface ProfileObjectPayload {
    autoContactDesc: string;
    autoContactTypeId: string;
    autoRefill: boolean;
    epostMemberId: string;
    epostPatientNum: string;
    externalLink: string;
    familyId: string;
    genderCode: string;
    generalStatusCode: string;
    genericsAvailable: boolean;
    dependents: ProfileObjectPayload[];
    languageDesc: string;
    languageId: string;
    patientBalance: string;
    patientCreditLimit: string;
    patientDob: string;
    patientEmailAddress: string;
    patientFirstName: string;
    patientId: string;
    patientIdCode: string;
    patientLastName: string;
    patientMaidenName: string;
    patientMiddleName: string;
    preferredPaymentMethodDesc: string;
    preferredPaymentMethodId: string;
    preferredShipMethodDesc: string;
    preferredShipMethodId: string;
    safetyCap: boolean;
    titleDesc: string;
    titleId: string;
    cardholderID: string;
    insuranceName: string;
    payorName: string;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
    addresses: ProfileObjectAddressPayload[];
    phones: ProfileObjectPhonePayload[];
    isCaregiver: boolean;
    hasMembership: boolean;
    membershipID: string;
    patientPlanAutoRefillFlag: boolean;
    planAlias?: string;
    hasEPostProfile: boolean;
    hasBirdiProfile: boolean;
    onSuccess?: () => void;
    onFailure?: (err?: ProfileRequestErrorPayload) => void;
}
export interface ProfileRequestErrorPayload {
    error?: string;
    description?: string;
    messageErrorText?: string;
}

export interface PlansObjectPayload {
    activationDate: string;
    altCardholderId: string;
    cardholderId: string;
    coverageStatusDesc: string;
    coverageStatusId: string;
    coverageType: string;
    epostPatientNum: string;
    epostPlanNum: string;
    groupId: string;
    groupName: string;
    payorName: string;
    payorType: string;
    personCode: string;
    planAlias: string;
    planExpired: boolean;
    planId: string;
    planName: string;
    planType: string;
    relationshipDesc: string;
    relationshipNum: number;
    terminationDate: string;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
    onSuccess?: () => void;
    onFailure?: () => void;
}
export interface WebProfilePayload {
    ePostPatientNum: string;
    ePostFamilyId: string;
    ResetPassword: boolean;
    PasswordQuestion1: string;
    PasswordAnswer1: string;
    PasswordQuestion2: string;
    PasswordAnswer2: string;
    PasswordQuestion3: string;
    PasswordAnswer3: string;
    TimeStamp: Date;
    Email: string;
    EmailConfirmed: boolean;
    PasswordHash: string;
    SecurityStamp: string;
    PhoneNumber: string;
    PhoneNumberConfirmed: boolean;
    TwoFactorEnabled: boolean;
    LockoutEndDateUtc: Date;
    LockoutEnabled: boolean;
    AccessFailedCount: 0;
    Roles: Array<unknown>;
    Claims: Array<unknown>;
    Logins: Array<unknown>;
    Id: string;
    UserName: string;
}

export interface APILoginPayload {
    '.expires': string;
    '.issued': string;
    access_token: string;
    expires_in: number;
    sys_user_num: string;
    token_type: string;
    userName: string;
    emailUpdate?: string;
}

export interface ProfileAddPaymentObjectPayload {
    readonly cardNumber: string;
    readonly nameOnCard: string;
    readonly expMonth: string;
    readonly expYear: string;
    readonly cvcNumber: string;
    readonly fsaCard: boolean;
    readonly zipCode: string;
}

export interface APIHealthConditionsPayload {
    Ampicillin: boolean;
    None: boolean;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}
export interface APIMedicalConditionsPayload extends APIHealthConditionsPayload, MedicalConditions {}

export interface APIAllergiesPayload extends APIHealthConditionsPayload, Allergies {}

export interface CreditCardPayload {
    FSACard: boolean;
    cardActive: boolean;
    cardFirstName: string;
    cardLastName: string;
    cardMiddleName: string;
    defaultCard: boolean;
    epostPatientNum: string;
    forceApproval: boolean;
    cardMonthNum: string;
    cardMonthDesc: string;
    cardNumber: string;
    cardSeqNum: string;
    secureCardNumber: string;
    cardTypeNum: string;
    cardType: string;
    cardYear: string;
    pciToken: string;
    securityCode: string;
    cardExpiration: string;
    cardName: string;
    spendingLimit: string;
    cardIsExpired: boolean;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
    defaultMembershipPayment?: boolean;
    conversionLink?: string;
    companyCard?: string;
    lastUsed?: string;
}

export interface RepresentativesPayload {
    cityName: string;
    cityStateZipNum: string;
    patientAddress: string;
    patientAddress2: string;
    patientAddress3: string;
    patientAreaCode: string;
    patientCareGiverName: string;
    patientCareGiverSeq: string;
    patientEmail: string;
    patientNote: string;
    patientPhoneExt: string;
    patientPhoneNumber: string;
    stateCode: string;
    zipCode: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
    loggedIn?: boolean;
}

export interface AllCreditCardsPayload {
    allPayments: Array<CreditCardPayload>;
}

export interface PaymetricDetailsPayload {
    MerchantId: string;
    ServiceUrl: string;
    AccessToken: string;
    ScriptUrl: string;
}

export interface TokenizePaymentCardPayload {
    onSuccess: UnknownFunction;
    onFailure: UnknownFunction;
}

export interface ContactUsPayload {
    HeaderID: number;
    Subject: string;
    CreateDate: Date;
    Action: string;
    MessageID: 0;
    Body: string;
    MessageDate: Date;
    PersonTypeDesc: string;
    PersonFistName: string;
    PersonLastName: string;
    PersonNumber: number;
    WebUserID: string;
    EmailAddress: string;
    MessageType: number;
    FirstName: string;
    LastName: string;
    Phone: string;
    ContactMethod: string;
    onSuccess: UnknownFunction;
    onFailure: UnknownFunction;
    loggedIn?: boolean;
}

export interface NotificationsPayload {
    EPostPatientNum: string;
    NewScriptID: number;
    NewScriptLastNotify: string;
    NewScriptEmail: boolean;
    NewScriptEmailAddress: string;
    NewScriptPhone: boolean;
    NewScriptPhoneNumber: string;
    NewScriptText: boolean;
    NewScriptTextNumber: string;
    RefillReminderID: number;
    RefillReminderLastNotify: string;
    RefillReminderEmail: boolean;
    RefillReminderEmailAddress: string;
    RefillReminderPhone: boolean;
    RefillReminderPhoneNumber: string;
    RefillReminderText: boolean;
    RefillReminderTextNumber: string;
    OrderShippedID: number;
    OrderShippedLastNotify: string;
    OrderShippedEmail: boolean;
    OrderShippedEmailAddress: string;
    OrderShippedPhone: boolean;
    OrderShippedPhoneNumber: string;
    OrderShippedText: boolean;
    OrderShippedTextNumber: string;
    ConsentAutoCalls: boolean;
    MarketingEmail: boolean;
    MarketingEmailAddress: string;
    MarketingID: number;
    MarketingLastNotify: string;
    MarketingPhone: boolean;
    MarketingPhoneNumber: string;
    MarketingText: boolean;
    MarketingTextNumber: string;
    IsPCM: boolean;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
}

export interface AllMessagesPayload {
    filters: string[];
    askThePharm?: AskThePharmMessagePayload[];
    contactUs?: ContactUsMessagePayload[];
    composeMessage: ComposeMessagePayload;
    replyMessage: ComposeMessagePayload;
    messageThread: MessageThread;
}

export interface ComposeMessagePayload {
    contactUs?: ContactUsComposeMessagePayload;
    askThePharm?: AskThePharmComposeMessagePayload;
}

export interface AskThePharmMessagePayload {
    HeaderID: number;
    Subject: string;
    Date: Date;
    Action: string;
    PharmReply: boolean;
    LastReponseBy: string;
}

export interface MessageThread {
    replies?: object[];
    subject?: string;
    messageSource?: string;
    threadStatus?: string;
}

export interface AskThePharmComposeMessagePayload {
    HeaderID: number;
    Subject: string;
    CreateDate: Date;
    Action: string;
    MessageID: number;
    Body: string;
    MessageDate: Date;
    PersonTypeDesc: string;
    PersonFistName: string;
    PersonLastName: string;
    PersonNumber: number;
    AccountID: string;
    WebUserID: string;
    PatientDOB: Date;
    Email: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
}

export interface ContactUsMessagePayload {
    HeaderID: number;
    Subject: string;
    Date: Date;
    Action: string;
    MessageType: number;
    UIDate: string;
    Author: string;
}

export interface ContactUsComposeMessagePayload {
    HeaderID: number;
    Subject: string;
    CreateDate: Date;
    Action: string;
    MessageID: number;
    Body: string;
    MessageDate: Date;
    PersonTypeDesc: string;
    PersonFistName: string;
    PersonLastName: string;
    PersonNumber: number;
    WebUserID: string;
    EmailAddress: string;
    MessageType: number;
    FirstName: string;
    LastName: string;
    Phone: string;
    ContactMethod: string;
    onSuccess?: UnknownFunction;
    onFailure?: UnknownFunction;
}

export interface PatientRegistrationPayload {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    BirthDate: string;
    GenderCode: string;
    epostPatientNum: number;
    FamilyId: number;
    AccountId: number;
    PatientStatusCode: string;
    PatientStatus: string;
    CardNumber: string;
    CoverageTypeId: number;
    CoverageType: string;
    RelationshipNumber: number;
    Relationship: string;
    Payor: string;
    PayorNum: number;
    PlanAlias: string;
    GroupId: string;
    GroupName: string;
    EmailAddress: string;
    PhoneSeqNum: number;
    PhoneTypeNum: number;
    PhoneType: string;
    AreaCode: string;
    PhoneNumber: string;
    Ext: string;
    AddressSeqNumb: number;
    AddressTypeNum: number;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    StateCode: string;
    StateNumber: number;
    ZipCode: string;
    Zip4: number;
    Country: string;
    CountryNumber: number;
    RecordLocation: string;
    HasUserProfile: number;
    UserName: string;
    WebId: string;
    IsMID: number;
    CoverageStartDate: string;
    CoverageEndDate: string;
    ReadHIPAA: boolean;
    AcceptedHIPAA: boolean;
    CacheId: string;
    RegCode: number;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
}

export interface PatientRegistrationErrorPayload {
    InError: boolean;
    ErrorMessage: string;
    SystemMessage: string;
    LogID: number;
    Location: string;
    ePostPatientNum: string;
}

export interface AccountSetupPayload {
    Id: string;
    Email: string;
    PhoneNumber: string;
    UserName: string;
    Password: string;
    PasswordQuestion1: string;
    PasswordAnswer1: string;
    PasswordQuestion2: string;
    PasswordAnswer2: string;
    PasswordQuestion3: string;
    PasswordAnswer3: string;
    CacheId: string;
    SecurityQuestions: object;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
    Domain: string;
    payor: string;
}

export interface AcknowledgementPayload {
    TotalCount: number | undefined;
    PageNo: number | undefined;
    PageSize: number | undefined;
    Acknowledgements: [
        {
            EPostPatientNum: string;
            Version: number | undefined;
            Type: string;
            AddedDate: string;
            Source: string;
            SourceIP: string;
        }
    ];
}

export interface HipaaStatusPayload {
    HIPAAA: boolean | undefined;
    messageErrorText: string;
    messageStatus: boolean;
}

export interface OptOutPayload {
    epostPatientNum: string;
    communicationType: string;
    communicationValue: string;
    notificationType: string;
}

export interface FamilyMemberPayload {
    key: string;
    label: string;
    value: string | string[];
}

export interface PhoneNumberVerification {
    contactAllowedSms: boolean;
    contactAllowedVoice: boolean;
    unitedStatesTerritory: boolean;
    phoneNumber: string;
    location: string;
    messageStatus: boolean;
    messageText: string;
}
export interface ValidatePhoneNumberResponse {
    phoneNumberVerifications: PhoneNumberVerification[];
    messageStatus: boolean;
    messageText: string;
}

/**
 * Anything related to Authentication in the system.
 */
export default class AccountService {
    static login() {
        return {
            post: (payload?: Login): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/Token?version=2`, payload, {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    })
                    .then((response) => response.data)
                    .catch((err) => err.response.data);
            }
        };
    }
    static register(): BaseService<RegistrationFormValues> {
        return {
            get: (payload?: RegistrationFormValues): Promise<PatientRegistrationPayload> => {
                const monthNumber = monthOptions.findIndex((month) => month.value === payload?.dobMonth);
                const dobDateTime = new Date(Number(payload?.dobYear), Number(monthNumber), Number(payload?.dobDay));
                const dobString =
                    ('0' + (dobDateTime.getMonth() + 1)).slice(-2) +
                    '-' +
                    ('0' + dobDateTime.getDate()).slice(-2) +
                    '-' +
                    dobDateTime.getFullYear();

                const parameters =
                    payload?.insuranceChoice === 'insurance'
                        ? `${payload?.firstName}/${payload?.lastName}/${dobString}/${payload?.memberId}`
                        : `${payload?.firstName}/${payload?.lastName}/${dobString}/`;

                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Registration/Validate/${parameters}`)
                    .then((response) => response.data);
            }
        };
    }
    static accountSetup(): BaseService<ProfileFormValues> {
        return {
            post: (payload: ProfileFormValues) => {
                return axiosClient.post(`${GATSBY_API_BASE_URL}/api/Registration/AccountSetup`, payload);
            }
        };
    }
    static accountFinalize(): BaseService<ProfileFormValues> {
        return {
            post: (payload: ProfileFormValues) => {
                return axiosClient.post(`${GATSBY_API_BASE_URL}/api/Registration/Finalize`, payload);
            }
        };
    }
    static acceptTos(): BaseService<ProfileFormValues> {
        return {
            post: (payload: ProfileFormValues) => {
                return axiosClient.post(`${GATSBY_API_BASE_URL}/api/Registration/AccountSetup`, payload);
            }
        };
    }
    static fetchProfile(): BaseService<ProfileObjectPayload> {
        return {
            get: (): Promise<ProfileObjectPayload> => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}/api/Patient/Profile`).then((response) => response.data);
            },
            post: (payload: ProfileObjectPayload) => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Patient/UpdatePatient`, payload)
                    .then((response) => response.data)
                    .catch((error) => error.response);
            }
        };
    }

    static fetchPlans(familyMembers: string[]): BaseService<RegistrationFormValues> {
        return {
            get: (): Promise<any> => {
                const familyPlansRequests = familyMembers.map((member) =>
                    axiosClient
                        .get(`${GATSBY_API_BASE_URL}/api/V2/Patient/Plans?epostPatientNum=${member}`)
                        .then((response) => {
                            return response.data;
                        })
                );

                return axios
                    .all(familyPlansRequests)
                    .then(
                        axios.spread((...responses) => {
                            return responses.flat();
                        })
                    )
                    .catch((errors) => {
                        console.error('errors => ', errors);
                    });
            }
        };
    }

    static orderHistory(): BaseService<OrderHistoryResponsePayload> {
        return {
            get: (payload: OrderHistoryRequestPayload): Promise<any> => {
                return axiosClient
                    .get(
                        `${GATSBY_API_BASE_URL}/api/Order/PatientOrders/${payload.from}/${payload.to}/${payload.page}/${payload.pageSize}/CANCELLED,CLOSED`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static paymentHistory(): BaseService<PaymentHistoryResponsePayload> {
        return {
            get: (payload: PaymentHistoryRequestPayload): Promise<any> => {
                return axiosClient
                    .get(
                        `${GATSBY_API_BASE_URL}/api/Ar/PaymentHistory/${payload.page}/${payload.pageSize}/${payload.includeAging}`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static orderGetInvoice(): BaseService<OrderGetInvoiceResponsePayload> {
        return {
            get: (payload: OrderGetInvoiceRequestPayload): Promise<any> => {
                const formatDate = (dateString: string) => {
                    const d = new Date(dateString);
                    const mm = d.getMonth() + 1;
                    const dd = d.getDate();
                    const yy = d.getFullYear();
                    return mm + '-' + dd + '-' + yy;
                };
                return axiosClient
                    .get(
                        `${GATSBY_API_BASE_URL}/api/Order/GetInvoice/${formatDate(payload.from)}/${formatDate(
                            payload.to
                        )}`,
                        { responseType: 'blob' }
                    )
                    .then((response) => response.data);
            }
        };
    }
    static orderLines(): BaseService<OrderLinesResponsePayload> {
        return {
            get: (payload: OrderLinesRequestPayload): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Order/OrderLines/${payload.epostOrderNum}`)
                    .then((response) => response.data);
            }
        };
    }
    static medicalConditions() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/MedicalConditions`)
                    .then((response) => response.data);
            },
            post: (payload: MedicalConditions): Promise<any> => {
                const { TemporaryOther, ...updatedPayload } = payload;
                const threatedPayload = threatHealthConditionsPayload(updatedPayload as MedicalConditions);
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/MedicalConditions/Update`, threatedPayload)
                    .then((response) => response.data);
            }
        };
    }
    static allergies() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}/api/Allergies`).then((response) => response.data);
            },
            post: (payload: Allergies): Promise<any> => {
                const { TemporaryOther, ...updatedPayload } = payload;
                const threatedPayload = threatHealthConditionsPayload(updatedPayload as Allergies);
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Allergies/Update`, threatedPayload)
                    .then((response) => response.data);
            }
        };
    }
    static representativesMethods() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/PatientRepresentative`)
                    .then((response) => response.data)
                    .catch(function (error) {
                        console.error(error);
                    });
            },
            post: (payload?: RepresentativesPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/PatientRepresentative`, payload)
                    .then((response) => response.data);
            },
            delete: (payload: any) => {
                return axiosClient
                    .delete(`${GATSBY_API_BASE_URL}/api/PatientRepresentative/${payload.patientCareGiverSeq}`)
                    .then((response) => response.data);
            }
        };
    }

    static paymentMethods() {
        return {
            getAll: (): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/CreditCard/ActiveCards`)
                    .then((response) => response.data);
            },
            getAllMembership: (): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Membership/GetBirdiPaymentMethods`)
                    .then((response) => response.data);
            },
            getAccessID: (): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Payments/PaymetricCredentials`)
                    .then((response) => response.data);
            },
            getAccessIDForMembership: (): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/UnauthorizedPayments/PaymetricCredentials`)
                    .then((response) => response.data);
            },
            getTokenizedCardNumber: (payload?: string): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Payments/GetTokenizedCardNumber?accessToken=${payload}`)
                    .then((response) => response.data);
            },
            getTokenizedMembershipCardNumber: (payload?: string): Promise<any> => {
                return axiosClient
                    .get(
                        `${GATSBY_API_BASE_URL}/api/UnauthorizedPayments/GetTokenizedCardNumber?accessToken=${payload}`
                    )
                    .then((response) => response.data);
            },
            post: (payload?: CreditCardPayload): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/CreditCard/UpdateCard`, payload)
                    .then((response) => response.data);
            },
            postMembership: (payload?: CreditCardPayload): Promise<any> => {
                return axiosClient
                    .put(
                        `${GATSBY_API_BASE_URL}/api/Membership/UpdateBirdiPaymentDefaultCard?epostPatientNum=${payload?.epostPatientNum}`,
                        payload
                    )
                    .then((response) => response.data);
            }
        };
    }
    static fetchWebProfile() {
        return {
            get: (): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Patient/WebProfile`)
                    .then((response) => response.data);
            }
        };
    }
    static fetchSecurityQuestions() {
        return {
            get: (username: string): Promise<any> => {
                return axiosClient
                    .get(
                        `${GATSBY_API_BASE_URL}/api/Patient/GetSecurityInfoByUsername/?username=${encodeURIComponent(
                            username
                        )}`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static submitSecurityQuestionAnswer() {
        return {
            post: (payload?: AccountSecurityPayload) => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Patient/ValidateSecurityInfoAndGeneratePasswordToken`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static changePassword() {
        return {
            update: (payload?: { username: string; currentPassword: string; newPassword: string }): Promise<any> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/Patient/ChangePassword`, { params: payload })
                    .then((response) => response);
            }
        };
    }
    static verifiedPatient() {
        return {
            post: (payload: { status: boolean }): Promise<any> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Patient/VerifiedPatient/${payload.status}`)
                    .then((response) => response.data);
            }
        };
    }
    static contactUs() {
        return {
            get: (payload: boolean): Promise<ContactUsPayload> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}${payload ? '/api/ContactUs' : '/api/ContactUs/AnonymousMessage'}`)
                    .then((response) => response.data);
            },
            post: async (payload: { message: ContactUsPayload; isLoggedIn: boolean }): Promise<ContactUsPayload> => {
                return await axiosClient
                    .post(
                        `${GATSBY_API_BASE_URL}${
                            payload.isLoggedIn ? '/api/ContactUs/SendMessage' : '/api/ContactUs/SendExternalMessage'
                        }`,
                        payload
                    )
                    .then((response) => response.data);
            }
        };
    }
    static resetPasswordWithToken() {
        return {
            post: (payload?: AccountSecurityPayload) => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Patient/ResetPasswordWithToken`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static sendChangePasswordRequest() {
        return {
            post: (payload: ChangePasswordPayload) => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/authorization/change-password`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static forgotUsername() {
        return {
            get: (payload: ForgotUsernameRequestPayload) => {
                return axiosClient
                    .get(
                        `${GATSBY_API_BASE_URL}/api/accounts/usernamebyemail?email=${encodeURIComponent(
                            payload?.email
                        )}`
                    )
                    .then((response) => response.data);
            }
        };
    }
    static notifications() {
        return {
            get: () => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}/api/Notification`).then((response) => response.data);
            },
            post: (payload: NotificationsPayload) => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/Notification`, payload)
                    .then((response) => response.data);
            }
        };
    }

    static messageMultiFileUpload(payload?: any): Promise<any> {
        const patientID = payload.PersonNumber;
        const savedFileNames: string[] = [];
        return payload.AttachedFiles.reduce(function (promise: Promise<any>, file: File) {
            return promise.then(function () {
                // Use "/" delimiter so that just the filename can be shown in the view.
                const fileName = encodeURI(`${payload.CreateDate}/${file.name}`);
                const readFileURL = `${CONTACT_US_FILE_URL}/robin-contactus-file-reader?patientid=${patientID}&filename=${fileName}`;
                savedFileNames.push(readFileURL);
                payload.SupportingDocuments = savedFileNames.join(',');
                const formData = new FormData();
                formData.append('file', file);

                if (file.size > LARGE_FILE_SIZE) {
                    return AccountService.message(undefined)
                        .largeFileUploadUrl({
                            patientId: patientID,
                            fileName: fileName,
                            file: file
                        })
                        .then((response) => {
                            return AccountService.message(undefined).largeFileUpload({
                                uploadUrl: response.data,
                                file: file
                            });
                        });
                } else {
                    return AccountService.message(undefined).fileUpload({
                        patientId: patientID,
                        fileName: fileName,
                        file: file
                    });
                }
            });
        }, Promise.resolve());
    }

    static message(messageType: string | undefined) {
        return {
            getAll: () => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}${'/api/AskThePharm/PatientMessageHeaders'}`)
                    .then((response) => {
                        const askThePharmMessages = response.data;
                        return axiosClient
                            .get(`${GATSBY_API_BASE_URL}${'/api/v2/ContactUs/MessageHeaders'}`)
                            .then((response) => {
                                return { askThePharm: askThePharmMessages, contactUs: response.data };
                            });
                    });
            },
            getCompose: () => {
                return axiosClient.get(`${GATSBY_API_BASE_URL}${'/api/AskThePharm/CreateMessage'}`).then((response) => {
                    const askThePharmCreateMessage = response.data;
                    return axiosClient
                        .get(`${GATSBY_API_BASE_URL}${'/api/v2/ContactUs/CreateMessage'}`)
                        .then((response) => {
                            return { askThePharm: askThePharmCreateMessage, contactUs: response.data };
                        });
                });
            },
            postMessage: (payload: ContactUsComposeMessagePayload | AskThePharmComposeMessagePayload) => {
                return AccountService.messageMultiFileUpload(payload).then(() => {
                    return axiosClient
                        .post(
                            `${GATSBY_API_BASE_URL}${
                                messageType === 'Ask The Pharmacist'
                                    ? '/api/AskThePharm/SaveMessage'
                                    : '/api/v2/ContactUs/SaveMessage'
                            }`,
                            payload
                        )
                        .then((response) => response.data);
                });
            },
            getMessageThread: (payload: any) => {
                return axiosClient
                    .get(
                        `${GATSBY_API_BASE_URL}${
                            messageType === 'Ask The Pharmacist'
                                ? `/api/AskThePharm/GetHeaderMessages/${payload.HeaderID}`
                                : `/api/v2/ContactUs/MessagesByHeaderId/${payload.HeaderID}`
                        }`
                    )
                    .then((response) => response.data);
            },
            getReply: (payload: any) => {
                return axiosClient
                    .get(
                        `${GATSBY_API_BASE_URL}${
                            messageType === 'Ask The Pharmacist'
                                ? `/api/AskThePharm/CreateReply/${payload.HeaderID}/${payload.messageAction}`
                                : `/api/v2/ContactUs/CreateReply/${payload.HeaderID}`
                        }`
                    )
                    .then((response) => response.data);
            },
            postReply: (payload: ContactUsComposeMessagePayload) => {
                return AccountService.messageMultiFileUpload(payload).then(() => {
                    return axiosClient
                        .post(
                            `${GATSBY_API_BASE_URL}${
                                messageType === 'Ask The Pharmacist'
                                    ? '/api/AskThePharm/SaveReply'
                                    : '/api/v2/ContactUs/SaveReply'
                            }`,
                            payload
                        )
                        .then((response) => response.data);
                });
            },
            closeMessage: (payload: any) => {
                if (messageType === 'Ask The Pharmacist') {
                    return axiosClient
                        .post(`${GATSBY_API_BASE_URL}/api/AskThePharm/CloseMessage/${payload.HeaderID}`)
                        .then((response) => response.data);
                } else {
                    return axiosClient
                        .delete(`${GATSBY_API_BASE_URL}/api/v2/ContactUs/CloseMessage?HeaderId=${payload.HeaderID}`)
                        .then((response) => response.data);
                }
            },
            fileList: (payload: any) => {
                return axiosClient
                    .get(`${CONTACT_US_FILE_URL}/robin-contactus-file-list?patientid=${payload.patientId}`)
                    .then((response) => {
                        return response;
                    });
            },
            fileUpload: (payload: any) => {
                return axiosClient
                    .post(
                        `${CONTACT_US_FILE_URL}/robin-contactus-file?patientid=${payload.patientId}&filename=${payload.fileName}`,
                        payload.file
                    )
                    .then((response) => {
                        return response;
                    });
            },
            largeFileUpload: async (payload: any) => {
                return axiosClient
                    .put(payload.uploadUrl, payload.file, {
                        transformRequest: (data, headers) => {
                            if (headers) delete headers.Authorization;
                            return data;
                        },
                        headers: { 'Content-Type': payload.file.type }
                    })
                    .then((response) => {
                        return response;
                    });
            },
            largeFileUploadUrl: async (payload: any) => {
                return axiosClient
                    .get(
                        `${CONTACT_US_FILE_URL}/robin-contactus-presigned?patientid=${payload.patientId}&filename=${payload.fileName}`,
                        { data: payload }
                    )
                    .then((response) => {
                        return response;
                    });
            },
            fileDownloadPath: (payload: any) => {
                // DRX-1076: The stored filename should be a FULL URL, but before this fix, the file may just have the filename.
                const fileURL =
                    payload.fileName.substring(0, CONTACT_US_FILE_URL.length) === CONTACT_US_FILE_URL
                        ? payload.fileName
                        : `${CONTACT_US_FILE_URL}/robin-contactus-file-reader?patientid=${payload.patientId}&filename=${payload.fileName}`;

                return axiosClient.get(fileURL).then((response) => {
                    return response;
                });
            },
            fileDownload: (payload: any) => {
                unsetDefaultHeader('Authorization');
                return axiosClient.get(payload.url, { responseType: 'blob' }).then((response) => {
                    setDefaultHeader('Authorization', `Bearer ${sessionStorage?.getItem('Authorization')}`);
                    return response;
                });
            }
        };
    }

    static acknowledgement() {
        // Acknowledgement GETs
        const getTermsOfServiceAcknowledgement = (payload: AcknowledgementPayload) => {
            return axiosClient.get(`${GATSBY_API_BASE_URL}/api/patient/${payload}/acknowledgement`).then((response) => {
                return response.data;
            });
        };
        const getHipaaAcknowledgement = (payload: any) => {
            return axiosClient.get(`${GATSBY_API_BASE_URL}/api/patient/${payload}/hipaaStatus`).then((response) => {
                return response.data;
            });
        };

        // Acknowledgement POSTs
        const postTermsOfServiceAcknowledgement = (payload: any) => {
            return axiosClient
                .post(
                    `${GATSBY_API_BASE_URL}/api/patient/${payload.patientId}/acknowledgement`,
                    payload.termsOfServiceAcknowledgementObject
                )
                .then((response) => response.data);
        };
        const postHipaaAcknowledgement = (payload: any) => {
            return axiosClient
                .post(`${GATSBY_API_BASE_URL}/api/patient/${payload.patientId}/hipaaAgree/true`)
                .then((response) => response.data);
        };

        return {
            getAll: (payload: AcknowledgementPayload) => {
                return axios
                    .all([getTermsOfServiceAcknowledgement(payload), getHipaaAcknowledgement(payload)])
                    .then(
                        axios.spread((...responses) => {
                            return responses;
                        })
                    )
                    .catch((errors) => {
                        console.error('errors => ', errors);
                    });
            },
            postAll: (payload: any) => {
                return axios
                    .all([postTermsOfServiceAcknowledgement(payload), postHipaaAcknowledgement(payload)])
                    .then(
                        axios.spread((...responses) => {
                            return responses;
                        })
                    )
                    .catch((errors) => {
                        console.error('errors => ', errors);
                    });
            },
            postHipaa: (payload: any) => {
                return axios
                    .all([postHipaaAcknowledgement(payload)])
                    .then(
                        axios.spread((...responses) => {
                            return responses;
                        })
                    )
                    .catch((errors) => {
                        console.error('errors => ', errors);
                    });
            }
        };
    }
    static getAwsCreds(region: string, poolID: string) {
        return {
            get: async (): Promise<any> => {
                const getAwsCredentials = (region: string, poolID: string) => {
                    // Initialize the Amazon Cognito credentials provider.
                    AWS.config.region = region;
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: poolID
                    });

                    const cognitoCredentials = AWS.config.credentials as AWS.CognitoIdentityCredentials;
                    return cognitoCredentials.getPromise();
                };

                const myPromise = new Promise((resolve, reject) => {
                    // If we already have credentials, go ahead and resolve the
                    // promise.
                    const currenTime = new Date();
                    const cognitoCredentials = AWS.config.credentials as AWS.CognitoIdentityCredentials;
                    if (
                        AWS.config.region === region &&
                        AWS.config.credentials &&
                        currenTime < cognitoCredentials.expireTime
                    ) {
                        resolve('Credentials already exist');
                    } else {
                        // Get credentials.
                        const creds = getAwsCredentials(region, poolID);

                        creds.then(() => {
                            resolve('Retrieved credentials');
                        });
                    }
                });

                try {
                    const response = await myPromise;
                    return response;
                } catch (errors) {
                    return console.error('errors =>', errors);
                }
            }
        };
    }
    static optout() {
        return {
            post: (payload: OptOutPayload) => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/patient/OptOut`, payload)
                    .then((response) => response.data);
            }
        };
    }
    // SMS Confirmation API requests
    static sendSmsConfirmation() {
        return {
            post: async (payload: SmsConfirmationSendRequest): Promise<SmsConfirmationSendResponse> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/SmsConfirmation/Send`, payload)
                    .then((response) => response.data);
            }
        };
    }
    static cancelSmsConfirmation() {
        return {
            post: async (): Promise<SmsConfirmationCancelResponse> => {
                return axiosClient
                    .post(`${GATSBY_API_BASE_URL}/api/SmsConfirmation/Cancel`)
                    .then((response) => response.data);
            }
        };
    }
    static pendingSmsRequests() {
        return {
            get: async (): Promise<SMSPendingRequestResponse> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/SmsConfirmation/IsRequestPending`)
                    .then((response) => response.data);
            }
        };
    }
    static getStatusOfSmsConfirmationRequest() {
        return {
            get: async (securityToken: string): Promise<SmsGetConfirmationStatusResponse> => {
                return axiosClient
                    .get(`${GATSBY_API_BASE_URL}/api/SmsConfirmation/Check/${securityToken}`)
                    .then((response) => response.data);
            }
        };
    }
    static getIpAddress() {
        return {
            get: async () => {
                return axios.get('https://api.ipify.org/?format=json').then((response) => response.data);
            }
        };
    }

    static manageAutoRefill() {
        return {
            put: async (payload: ProfileUpdateAutoRefill): Promise<any> => {
                return axiosClient
                    .put(`${GATSBY_API_BASE_URL}/api/Patient/UpdateAutoRefill`, payload)
                    .then((response) => response.data);
            }
        };
    }

    static accountFinalizeNotInsuranced() {
        return {
            post: async (payload: accountFinalizeNotInsuranced): Promise<any> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Registration/FinalizeNotInsuranced`,
                    payload
                );
                return response.data;
            }
        };
    }

    static resendVerificationEmail() {
        return {
            post: async (email: string): Promise<string> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/authorization/resendVerificationEmail`,
                    `"${email}"`
                );
                return response.data;
            }
        };
    }

    static validatePhoneNumber() {
        return {
            post: async (phoneArr: string[]): Promise<ValidatePhoneNumberResponse> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Notification/PhoneNumberVerification`,
                    {
                        phoneNumbers: phoneArr
                    }
                );
                return response.data;
            }
        };
    }
}
