import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RegistrationHandlers } from './registration.handlers';
import { registrationInitialState } from './registration.initial-values';
import {
    registrationContinueRegistrationRoutine,
    registrationCreateWebAccountRoutine,
    registrationMinimalFinalizeRoutine
} from './registration.routines';
import { RegistrationFormSchema, RegistrationState } from './registration.types';

const registrationSlice = createSlice({
    name: 'registration',
    initialState: registrationInitialState,
    reducers: {
        setRegistrationFormValues: (state, action: PayloadAction<Partial<RegistrationFormSchema>>) =>
            RegistrationHandlers.setFormValues(state, action),
        clearRegistrationFormFields: (state) => RegistrationHandlers.clearAllFormFields(state)
    },
    extraReducers: ({ addCase }) => {
        // Registration Create Web Account Cases
        addCase(registrationCreateWebAccountRoutine.TRIGGER, (state) => RegistrationHandlers.setLoading(state, true));
        addCase(registrationCreateWebAccountRoutine.SUCCESS, (state) => RegistrationHandlers.setLoading(state, false));
        addCase(registrationCreateWebAccountRoutine.FAILURE, (state) => RegistrationHandlers.setLoading(state, false));

        // Registration Continue Cases
        addCase(registrationContinueRegistrationRoutine.TRIGGER, (state) =>
            RegistrationHandlers.setLoading(state, true)
        );
        addCase(
            registrationContinueRegistrationRoutine.SUCCESS,
            (state, { payload }: PayloadAction<RegistrationState>) =>
                RegistrationHandlers.onSuccess(state, payload.registration)
        );
        addCase(registrationContinueRegistrationRoutine.FAILURE, (state) =>
            RegistrationHandlers.setLoading(state, false)
        );

        // Registration Minimal Finalize Cases
        addCase(registrationMinimalFinalizeRoutine.TRIGGER, (state) => RegistrationHandlers.setLoading(state, true));
        addCase(registrationMinimalFinalizeRoutine.SUCCESS, (state) => RegistrationHandlers.setLoading(state, false));
        addCase(registrationMinimalFinalizeRoutine.FAILURE, (state) => RegistrationHandlers.setLoading(state, false));
    }
});

export const { setRegistrationFormValues, clearRegistrationFormFields } = registrationSlice.actions;
export default registrationSlice.reducer;
