import { call } from 'redux-saga/effects';

import { BaseEffectHandler } from 'types/saga-handler';

export function* baseEffectHandler<Payload, Response = any>({
    service,
    data,
    isAuthenticatedService,
    isLoggedIn,
    onResponse,
    onError,
    onComplete
}: BaseEffectHandler<Payload, Response>): any {
    try {
        if (!isAuthenticatedService || (isAuthenticatedService && isLoggedIn)) {
            const response = yield call(service, data);
            if (!response.error && response.status !== 500 && response.status !== 400) {
                if (onResponse) yield onResponse(response);
            } else {
                if (response.error || (response.data && response.data.messageErrorText)) {
                    throw response;
                }
            }
        }
    } catch (err) {
        if (onError) yield onError({ response: err });
    } finally {
        if (onComplete) yield onComplete();
    }
}

const defaultExport = { baseEffectHandler };
export default defaultExport;
