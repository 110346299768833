import { PayloadAction } from '@reduxjs/toolkit';
import { RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';
import produce from 'immer';
import { put, select, takeLatest } from 'redux-saga/effects';

import {
    accountCreditCardsSelector,
    accountIsLoggedInSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import { AllCreditCardsPayload, CreditCardPayload, ProfileObjectPayload } from 'state/account/account.services';
import {
    cancelOrderLine,
    cartCompleteOrderRoutine,
    cartUpdateExpeditedShippingRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateShippingRoutine,
    getCartRoutine,
    startCartRoutine,
    updateRefillLinesRoutine
} from 'state/cart/cart.routines';
import { cartOrderBillShipMethodSelector, cartSelector } from 'state/cart/cart.selectors';
import cartService, {
    CancelOrderLinePayload,
    CartObjectPayload,
    CartObjectV2Payload,
    OrderV2Payload,
    StartOrderV2Payload
} from 'state/cart/cart.services';

import { OrderBillShip, RefillRxs } from 'types/order-prescription';
import { RxDetails } from 'types/prescription';

import { DEFAULT_SHIPPING_ID } from 'util/cart';
import { TrackError } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';

import { medicineCabinetPrescriptionsSelector } from '../medicine-cabinet/medicine-cabinet.selectors';
import { updateLocalCartState } from './cart.actions';
import { getMainCart } from './cart.helpers';

export default function* cartSaga() {
    yield takeLatest(
        getCartRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                onSuccess?: (data: any) => void;
                onFailure?: (data: any) => void;
            }>
        ) {
            try {
                const { onSuccess, onFailure } = action.payload || {};
                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);
                const epostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);

                if (isLoggedIn) {
                    yield baseEffectHandler<CartObjectV2Payload>({
                        service: isCaregiver ? cartService.startCart().getAllV2 : cartService.startCart().getAll,
                        isAuthenticatedService: true,
                        isLoggedIn,
                        *onResponse(data) {
                            const result = { cart: isCaregiver ? data : [data], isCaregiver, epostPatientNum };

                            yield put(getCartRoutine.success(result));
                            if (isCaregiver) {
                                const cartWithShippingID = data?.find(
                                    (cart) => cart?.Order?.orderBillShip?.shipMethodId
                                );
                                const shipMethodId =
                                    cartWithShippingID?.Order?.orderBillShip?.shipMethodId || DEFAULT_SHIPPING_ID;
                                yield put(updateLocalCartState({ shipMethodId }));
                            } else {
                                const shipMethodId = data?.Order?.orderBillShip?.shipMethodId || DEFAULT_SHIPPING_ID;
                                yield put(updateLocalCartState({ shipMethodId }));
                            }
                            if (onSuccess) onSuccess(result);
                        },
                        *onError(data) {
                            if (onFailure) onFailure(data);
                            yield put(getCartRoutine.failure(data));
                        }
                    });
                }
            } catch (e) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure(e);
                yield put(getCartRoutine.failure(e));
            }
        }
    );

    yield takeLatest(
        startCartRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumber: string;
                epostPatientNum: string;
                onSuccess: (data: any) => void;
                onFailure: (data: any) => void;
            }>
        ) {
            try {
                const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
                const creditCardsObject: AllCreditCardsPayload = yield select(accountCreditCardsSelector);
                const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);
                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                const cartObjects: StartOrderV2Payload[] = yield select(cartSelector);
                const accountEpostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);

                const { rxNumber, epostPatientNum, onFailure, onSuccess } = action.payload;

                const cart = getMainCart(cartObjects, epostPatientNum);
                // Get the main cart's ship method id to set to dependents
                const mainCartShipMethodId = cart?.Order?.orderBillShip?.shipMethodId;

                const prescriptionsObject: RxDetails = yield select(medicineCabinetPrescriptionsSelector);
                const orderDate = new Date().toISOString();
                const defaultAddress = profileObject?.addresses.find((obj) => {
                    return obj.defaultShip;
                });

                const defaultCreditCard =
                    creditCardsObject !== undefined && creditCardsObject.length > 0
                        ? creditCardsObject.find((obj: { defaultCard: any }) => obj.defaultCard)?.cardSeqNum
                        : null;
                let currentPrescription = prescriptionsObject?.find((obj) => {
                    return obj.rxNumber;
                });

                currentPrescription = {
                    ...currentPrescription,
                    webEligibilityStatus: RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE
                };

                const rxsToOrder: RefillRxs[] = [];
                const rxObject = {
                    rxNumber: rxNumber,
                    lastRefillScriptId: String(currentPrescription?.prevScriptId),
                    originationNum: '6',
                    messageText: '',
                    messageStatus: true,
                    rxLineError: 0,
                    lineTotal: null,
                    fillNote: '',
                    epostRxScriptId: '',
                    orderNum: '',
                    patientCopay: null,
                    planNum: '',
                    insurancePayment: null,
                    messageErrorText: ''
                };
                rxsToOrder.push(rxObject);
                cart?.Order.refillRxs.map((refill) => {
                    rxsToOrder.push(refill);
                });

                const orderBillShip = {
                    ordShipDate: orderDate,
                    orderPaymentOwner: profileObject?.epostPatientNum,
                    paymentMethodId: '2',
                    shipMethodId: mainCartShipMethodId,
                    orderNum: null,
                    patientBillAddressSeq: defaultAddress !== undefined ? defaultAddress.addressSeqNum : null,
                    patientShipAddressSeq: defaultAddress !== undefined ? defaultAddress.addressSeqNum : null,
                    paymentCardSeqNum: defaultCreditCard !== undefined ? defaultCreditCard : null
                };

                // When updating, consider the easy-refill (gatsby/src/state/easy-refill/easy-refill.sagas.ts:834)
                const updatedCartObject = produce(cart, (draftState) => {
                    if (!draftState) return;

                    if (accountEpostPatientNum === cart?.EpostPatientNum) {
                        draftState.Order.orderBillShip = { ...draftState.Order.orderBillShip, ...orderBillShip };
                    } else {
                        draftState.Order.orderBillShip.paymentCardSeqNum = null;
                        draftState.Order.orderBillShip.patientShipAddressSeq = null;
                    }

                    draftState.Order.doNotSubmitToWorkflow = true;
                    draftState.Order.orderHeader.forceReview = false;
                    draftState.Order.orderHeader.locationId = cart.Order.orderHeader.locationId;
                    draftState.Order.orderHeader.orderDate = orderDate;
                    draftState.Order.orderHeader.orderHighPriority = false;
                    // DRX-1548: use orderStatusNum: null (was previously '1'); ANS has advised that this is the optimal way to handle
                    draftState.Order.orderHeader.orderStatusNum = draftState.Order?.orderHeader?.orderStatusNum || null;
                    draftState.Order.orderHeader.originationNum = '6';
                    draftState.Order.orderHeader.workflowTypeNum = '3';
                    draftState.Order.orderHeader.orderInvoiceNumber =
                        draftState.Order?.orderHeader?.orderInvoiceNumber || null;
                    draftState.Order.orderHeader.orderNum = draftState.Order?.orderHeader?.orderNum || null;
                    draftState.Order.originationNum = '6';
                    draftState.Order.refillRxs = [...rxsToOrder];

                    draftState.Order.cartId = draftState.Order?.cartId || null;

                    // DRX-3081; Reset the message* fields on the order, this is needed when attempting to add multiple RXs to the order
                    draftState.Order.messageErrorText = '';
                    draftState.Order.messageStatus = true;
                    draftState.Order.messageText = '';
                });

                const payload = { epostPatientNum: accountEpostPatientNum, updatedCartObject };

                yield put(updateLocalCartState({ shipMethodId: DEFAULT_SHIPPING_ID }));
                yield baseEffectHandler<StartOrderV2Payload>({
                    service: isCaregiver ? cartService.startCart().postV2 : cartService.startCart().post,
                    isAuthenticatedService: true,
                    isLoggedIn,
                    data: payload,
                    *onResponse(data) {
                        const index = cartObjects?.findIndex((cart) => cart.EpostPatientNum == epostPatientNum);
                        const updatedCart = cartObjects.map((cart) => ({ ...cart }));
                        if (index !== -1) {
                            updatedCart[index] = { ...data };
                            updatedCart[index].Order.orderBillShip = {
                                ...updatedCart[index].Order.orderBillShip,
                                ...orderBillShip
                            };
                        }
                        if (onSuccess) onSuccess(updatedCart);
                        yield put(startCartRoutine.success(updatedCart));
                    },
                    *onError(data) {
                        console.log(data);
                        if (onFailure) onFailure(data);
                        yield put(startCartRoutine.failure(data));
                    }
                });
            } catch (e) {
                console.log(e);
                const { onFailure } = action.payload;
                if (onFailure) onFailure(e);
                yield put(startCartRoutine.failure(undefined));
                TrackError('cart.sagas.ts', 'startCartRoutine', e);
            }
        }
    );

    yield takeLatest(
        updateRefillLinesRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumber: string;
                epostPatientNum: string;
                onSuccess: (data: any) => void;
                onFailure: (data: any) => void;
            }>
        ) {
            try {
                const { rxNumber, epostPatientNum, onFailure, onSuccess } = action.payload;

                const cartObjects: StartOrderV2Payload[] = yield select(cartSelector);
                const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);
                const prescriptionsObject: RxDetails = yield select(medicineCabinetPrescriptionsSelector);
                const accountEpostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
                const shippingMethodId = yield select(cartOrderBillShipMethodSelector);
                let currentPrescription = prescriptionsObject?.find((obj) => {
                    return obj.rxNumber;
                });
                currentPrescription = {
                    ...currentPrescription,
                    webEligibilityStatus: RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE
                };

                const cart = getMainCart(cartObjects, epostPatientNum);

                const rxObjects: RefillRxs[] = [];
                const newObject: RefillRxs = {
                    rxNumber: rxNumber,
                    lastRefillScriptId: String(currentPrescription?.prevScriptId),
                    originationNum: '6',
                    messageText: '',
                    messageStatus: true,
                    rxLineError: '',
                    lineTotal: null,
                    fillNote: '',
                    epostRxScriptId: '',
                    orderNum: '',
                    patientCopay: null,
                    insurancePayment: null,
                    messageErrorText: '',
                    planNum: ''
                };
                rxObjects.push(newObject);

                const updatedCartObject = produce(cart, (draftState) => {
                    if (draftState) {
                        rxObjects.forEach((newObject) => {
                            draftState.Order.refillRxs.push(newObject);
                            draftState.Order.orderBillShip.shipMethodId = shippingMethodId;
                        });
                    }
                });

                const payload = { epostPatientNum: accountEpostPatientNum, updatedCartObject };

                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                yield baseEffectHandler<StartOrderV2Payload>({
                    service: isCaregiver ? cartService.startCart().postV2 : cartService.startCart().post,
                    isAuthenticatedService: true,
                    isLoggedIn,
                    data: payload,
                    *onResponse(data) {
                        const index = cartObjects?.findIndex((cart) => cart.EpostPatientNum == epostPatientNum);
                        const updatedCart = cartObjects.map((cart) => ({ ...cart }));
                        if (index !== -1) updatedCart[index] = { ...data };
                        if (onSuccess) onSuccess(updatedCart);
                        yield put(updateRefillLinesRoutine.success(updatedCart));
                    },
                    *onError(data) {
                        console.log(data);
                        if (onFailure) onFailure(data);
                        yield put(updateRefillLinesRoutine.failure(data));
                    }
                });
            } catch (e) {
                console.log(e);
                const { onFailure } = action.payload;
                if (onFailure) onFailure(e);
                yield put(updateRefillLinesRoutine.failure());
                TrackError('cart.sagas.ts', 'updateRefillLinesRoutine', e);
            }
        }
    );

    yield takeLatest(cancelOrderLine.TRIGGER, function* (action: PayloadAction<any>) {
        try {
            const {
                payload: { rxNumber, onSuccess }
            } = action;

            const cartObjects: StartOrderV2Payload[] = yield select(cartSelector);
            const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);

            const cartObject = cartObjects.find((cart) =>
                cart.Order.refillRxs.find((refills) => refills.rxNumber === rxNumber)
            );
            const cartItems: RefillRxs[] | undefined = cartObject?.Order.refillRxs;

            const currentPrescription = cartItems?.find((obj: any) => {
                return obj.rxNumber === rxNumber;
            });
            let cancelObject = {
                rxNumber: rxNumber,
                lineId: currentPrescription?.epostRxScriptId,
                orderNum: cartObject?.Order.orderHeader.orderNum
            };

            if (isCaregiver) cancelObject = { ...cancelObject, epostNumPatient: cartObject?.EpostPatientNum };

            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            yield baseEffectHandler<CancelOrderLinePayload>({
                service: isCaregiver ? cartService.cancelOrder().postV2 : cartService.cancelOrder().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cancelObject,
                *onResponse(data) {
                    const index = cartObjects?.findIndex(
                        (cart) => cart.EpostPatientNum === cartObject?.EpostPatientNum
                    );
                    const updatedCart = cartObjects.map((cart) => ({ ...cart }));
                    if (index !== -1)
                        updatedCart[index] = {
                            ...updatedCart[index],
                            Order: {
                                ...data,
                                refillRxs: updatedCart[index].Order.refillRxs.filter(
                                    (refill) => refill.epostRxScriptId !== cancelObject.lineId
                                )
                            }
                        };

                    yield put(getCartRoutine.trigger());

                    yield put(cancelOrderLine.success(updatedCart));
                    if (onSuccess) onSuccess();
                },
                *onError(data) {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                    yield put(cancelOrderLine.failure(data));
                }
            });
        } catch (e) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cancelOrderLine.failure());
            TrackError('cart.sagas.ts', 'cancelOrderLine', e);
        }
    });

    yield takeLatest(
        cartCompleteOrderRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                lineItems?: RefillRxs[];
                onSuccess?: (data: unknown) => void;
                onFailure?: (data: unknown) => void;
            }>
        ) {
            const {
                payload: { onSuccess, onFailure, lineItems }
            } = action;
            try {
                const orderObject: OrderV2Payload[] = yield select(cartSelector);
                const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
                const creditCardsObject: CreditCardPayload[] = yield select(accountCreditCardsSelector);
                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);

                const defaultAddress = profileObject?.addresses.find((obj) => {
                    return obj.defaultShip;
                });
                const defaultCreditCard = creditCardsObject.find((obj) => {
                    return obj.defaultCard;
                });

                const updatedCartObject = produce(orderObject, (draftState) => {
                    if (draftState) {
                        draftState = draftState.map((orderCart: OrderV2Payload) => {
                            const cartItem: OrderV2Payload = { ...orderCart };
                            // Use default address there is no address selected
                            if (defaultAddress !== undefined) {
                                cartItem.Order.orderBillShip.patientBillAddressSeq =
                                    orderCart.Order.orderBillShip.patientBillAddressSeq ?? defaultAddress.addressSeqNum;
                                cartItem.Order.orderBillShip.patientShipAddressSeq =
                                    orderCart.Order.orderBillShip.patientShipAddressSeq ?? defaultAddress.addressSeqNum;
                            }

                            // Use default credit card there is no credit card selected
                            if (defaultCreditCard !== undefined) {
                                cartItem.Order.orderBillShip.paymentCardSeqNum =
                                    orderCart.Order.orderBillShip.paymentCardSeqNum ?? defaultCreditCard.cardSeqNum;
                            }

                            cartItem.Order.orderBillShip.paymentMethodId =
                                orderCart.Order.orderBillShip.paymentMethodId === '6' &&
                                orderCart.Order.orderBillShip.paymentCardSeqNum
                                    ? '2'
                                    : orderCart.Order.orderBillShip.paymentMethodId;

                            if (lineItems !== undefined) {
                                const cleanRefillRxs: RefillRxs[] = [];

                                orderCart.Order.refillRxs.forEach((rx: RefillRxs) => {
                                    const currentPrescription = lineItems.find((lineItem: RefillRxs) => {
                                        return rx.rxNumber === lineItem.rxNumber;
                                    });
                                    if (currentPrescription) {
                                        // Exclude extra attributes included in the ExtendedRefillRxs object
                                        const cleanRefillRx: RefillRxs & { [key: string]: any } = rx;
                                        Object.keys(rx).forEach(function (key) {
                                            cleanRefillRx[key] = currentPrescription[key];
                                        });
                                        cleanRefillRxs.push(cleanRefillRx);
                                    } else {
                                        cleanRefillRxs.push(rx);
                                    }
                                });
                                cartItem.Order.refillRxs = cleanRefillRxs;
                            }
                            return cartItem;
                        });
                    }
                });

                yield baseEffectHandler<OrderV2Payload[]>({
                    service: isCaregiver ? cartService.completeOrder().postV2 : cartService.completeOrder().post,
                    isAuthenticatedService: true,
                    isLoggedIn,
                    data: updatedCartObject,
                    *onResponse(data) {
                        yield put(cartCompleteOrderRoutine.success({ cart: data, orderPlaced: true }));
                        if (onSuccess) onSuccess(data);
                    },
                    *onError(data) {
                        yield put(cartCompleteOrderRoutine.failure({ cart: data, orderPlaced: false }));
                        if (onFailure) onFailure(data);
                    }
                });
            } catch (e: any) {
                yield put(cartCompleteOrderRoutine.failure());
                if (onFailure) onFailure(e);
                TrackError('cart.sagas.ts', 'cartCompleteOrderRoutine', e);
            }
        }
    );

    yield takeLatest(cartUpdateShippingRoutine.TRIGGER, function* (action: PayloadAction<OrderBillShip>) {
        try {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const epostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
            const cartsObject: OrderV2Payload[] = yield select(cartSelector);
            const cartObject = cartsObject?.find((cart) => cart?.Order.orderBillShip.patientBillAddressSeq !== null)
                ? cartsObject?.find((cart) => cart?.Order.orderBillShip.patientBillAddressSeq !== null)
                : getMainCart(cartsObject, epostPatientNum);

            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.Order.orderBillShip = action.payload;
                    draftCartObject.Order.orderBillShip.shipMethodId = action.payload.shipMethodId;
                }
            });

            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload?.Order,
                *onResponse(data) {
                    const { onSuccess } = action.payload;
                    if (onSuccess) onSuccess();
                    yield put(
                        cartUpdateShippingRoutine.success({
                            Order: cartObjectPayload?.Order,
                            epostPatientNum
                        })
                    );
                    yield put(updateLocalCartState({ shipMethodId: action.payload.shipMethodId }));
                },
                *onError(data) {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                    yield put(cartUpdateShippingRoutine.failure(data));
                }
            });
        } catch (e) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cartUpdateShippingRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdateShippingRoutine', e);
        }
    });

    yield takeLatest(cartUpdateExpeditedShippingRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const { orderHighPriority, shipMethodId, onSuccess, onFailure } = action.payload;
        try {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const epostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
            const cartsObject: OrderV2Payload[] = yield select(cartSelector);

            const cartObject = cartsObject?.find((cart) => cart?.Order.orderBillShip.shipMethodId !== null)
                ? cartsObject?.find((cart) => cart?.Order.orderBillShip.shipMethodId !== null)
                : getMainCart(cartsObject, epostPatientNum);

            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.Order.orderBillShip.shipMethodId = shipMethodId;
                    draftCartObject.Order.orderHeader.orderHighPriority = orderHighPriority;
                }
            });

            yield put(updateLocalCartState({ shipMethodId }));
            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload?.Order,
                *onResponse(data) {
                    if (onSuccess) onSuccess();
                    yield put(cartUpdateExpeditedShippingRoutine.success({ ...cartObjectPayload, shipMethodId }));
                },
                *onError(data) {
                    if (onFailure) onFailure();
                    yield put(cartUpdateExpeditedShippingRoutine.failure(data));
                }
            });
        } catch (e) {
            if (onFailure) onFailure();
            yield put(cartUpdateExpeditedShippingRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdateExpeditedShippingRoutine', e);
        }
    });

    yield takeLatest(cartUpdatePaymentRoutine.TRIGGER, function* (action: PayloadAction<OrderBillShip>) {
        try {
            const { onSuccess, onFailure } = action.payload;

            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const epostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
            const cartsObject: OrderV2Payload[] = yield select(cartSelector);
            const cartObject = cartsObject?.find((cart) => cart?.Order.orderBillShip.paymentCardSeqNum !== null)
                ? cartsObject?.find((cart) => cart?.Order.orderBillShip.paymentCardSeqNum !== null)
                : getMainCart(cartsObject, epostPatientNum);

            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.Order.orderBillShip = action.payload;
                }
            });

            yield baseEffectHandler<CartObjectPayload>({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload?.Order,
                *onResponse(data) {
                    if (data.status === 204) {
                        if (onSuccess) onSuccess();
                        yield put(
                            cartUpdatePaymentRoutine.success({ Order: cartObjectPayload?.Order, epostPatientNum })
                        );
                    }
                },
                *onError(data) {
                    if (onFailure) onFailure();
                    yield put(cartUpdatePaymentRoutine.failure(data));
                }
            });
        } catch (e) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cartUpdatePaymentRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdatePaymentRoutine', e);
        }
    });
}
