import { SelectPlanState } from 'state/membership-registration/membership-registration.reducer';

import { formatPrice } from 'schema/price.schema';

import { MembershipDetails, MembershipUpgradePlanPayload, PricingDetails } from 'types/membership';

export const PATIENT_ALREADY_INVITED = 'There is already an invitation for this Patient';
export const PATIENT_ALREADY_HAS_CAREGIVER = 'Patient is already a dependent in another caregiver account';

/**
 * DRX-2761: In order to manage the expected error responses,
 * we have implemented this constant to store all possible errors.
 */
export const MEMBERSHIP_RESPONSE_ERRORS = {
    PATIENT_HAS_ALREADY_PLAN: 'Invite cannot be sent, the patient already has a Care or Care+ plan',
    PAYMENT_NOT_AUTHORIZED: 'Payment Not Authorized Difference Upgrade',
    PREAUTH_PAYMENT_NOT_AUTORIZED: 'Error with Pre-Authorized Payment Attempt',
    PAYMENT_PROCESS_WITH_ERRORS: 'Payment Processed with Errors'
} as const;

export const DEFAULT_MEMBERSHIP_JOIN_FEE = 49.0;

/**
 * Generates available plans based on the provided parameters.
 * @param {string} paymentModel - The payment model, either 'Annually' or 'Monthly'.
 * @param {Array} planDetails - Details of all available plans.
 * @param {boolean} isOnDemandPlan - Indicates whether it's an on-demand plan.
 * @param {boolean} isCarePlan - Indicates whether it's a care plan.
 * @param {object} membershipDetails - Details of the current membership.
 * @param {string} selectedPlanId - The ID of the currently selected plan.
 * @param {function} onChangeSelection - The function to handle plan selection changes.
 * @returns {Array<MembershipAvailablePlanCardProps>} An array of available plans based on the provided parameters.
 */
export const generateAvailablePlans = (
    paymentModel: string,
    planDetails?: SelectPlanState[],
    isOnDemandPlan?: boolean,
    isCarePlan?: boolean,
    membershipDetails?: MembershipDetails,
    selectedPlanId?: number,
    onChangeSelection?: (selectedPlan: Partial<MembershipUpgradePlanPayload>) => void,
    pricingDetails?: PricingDetails[]
) => {
    if (!planDetails) return [];

    return planDetails
        .filter((plan) => {
            if (isOnDemandPlan) {
                return plan.membershipPlanId !== membershipDetails?.planId;
            } else if (isCarePlan) {
                return plan.membershipPlanId !== membershipDetails?.planId && plan.membershipPlanId !== 3;
            }
            return true; // Return true for other cases
        })
        .map((plan) => {
            const commonProperties = {
                membershipPlanId: plan.membershipPlanId,
                planName: plan.membershipPlanDescription,
                availableMembers: plan.membershipPlanAttributes[0].membershipMembers,
                features: [
                    {
                        name:
                            plan.membershipPlanAttributes[0].membershipIncludedrx > 1
                                ? 'prescriptions'
                                : 'prescription',
                        total: plan.membershipPlanAttributes[0].membershipIncludedrx,
                        monthlyFrequency: 12
                    },
                    {
                        name:
                            plan.membershipPlanAttributes[0].membershipIncludedvisits > 1
                                ? 'telemedicineVisits'
                                : 'telemedicineVisit',
                        total: plan.membershipPlanAttributes[0].membershipIncludedvisits,
                        monthlyFrequency: 12
                    }
                ],
                joinFee: plan.membershipPlanAttributes[0].joinFeeAmount
                    ? formatPrice(plan.membershipPlanAttributes[0].joinFeeAmount)
                    : undefined,
                price:
                    paymentModel === 'Annually'
                        ? formatPrice(plan.membershipPlanAttributes[0].pricingAnnual / 12 || 0)
                        : formatPrice(plan.membershipPlanAttributes[0].pricingMonthly || 0),
                isSelected: selectedPlanId === plan.membershipPlanId || false,
                savePercentage: paymentModel === 'Annually' ? 15 : null,
                isPayAsYouGo: plan.membershipPlanAttributes[0].payAsYouGo || false
            };

            if (isOnDemandPlan) {
                const findPrice = pricingDetails?.find((f) => f.PlanId === plan.membershipPlanId);

                return {
                    ...commonProperties,
                    ...(paymentModel === 'Annually' && {
                        priceAccumulated: {
                            total: plan.membershipPlanAttributes[0].pricingAnnual ?? 0,
                            monthlyFrequency: 12,
                            showBilled: false
                        }
                    }),
                    onChange: () =>
                        onChangeSelection
                            ? onChangeSelection({
                                  membershipPlanId: plan.membershipPlanId,
                                  chargeAmount:
                                      paymentModel === 'Annually' ? findPrice?.AnnualPrice : findPrice?.MonthlyPrice,
                                  newValuePlan:
                                      paymentModel === 'Annually' ? findPrice?.AnnualPrice : findPrice?.MonthlyPrice,
                                  termlength: paymentModel === 'Annually' ? 12 : 1
                              })
                            : undefined
                };
            }

            if (isCarePlan) {
                return {
                    ...commonProperties,
                    ...(paymentModel === 'Annually' && {
                        priceAccumulated: {
                            total: formatPrice(plan.membershipPlanAttributes[0].pricingAnnual),
                            monthlyFrequency: 12,
                            showBilled: false
                        }
                    }),
                    isSelected: true
                };
            }

            return [];
        });
};
