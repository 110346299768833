/* eslint-disable no-unused-vars */
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';

import { ConfirmationStatuses } from 'components/verify-sms-modal/types';

import { AccountState } from 'state/account/account.reducers';
import { RootState } from 'state/store';

import { findPrimaryPlanAlias } from './account.helpers';

export const accountStateSelector = (state: RootState): AccountState => state.accountReducer;

export const accountRegistrationSelector = createSelector(accountStateSelector, (account) => account.registration);
export const accountProfileSelector = createSelector(accountStateSelector, (account) => account.profileObject);
export const accountPlansSelector = createSelector(accountStateSelector, (account) => account.plansObject);
export const accountProfileAddressesSelector = createSelector(accountProfileSelector, (profile) =>
    profile && profile.addresses ? profile?.addresses : []
);
export const accountIsLoggedInSelector = createSelector(accountStateSelector, (account) => {
    const now = Date.now();
    return !account.isLoggingOut &&
        account.token &&
        account.issued &&
        account.expires &&
        account.sessionExpiration &&
        now < account.expires &&
        now < account.sessionExpiration
        ? true
        : false;
});

export const accountSignInUsernameSelector = createSelector(accountStateSelector, (account) => account.signInUsername);

export const accountHasInsuranceSelector = createSelector(accountProfileSelector, (profile) => {
    return profile && profile?.cardholderID && !profile?.cardholderID.includes('BRD') ? true : false;
});
export const accountAcCodeSelector = createSelector(accountProfileSelector, (profile) => {
    let insuranceName = 'BRD01';
    // If the user is logged in. For non-insured users, this will still resolve to
    // 'BRD01', the same as the default value for insuranceName.
    if (profile && profile?.insuranceName) {
        insuranceName = profile?.insuranceName.split('-')[0].trim();
    }
    return insuranceName;
});
export const accountHealthConditionsSelector = createSelector(
    accountStateSelector,
    ({ healthConditions }: AccountState) => ({
        healthConditions
    })
);

export const accountAllergiesSelector = createSelector(
    accountStateSelector,
    (healthConditions) => healthConditions.healthConditions.allergies
);

export const accountMedicalConditionsSelector = createSelector(
    accountStateSelector,
    (healthConditions) => healthConditions.healthConditions.medicalConditions
);

export const accountWebProfileSelector = createSelector(accountStateSelector, (account) => account.webProfile);
export const accountChangedPasswordSelector = createSelector(
    accountStateSelector,
    (account) => account.passwordChangeSuccess
);
export const accountCreditCardsSelector = createSelector(accountStateSelector, (account) => account.creditCards);
export const accountRepresentativesSelector = createSelector(
    accountStateSelector,
    (account) => account.representatives
);
export const accountIsSubmittingPasswordResetSelector = createSelector(
    accountStateSelector,
    (account) => account.isSubmittingPasswordReset
);
export const paymetricDetailsSelector = createSelector(accountStateSelector, (account) => account.paymetricDetails);
export const paymetricTokenizedCardSelector = createSelector(accountStateSelector, (account) => account.pciToken);
export const accountContactUsSelector = createSelector(accountStateSelector, (account) => account.contactUs);
export const accountNotificationsSelector = createSelector(accountStateSelector, (account) => account.notifications);
export const accountMessagesSelector = createSelector(accountStateSelector, (account) => account.messages);
export const accountAcknowledgementSelector = createSelector(
    accountStateSelector,
    (account) => account.acknowledgement
);
export const accountAcknowledgementTermsStatusSelector = createSelector(
    accountStateSelector,
    (account) => !!account.acknowledgement?.Acknowledgements?.find((ack) => ack.Type === 'Terms of Use')
);
export const accountHIPAASelector = createSelector(accountStateSelector, (account) => account.HIPAA);
export const accountAcknowledgementCurrentVIDSelector = createSelector(
    accountStateSelector,
    (account) => account?.currentVid
);

export const isLoadingAccountMedicalConditionsSelector = createSelector(
    accountStateSelector,
    (account) => account.isLoadingHealthConditions
);

export const currentMessageThreadSelector = createSelector(
    accountMessagesSelector,
    (message) => message?.messageThread
);

export const accountSmsPendingRequestsSelector = createSelector(
    accountStateSelector,
    (account) => account.pendingSmsOptIns
);

export const accountSmsRequestsVerifiedSelector = createSelector(
    [accountSmsPendingRequestsSelector, accountStateSelector],
    (pendingOptIns, account) => {
        const anyPendingRequests = pendingOptIns.filter((optIn) => optIn.status !== ConfirmationStatuses.Confirmed);
        return account.pendingSmsOptInsLoaded && anyPendingRequests.length === 0;
    }
);

export const accountSmsRequestsPendingSelector = createSelector(
    [accountSmsPendingRequestsSelector, accountStateSelector],
    (pendingOptIns, account) => {
        const anyPendingRequests = pendingOptIns.filter((optIn) => optIn.status === ConfirmationStatuses.NoChange);
        return account.pendingSmsOptInsLoaded && anyPendingRequests.length > 0;
    }
);

export const accountSmsPendingRequestsLoadedSelector = createSelector(
    accountStateSelector,
    (account) => account.pendingSmsOptInsLoaded
);

export const accountSmsCheckingStatusSelector = createSelector(
    accountStateSelector,
    (account) => account.checkingSmsStatus
);

export const accountSmsCancelingRequestSelector = createSelector(
    accountStateSelector,
    (account) => account.cancelingSmsRequest
);

export const accountSmsRequestInitiatedSelector = createSelector(accountSmsPendingRequestsSelector, (pendingOptIns) => {
    const timeRequestInitiated = pendingOptIns.map((smsRequest) => smsRequest.TimeInitiated);
    if (timeRequestInitiated.length > 0) {
        const date = timeRequestInitiated[timeRequestInitiated.length - 1];
        const userTimezoneOffset = moment().utcOffset();
        return (
            Number(moment.parseZone(date).utcOffset(userTimezoneOffset, true).format('x')) + userTimezoneOffset * 60000
        );
    }
    return Date.now();
});

export const accountSmsRequestsNotVerifiedSelector = createSelector(
    [accountSmsPendingRequestsSelector, accountStateSelector],
    (pendingOptIns, account) => {
        const anyCanceledRequests = pendingOptIns.filter((optIn) =>
            [ConfirmationStatuses.Denied, ConfirmationStatuses.Expired].includes(optIn.status)
        );
        return account.pendingSmsOptInsLoaded && anyCanceledRequests.length > 0;
    }
);

export const accountUnauthenticatedSessionExpirationSelector = createSelector(
    accountStateSelector,
    (account) => account.unauthenticatedSesssionExpiration
);

export const accountAllSmsNumbersSelector = createSelector(accountNotificationsSelector, (notifications) => {
    return [
        notifications?.MarketingTextNumber,
        notifications?.NewScriptTextNumber,
        notifications?.RefillReminderTextNumber,
        notifications?.OrderShippedTextNumber
    ];
});

export const accountUserIpAddressSelector = createSelector(accountStateSelector, (account) => account.userIpAddress);

export const accountProfilEPostPatientNumSelector = createSelector(
    accountStateSelector,
    (account) => account.profileObject?.epostPatientNum
);

export const accountProfilIsCaregiverSelector = createSelector(accountStateSelector, (account) => {
    return account.profileObject?.isCaregiver;
});

export const accountIsCaliforniaUserSelector = createSelector(accountProfileSelector, (profile) => {
    return !!(profile && profile?.addresses?.find((address) => address.state === 'CA' && address.defaultShip));
});

export const accountIsMinorSelector = createSelector(accountStateSelector, (account) => {
    return account?.isMinor;
});

export const accountProfileMembershipSelector = createSelector(accountStateSelector, (account) => {
    return { hasMembership: account.profileObject?.hasMembership, membershipID: account.profileObject?.membershipID };
});

export const accountIsMembershipSelector = createSelector(accountStateSelector, (account) => {
    return account.profileObject?.hasMembership;
});

export const accountDefaultPhoneSelector = createSelector(accountStateSelector, (account) => {
    return account.profileObject?.phones[0];
});

export const accountIsProfileLoading = createSelector(accountStateSelector, (account) => {
    return account.isProfileLoading;
});

export const accountIsLoadingPaymentMethods = createSelector(accountStateSelector, (account) => {
    return account.isLoadingCreditCards;
});

export const accountHasOutstandingBalance = createSelector(accountStateSelector, (account) => {
    return account.profileObject && Number.parseFloat(account.profileObject.patientBalance) > 0;
});

export const accountAutoRefillPlanEligibleSelector = createSelector([accountStateSelector], (account) => {
    return account?.profileObject?.patientPlanAutoRefillFlag === true;
});

export const accountAutoRefillSelector = createSelector([accountStateSelector], (account) => {
    return account?.profileObject?.autoRefill;
});

export const accountAutoRefillEligibleSelector = createSelector(
    [accountAutoRefillPlanEligibleSelector, accountAutoRefillSelector],
    (planEligible, planAutoRefillStatus) => {
        return planEligible && planAutoRefillStatus === false;
    }
);

export const accountRegistrationFormValuesSelector = createSelector(accountStateSelector, (account) => {
    return account.assitsOthersFormValue;
});

export const accountRegistrationUninsuredRegisterErrorPayloadSelector = createSelector(
    accountStateSelector,
    (account) => {
        return account.errorPayload;
    }
);

/**
 * Selector that returns a boolean indicating whether the registration process for non-insured users is currently in progress.
 *
 * @return {boolean} True if the registration process is in progress, false otherwise.
 */
export const accountRegistrationUninsuredIsBusySelector = createSelector(accountStateSelector, (account) => {
    return account.isBusy;
});

/**
 * Returns the default address from the profile object.
 *
 * @return { Address | undefined } The default address from the profile object, or undefined if no default address is found.
 */
export const accountDefaultAddressSelector = createSelector(accountProfileSelector, (profile) => {
    return profile && profile?.addresses?.find((address) => address.defaultShip);
});

export const accountFamilyPlansMapSelector = createSelector(
    accountProfileSelector,
    accountPlansSelector,
    (account, plans = []): Record<string, string> => {
        const caregiverPlanAlias = findPrimaryPlanAlias(plans, account?.epostPatientNum as string);

        const familyPlanMap = account?.dependents.reduce((acc, item) => {
            const planAlias = findPrimaryPlanAlias(plans, item.epostPatientNum);
            return {
                ...acc,
                [item.epostPatientNum]: planAlias
            };
        }, {} as Record<string, string>);

        // Include caregiver's planAlias in the map
        return {
            ...familyPlanMap,
            [account?.epostPatientNum as string]: caregiverPlanAlias
        };
    }
);

export const accountIsNavitusUserSelector = createSelector([accountStateSelector], (account) => {
    return account?.profileObject?.payorName === 'NAVITUS';
});

export const accountPaymentHistoryV2Selector = createSelector([accountStateSelector], (account) => {
    return account?.paymentHistoryV2;
});

export const accountPaymentHistoryLoadingSelector = createSelector([accountStateSelector], (account) => {
    return account?.isFetchingPaymentHistory;
});
