import { REACT_APP_BOT_VERSION } from 'gatsby-env-variables';
import { ReactElement, useEffect, useState } from 'react';

import useIsChatbotVisible from 'hooks/useIsChatbotVisible';

import ChatbotV1 from './chatbot-v1.component';
import ChatbotV2 from './chatbot-v2.component';

interface ChatBotProps {
    uniqueID: string;
    isInCart?: boolean;
}

/**
 * Retrieves the height of the cookie banner element.
 *
 * The hook sets up an interval to periodically check for changes in the
 * banner's height, ensuring that the height is up-to-date if the banner
 * is dynamically resized.
 *
 * @returns {string} The current height of the cookie banner as a CSS unit. Defaults to '1rem' value.
 *
 */
const useCookieBannerHeight = (): string | undefined => {
    const getCookieBannerHeight = () => {
        const element = document.querySelector('.cookie-banner');
        if (!element) return undefined;

        const style = getComputedStyle(element);
        const bannerSize = style.height;

        return bannerSize;
    };

    const [bannerHeight, setBannerHeight] = useState(getCookieBannerHeight());

    useEffect(() => {
        const checkBanner = () => {
            setBannerHeight(getCookieBannerHeight());
        };

        checkBanner();
        const intervalId = setInterval(checkBanner, 500);
        return () => clearInterval(intervalId);
    }, []);

    return bannerHeight;
};

const Chatbot = (props: ChatBotProps): ReactElement | null => {
    const bannerHeight = useCookieBannerHeight();
    const isVisible = useIsChatbotVisible();

    return isVisible ? (
        <div>
            {REACT_APP_BOT_VERSION === 'v2' ? (
                <ChatbotV2 {...props} bottomHeight={bannerHeight} />
            ) : (
                <ChatbotV1 {...props} bottomHeight={bannerHeight} />
            )}
        </div>
    ) : null;
};

export default Chatbot;
