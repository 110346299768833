import { createRoutine } from 'redux-saga-routines';

import {
    PaymentHistoryLine,
    PaymentHistoryRequestPayload,
    PaymentHistoryV2Response,
    PayPatientBalanceFamilyErrors,
    PayPatientBalanceFamilyResponse,
    PostPatientBalanceFamilyPayload
} from 'types/payment-history';

export const paymentsPostPayBalanceRoutine = createRoutine('PAYMENTS/POST_PAY_BALANCE', {
    success: (response: PayPatientBalanceFamilyResponse) => response
});

export const paymentsV2PostPatientBalanceFamilyRoutine = createRoutine('PAYMENTS/V2/POST_PATIENT_BALANCE_FAMILY', {
    trigger: (payload: PostPatientBalanceFamilyPayload) => payload,
    failure: (response: PayPatientBalanceFamilyErrors) => response
});

export const paymentsGetPaymentHistoryRoutine = createRoutine('PAYMENTS/GET_PAYMENT_HISTORY', {
    trigger: (payload: PaymentHistoryRequestPayload) => payload,
    success: (response: Array<PaymentHistoryLine>) => response
});
export const paymentsV2GetPaymentHistoryRoutine = createRoutine('PAYMENTS/V2/GET_PAYMENT_HISTORY', {
    trigger: (payload: PaymentHistoryRequestPayload) => payload,
    success: (response: PaymentHistoryV2Response) => response
});

export const paymentsGet = createRoutine('PAYMENTS/V2/GET_PAYMENT_HISTORY', {
    trigger: (payload: PaymentHistoryRequestPayload) => payload,
    success: (response: PaymentHistoryV2Response) => response
});
