import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { CreditCardPayload, ProfileObjectAddressPayload } from 'state/account/account.services';

import { updateLocalCartState } from './cart.actions';
import {
    cancelOrderLine,
    cartCompleteOrderRoutine,
    cartUpdateCartRoutine,
    cartUpdateExpeditedShippingRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateShippingRoutine,
    getCartRoutine,
    startCartRoutine,
    updateRefillLinesRoutine
} from './cart.routines';
import { CartObjectPayload, CartObjectV2Payload, OrderV2Payload, StartOrderV2Payload } from './cart.services';

export interface CartOrderSnapshot {
    cart: CartObjectPayload;
    shippingAddress: ProfileObjectAddressPayload;
    paymentCard: CreditCardPayload;
}

export interface cartState {
    error?: string;
    isBusy?: boolean;
    cart?: {
        BirdiOrderNumber?: string;
        FirstName?: string;
        LastName?: string;
        type?: string;
        EpostPatientNum?: string;
        messageErrorText?: string;
        messageStatus?: boolean;
        messageText?: string;
        Order: CartObjectPayload;
    }[];
    orderPlaced: boolean;
    orderSnapshot?: CartOrderSnapshot;
    shipMethodId?: string;
    isLoadingAddressShipping?: boolean;
    isLoadingPayments?: boolean;
    isLoadingCancelPrescriptions?: boolean;
}

export const initialState: cartState = {
    cart: undefined,
    orderPlaced: false,
    orderSnapshot: undefined
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart(state: cartState, action) {
            state.error = undefined;
            state.isBusy = false;
            state.cart = action.payload;
        },
        resetCart(state: cartState) {
            state.error = undefined;
            state.isBusy = false;
            state.cart = undefined;
            state.orderPlaced = false;
        }
        // this one is never called, so i comment the entire reducer.
        // setOrderExpeditedShipping(state: cartState, action) {
        //     if (state.cart) {
        //         state.cart.orderHeader.orderHighPriority = action.payload.expeditedShipping;
        //         state.cart.orderBillShip.shipMethodId = action.payload.code;
        //     }
        // }
    },
    extraReducers: ({ addCase }) => {
        addCase(getCartRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(getCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectV2Payload>) =>
            produce(state, (draftState) => {
                if (payload.isCaregiver) {
                    draftState.cart = payload.cart;
                } else {
                    const { refillRxs, orderHeader, ...params } = payload.cart[0].Order;
                    draftState.cart = [
                        {
                            EpostPatientNum: payload.epostPatientNum, // We will use this parameter to search the cart.
                            Order: {
                                orderHeader: {
                                    ...orderHeader
                                },
                                refillRxs: refillRxs,
                                ...params
                            }
                        }
                    ];
                }
                draftState.orderPlaced = initialState.orderPlaced;
                draftState.isBusy = false;
                draftState.isLoadingPayments = false;
                draftState.isLoadingAddressShipping = false;
                draftState.isLoadingCancelPrescriptions = false;
            })
        );
        addCase(getCartRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
                draftState.orderPlaced = initialState.orderPlaced;
                draftState.isBusy = false;
                draftState.isLoadingPayments = false;
                draftState.isLoadingAddressShipping = false;
                draftState.isLoadingCancelPrescriptions = false;
            })
        );

        /**
         * Cart Start Cart Reducers
         */
        addCase(startCartRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(startCartRoutine.SUCCESS, (state, { payload }: PayloadAction<StartOrderV2Payload[]>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
                draftState.isBusy = false;
            })
        );
        addCase(startCartRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
                draftState.isBusy = false;
            })
        );

        /**
         * Cart Update Cart Reducers
         */
        addCase(cartUpdateCartRoutine.TRIGGER, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                // nothing yet
            })
        );
        addCase(cartUpdateCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = [{ Order: payload }];
            })
        );
        addCase(cartUpdateCartRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
            })
        );

        /**
         * Cart Update Refill Lines Reducers
         */
        addCase(updateRefillLinesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(updateRefillLinesRoutine.SUCCESS, (state, { payload }: PayloadAction<StartOrderV2Payload[]>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
                draftState.isBusy = false;
            })
        );
        addCase(updateRefillLinesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );

        /**
         * Cart Order Line Reducers
         */
        addCase(cancelOrderLine.TRIGGER, (state, { payload }: PayloadAction<StartOrderV2Payload[]>) =>
            produce(state, (draftState) => {
                draftState.isLoadingCancelPrescriptions = true;
            })
        );
        addCase(cancelOrderLine.SUCCESS, (state, { payload }: PayloadAction<StartOrderV2Payload[]>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
            })
        );
        addCase(cancelOrderLine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) => {
            // Do nothing, and leave the cart in the same state.
        });

        /**
         * Cart Update Shipping Reducers
         */
        addCase(
            cartUpdateShippingRoutine.TRIGGER,
            (state, { payload }: PayloadAction<{ Order: CartObjectPayload; epostPatientNum: string }>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingAddressShipping = true;
                })
        );
        addCase(
            cartUpdateShippingRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ Order: CartObjectPayload; epostPatientNum: string }>) =>
                produce(state, (draftState) => {
                    draftState.cart = state.cart;
                    draftState.shipMethodId = state.shipMethodId;
                })
        );
        addCase(cartUpdateShippingRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = state.cart;
            })
        );

        /**
         * Cart Update Payment Reducers
         */
        addCase(cartUpdatePaymentRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
                draftState.isLoadingPayments = true;
            })
        );
        addCase(
            cartUpdatePaymentRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ Order: CartObjectPayload; epostPatientNum: string }>) =>
                produce(state, (draftState) => {
                    draftState.cart = state.cart;
                    // draftState.isLoadingPayments = false;
                    // left finish loader on get cart routine again
                })
        );
        addCase(cartUpdatePaymentRoutine.FAILURE, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.cart = state.cart;
                draftState.isBusy = false;
                draftState.isLoadingPayments = false;
            })
        );
        addCase(updateLocalCartState, (state, { payload }: PayloadAction<{ shipMethodId: string }>) =>
            produce(state, (draftState) => {
                draftState.shipMethodId = payload.shipMethodId;
            })
        );
        /*
         * Complete Cart Routine
         */
        addCase(
            cartCompleteOrderRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ cart: OrderV2Payload[]; orderPlaced: boolean }>) =>
                produce(state, (draftState) => {
                    draftState.orderPlaced = payload.orderPlaced;
                    draftState.cart = payload.cart;
                })
        );

        addCase(cartUpdateExpeditedShippingRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingAddressShipping = true;
            })
        );
        addCase(cartUpdateExpeditedShippingRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingAddressShipping = false;
            })
        );
    }
});

export const { setCart, resetCart } = cartSlice.actions;

export default cartSlice.reducer;
