import { creditCardMonthOptions } from 'const/options';

import { CardType, CardTypeResult } from 'types/payment';

/**
 * @param cardNumber Gets the cart number of the user
 * @returns The card type
 */
export const detectCardType = (cardNumber: string): CardTypeResult | null => {
    const re: Record<CardType, RegExp> = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        amex: /^3[47][0-9]{13}$/
    };

    for (const key in re) {
        if (re[key as CardType].test(cardNumber)) {
            const cardKeys = Object.keys(re);
            return { key: key as CardType, index: (cardKeys.indexOf(key) + 1).toString() };
        }
    }

    return null;
};

export const getMonthNum = (month: string) => {
    return (creditCardMonthOptions.map((month) => month.value).indexOf(month) + 1).toString();
};

export const getCreditCardEnding = (secureCardNumber: string, prefix = '') => {
    return secureCardNumber ? `${prefix}${secureCardNumber.slice(-4)}` : '';
};

interface NameParts {
    firstName: string;
    middleName: string;
    lastName: string;
}

export const getNameParts = (names: string[]): NameParts => {
    if (names.length === 1) {
        return {
            firstName: names[0],
            middleName: '',
            lastName: ''
        };
    }
    const firstName = names[0];
    const middleName = names.length > 2 ? names.slice(1, names.length - 1).join(' ') : '';
    const lastName = names.length > 1 ? names[names.length - 1] : '';

    return {
        firstName,
        middleName,
        lastName
    };
};
