import { PayloadAction } from '@reduxjs/toolkit';
import produce, { Draft } from 'immer';

import { Registration, RegistrationFormSchema, RegistrationState } from './registration.types';

export const RegistrationHandlers = {
    setLoading: (state: Draft<RegistrationState>, isLoading: boolean): Draft<RegistrationState> =>
        produce(state, (draftState) => {
            draftState.isLoading = isLoading;
        }),

    onSuccess: (state: Draft<RegistrationState>, payload: Registration): Draft<RegistrationState> =>
        produce(state, (draftState) => {
            Object.assign(draftState, payload);
            draftState.isLoading = false;
        }),

    setFormValues: (
        state: Draft<RegistrationState>,
        action: PayloadAction<Partial<RegistrationFormSchema>>
    ): Draft<RegistrationState> =>
        produce(state, (draftState) => {
            if (draftState.formValues)
                draftState.formValues = {
                    ...draftState.formValues,
                    ...action.payload
                };
        }),
    clearAllFormFields: (state: Draft<RegistrationState>): Draft<RegistrationState> =>
        produce(state, (draftState) => {
            if (draftState.formValues) {
                Object.keys(draftState.formValues).forEach((key) => {
                    (draftState.formValues as Record<string, any>)[key] = '';
                });
            }
        })
};
