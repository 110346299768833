import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';

import {
    DiscountCardProps,
    DiscountCardResponse,
    DrugFormOptions,
    DrugInfoProps,
    DrugSearchResponse,
    LocationFilters,
    PharmacyResults,
    ScriptSaveAutocompleteResponse,
    UserLocation
} from 'types/discount-card';

import { adaptDrugResponse, formatLocationAddress, titleCaseDrugName } from './discount-card.helpers';
import {
    discountCardSearchFilterRoutine,
    discountCardSearchRoutine,
    discountCardSetLocationRoutine,
    generateDiscountCardRoutine,
    getAutocompleteValues
} from './discount-card.routines';

interface initialStateProps {
    currentLocation: UserLocation | undefined;
    isLoading?: boolean;
    isFilterLoading?: boolean;
    error: {
        messageText?: string;
        messageTextAutocomplete?: string;
    };
    scriptSaveToken: string | null;
    autocompleteValues: string[] | [];
    isAutocompleteLoading: boolean;
    searchResults?: DrugSearchResponse;
    currentDrug?: DrugInfoProps;
    formOptions?: DrugFormOptions;
    currentDrugForm?: DrugInfoProps;
    locationFilters?: LocationFilters;
    generatedDiscountCard?: DiscountCardResponse;
    pharmacies?: PharmacyResults[];
    generatedCard?: DiscountCardProps;
}

/**
 * States provisional, should be complemented or changed as soon as we have the definition of the APIs
 */
const initialState: initialStateProps = {
    currentLocation: undefined,
    isLoading: false,
    error: {
        messageText: undefined,
        messageTextAutocomplete: undefined
    },
    scriptSaveToken: null,
    autocompleteValues: [],
    isAutocompleteLoading: false,
    searchResults: undefined,
    currentDrug: undefined,
    formOptions: undefined,
    currentDrugForm: undefined,
    locationFilters: {
        radiusRange: 25
    },
    pharmacies: undefined,
    generatedCard: undefined
};

const discountCardSlice = createSlice({
    name: 'discount-card',
    initialState,
    reducers: {
        setInitialValues: (state, action: PayloadAction<Partial<initialStateProps>>) => {
            state.searchResults = action.payload.searchResults;
            state.currentLocation = action.payload.currentLocation;
            state.isLoading = action.payload.isLoading;
            state.currentDrug = action.payload.currentDrug;
            state.formOptions = action.payload.formOptions;
            state.pharmacies = action.payload.pharmacies;
        },
        setLocationRadius: (state, action: PayloadAction<initialStateProps['locationFilters']>) => {
            state.locationFilters = {
                radiusRange: action.payload?.radiusRange
            };
        },
        setCurrentLocationAddress: (state, { payload }: PayloadAction<UserLocation>) => {
            state.currentLocation = {
                address: {
                    city: payload.address?.city,
                    state: payload.address?.state,
                    zip: payload.address?.zip
                }
            };
        },
        setDiscountCardVoucher: (state, { payload }: PayloadAction<DiscountCardProps>) => {
            state.generatedCard = {
                memberId: payload.memberId,
                bin: payload.bin,
                group: payload.group,
                pcn: payload.pcn
            };
        },
        resetMessageError: (state) => {
            state.error.messageTextAutocomplete = undefined;
        },
        resetAutocompleteValue: (state) => {
            state.autocompleteValues = [];
        }
    },
    extraReducers: ({ addCase }) => {
        const handleDrugSearchSuccess = (state: WritableDraft<initialStateProps>, payload: DrugSearchResponse) => {
            return produce(state, (draftState) => {
                draftState.isLoading = false;
                draftState.isFilterLoading = false;
                draftState.searchResults = payload;

                const selectedQuantity = payload?.Quantities.find((qty) => qty.IsSelected);
                const combinedQuantity = selectedQuantity
                    ? `${selectedQuantity.Quantity} ${selectedQuantity.QuantityLabel}`
                    : '';

                draftState.currentDrug = {
                    brandName: payload?.DrugInfo?.BrandName,
                    genericName: payload?.DrugInfo?.GenericName,
                    image: payload?.DrugInfo?.PillImage,
                    description: payload?.DrugInfo?.Description,
                    dosage: payload?.Strengths.find((dosage) => dosage.IsSelected)?.Strength || '',
                    quantity: combinedQuantity,
                    location: formatLocationAddress(state.currentLocation?.address, true),
                    ndc: payload?.DrugInfo?.NDC,
                    brandGeneric: payload.Names.find((name) => name.IsSelected)?.BrandGeneric
                };

                draftState.formOptions = {
                    drugOptions: payload.Names,
                    formOptions: payload.Forms,
                    strengthOptions: payload.Strengths,
                    quantityOptions: payload.Quantities
                };
                draftState.pharmacies = adaptDrugResponse(payload.Drugs);
            });
        };

        /* Set location actions */
        addCase(discountCardSetLocationRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoading = true;
                draftState.currentLocation = undefined;
                draftState.error.messageText = undefined;
            })
        );
        addCase(discountCardSetLocationRoutine.SUCCESS, (state, { payload }: PayloadAction<UserLocation>) =>
            produce(state, (draftState) => {
                draftState.isLoading = false;
                draftState.currentLocation = {
                    location: payload?.location
                };
            })
        );
        addCase(discountCardSetLocationRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.currentLocation = undefined;
                draftState.error.messageText = payload.messageText;
                draftState.isLoading = false;
            })
        );
        /* End Set location */

        addCase(getAutocompleteValues.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isAutocompleteLoading = true;
                draftState.error.messageTextAutocomplete = undefined;
            })
        );
        addCase(getAutocompleteValues.SUCCESS, (state, { payload }: PayloadAction<ScriptSaveAutocompleteResponse>) =>
            produce(state, (draftState) => {
                draftState.isAutocompleteLoading = false;
                draftState.autocompleteValues = payload.DrugNames.map((d) => titleCaseDrugName(d.DrugName)) as string[];
            })
        );
        addCase(getAutocompleteValues.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.isAutocompleteLoading = false;
                draftState.autocompleteValues = [];
                draftState.error.messageTextAutocomplete = payload?.messageText;
            })
        );

        /* Submit search actions */
        addCase(discountCardSearchRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoading = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(discountCardSearchRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugSearchResponse>) =>
            handleDrugSearchSuccess(state, payload)
        );
        addCase(discountCardSearchRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.searchResults = undefined;
                draftState.currentDrug = undefined;
                draftState.currentDrugForm = undefined;
                draftState.error.messageText = payload?.messageText;
                draftState.isLoading = false;
            })
        );
        /* End Submit search */

        /** Filter Drug search */
        addCase(discountCardSearchFilterRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFilterLoading = true;
                draftState.error.messageText = undefined;
            })
        );
        addCase(discountCardSearchFilterRoutine.SUCCESS, (state, { payload }: PayloadAction<DrugSearchResponse>) =>
            handleDrugSearchSuccess(state, payload)
        );
        addCase(discountCardSearchFilterRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.searchResults = undefined;
                draftState.currentDrug = undefined;
                draftState.currentDrugForm = undefined;
                draftState.error.messageText = payload?.messageText;
                draftState.isFilterLoading = false;
            })
        );
        /** End Filter Drug search */

        addCase(generateDiscountCardRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoading = true;
                draftState.generatedDiscountCard = undefined;
            })
        );
        addCase(generateDiscountCardRoutine.SUCCESS, (state, { payload }: PayloadAction<DiscountCardResponse>) =>
            produce(state, (draftState) => {
                draftState.isLoading = false;
                draftState.generatedDiscountCard = payload;
            })
        );
        addCase(generateDiscountCardRoutine.FAILURE, (state, { payload }: PayloadAction<{ messageText: string }>) =>
            produce(state, (draftState) => {
                draftState.generatedDiscountCard = undefined;
                draftState.error.messageText = payload.messageText;
                draftState.isLoading = false;
            })
        );
    }
});

export const {
    setInitialValues,
    setLocationRadius,
    setCurrentLocationAddress,
    setDiscountCardVoucher,
    resetMessageError,
    resetAutocompleteValue
} = discountCardSlice.actions;

export default discountCardSlice.reducer;
